import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import { useParams } from 'react-router-dom';
// import { compareAsc, format } from 'date-fns';
import Header from '../../layout/header';
import Footer from '../../layout/footer';

import { Link } from "react-tiger-transition";
import GetService from "../../components/services_member/get.service";

import bg from '../../assets/img/bg-host.jpg';
import bgfooter from '../../assets/img/bg-footer-host.jpg';


const Historyhost = (datat) => {

    const [dataMap, setDataMap] = useState([]);

    const fetchData = useCallback(async () => {
        // console.log(user)
        // if (user) {

        GetService.getAllHost().then(
            (response) => {
                // console.log(response)
                if (response.status === 200) {
                    setDataMap(response.data)
                    // setDataPresentationSearch(response.data)
                    // localStorage.setItem("member_data", JSON.stringify(response.data_member));
                    // setDatamember(response.data_member);
                }
            }, (error) => {
                // window.location.reload();
            }
        )
        // }
    });

    useEffect(() => {
        fetchData()
    }, []);


    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="home" datamember={datat.datamember} />
                <div className="wrapper bg-white box-index">
                    <section className="mt-3" id="page-wrap">
                        <div className="container-fluid p-0 bg-start" style={{ backgroundImage: `url(${bg})` }}>
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">
                                    <div className="w-100 position-relative">
                                        <div className='w-100'>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className='font-14'>
                                                            <Link to="/" transition='default-fade' className="a-default">หน้าแรก</Link>
                                                            <span className='mx-2'>|</span>
                                                            <span className='text-decoration-underline'>ประวัติการเป็นเจ้าภาพจัดงานสาธิตวิชาการ</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='w-100 mt-5'>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 text-center">
                                                        <div className='topic-news weight-600 mb-3'>ประวัติการเป็นเจ้าภาพจัดงานสาธิตวิชาการ</div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="container pb-5">
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className=' px-1 px-md-4 pt-5'>
                                                            <div className='row justify-content-center'>
                                                                {dataMap.length ? dataMap.map((val, index) => (
                                                                    <div className='col-12 col-md-8 col-lg-8 p-3 mb-3' key={index}>
                                                                        <div className='shadow-lg position-relative box-host'>
                                                                            <div className='tab-host'>
                                                                                <span className='rotate-90'>{val.topic}</span>
                                                                            </div>
                                                                            <div className='text-center pt-5 pb-4'>
                                                                                <img src={process.env.REACT_APP_API_URL + "/uploads/historyhost/" + val.id + '/' + val.img} style={{ height: "80px" }} />
                                                                            </div>
                                                                            <div className='px-5 pb-5'>
                                                                                {val.sub_topic}
                                                                            </div>
                                                                            {/* {(index + 1) === dataMap.length ?
                                                                                <div className='px-5 pb-5'><Link to='/' className="hbtn hb-fill-right">เข้าร่วม</Link></div>
                                                                                : null} */}
                                                                        </div>
                                                                    </div>
                                                                ))
                                                                    : <div className='py-5 text-center'>- No data -</div>}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <div className='margin-res-0' style={{marginTop:"-100px"}}>
                                <img src={bgfooter} className="w-100"/>
                            </div> */}
                        </div>
                    </section>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default Historyhost;