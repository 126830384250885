import React from 'react';

import Header from '../../layout/header';
import Footer from '../../layout/footer';

import { Link } from "react-tiger-transition";

import bg from '../../assets/img/mascot.jpg';

const Mascot = (datat) => {

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="news" />
                <div className="wrapper bg-white box-index">
                    <section className="mt-3" id="page-wrap">
                        <div className="container-fluid p-0 bg-start">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">
                                    <div className="w-100 position-relative">
                                        <div className='w-100'>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className='font-14'>
                                                            <Link to="/" transition='default-fade' className="a-default">หน้าแรก</Link>
                                                            <span className='mx-2'>|</span>
                                                            <span className='text-decoration-underline'>การออกแบบ Mascot ประจำงาน</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 mt-4'>
                                            <div className="container mb-5 bg-white">
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className='pt-5'>
                                                        <img src={bg} className="w-100"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default Mascot;