import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import { useParams } from 'react-router-dom';
// import { compareAsc, format } from 'date-fns';
import Moment from 'moment';
import InputMask from 'react-input-mask';

import Header from '../../layout/header';
import Footer from '../../layout/footer';

import { Link } from "react-tiger-transition";
import Swal from 'sweetalert2'

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";
import GetService from "../../components/services_member/get.service";

import loading from '../../assets/img/loading.gif';
import bg from '../../assets/img/bg-contest.jpg';
import a1 from '../../assets/img/a-1.jpg';
import calendar from '../../assets/img/calendar.svg';
import search from '../../assets/img/searchM.svg';

import ModalLogin from "../../layout/modal/modalLogin";
import seemore from '../../assets/img/seemore.svg';

const Newsdetail = (datat) => {

    const { iddetail } = useParams();

    // const memberdata = localStorage.getItem("member_data");

    // if (!memberdata) {
    //     // window.location.href = "/";
    //     // console.log('no login')
    // } else {
    //     // console.log('login')
    // }

    const [dateday, setDateday] = useState(Moment(new Date()).format('DD/MM/yyyy'));

    const dateoption = {
        locale: {
            format: 'DD/MM/YYYY'
        },
        startDate: dateday,
        // maxDate: dateday,
        singleDatePicker: true,
        autoApply: true,
    };

    const [dataMap, setDataMap] = useState([]);
    const [dataMapSearch, setDataMapSearch] = useState([]);


    const fetchData = useCallback(async (id, iddetail) => {
        // console.log(user)
        // if (user) {

        GetService.getAllNews(id).then(
            (response) => {
                // console.log(response)
                if (response.status === 200) {
                    setDataMap(response.data)
                    setDataMapSearch((response.data).filter(valb => valb.id === iddetail))
                    // localStorage.setItem("member_data", JSON.stringify(response.data_member));
                    // setDatamember(response.data_member);
                    console.log(iddetail)
                }
            }, (error) => {
                // window.location.reload();
            }
        )
        // }
    });

    useEffect(() => {
        fetchData(0, iddetail)
    }, []);


    const [modalLogin, setModalLogin] = useState({ view: false });

    const handleModalhide = () => {
        setModalLogin({ view: false })
        setTimeout(window.location.reload(), 3000);
    }

    console.log(iddetail)


    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="news" />
                <div className="wrapper bg-white box-index">
                    <section className="mt-3" id="page-wrap">
                        <div className="container-fluid p-0 bg-start">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">
                                    <div className="w-100 position-relative">
                                        <div className='w-100'>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className='font-14'>
                                                            <Link to="/" transition='default-fade' className="a-default">หน้าแรก</Link>
                                                            <span className='mx-2'>|</span>
                                                            <Link to="/news/news" transition='default-fade' className="a-default">ข่าวสาร</Link>
                                                            <span className='mx-2'>|</span>
                                                            <span className='text-decoration-underline'>{dataMapSearch.length ? (dataMapSearch[0].topic) : null}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 mt-4'>
                                            <div className="container mb-5 bg-white">
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-4 col-lg-4 text-start">
                                                        <div className='pt-5'>
                                                            <div className='font-22 weight-600 mb-3'>ข่าวสารล่าสุด</div>
                                                            <div className='mt-5 mt-md-0'>
                                                                {dataMap.length ? dataMap.sort((a, b) => b.id - a.id).slice(0, 3).map((val, index) => (
                                                                    <div className='border-loop mb-4' key={index}>
                                                                        <div className='weight-500 font-16'>
                                                                            {val.topic}
                                                                        </div>
                                                                        <div className='py-3'>
                                                                            <a href={"/news/detail/" + val.id} className="color-light-blue see-more">
                                                                                อ่านเพิ่มเติม <img src={seemore} className="img-seemore" />
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                )) : <div className='py-5 text-center'>- No data -</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-8 col-lg-8 text-start ps-md-5">
                                                        <div className='py-5'>
                                                            <div className='row'>
                                                                {dataMapSearch.length ? dataMapSearch.sort((a, b) => b.id - a.id).map((val, index) => (
                                                                    <div className='col-12 col-md-12 col-lg-12 p-3 pt-0' key={index}>
                                                                        <div className='topic-news weight-600 mb-3'>{val.topic}</div>
                                                                        <div className='font-14 weight-300'>Tag : {(val.tags)}</div>
                                                                        <div className='font-14 weight-300'>{(val.date)} | โดย {(val.write_by)}</div>
                                                                        <div className='mt-4'>
                                                                            <img src={process.env.REACT_APP_API_URL + "/uploads/news/" + val.id + '/' + val.img} className="w-100" />
                                                                        </div>                                                                        
                                                                        <div className='weight-300 pt-3'>
                                                                            <div dangerouslySetInnerHTML={{ __html: val.detail }} />
                                                                        </div>

                                                                    </div>
                                                                ))
                                                                    : <div className='py-5 text-center'>- No data -</div>}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </div>
            {modalLogin.view ?
                <ModalLogin show={modalLogin.view} onHide={() => setModalLogin({ view: false })} handleModalhide={(e) => handleModalhide()} />
                : null}
        </>
    );
};

export default Newsdetail;