import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Modal, Button, Form, Col, Row } from "react-bootstrap";

import Header from '../../layout/header';
import Footer from '../../layout/footer';

import PostService from "../../components/services_member/post.service";
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";

import tel from '../../assets/img/Tel.svg';
import mail from '../../assets/img/Letter.svg';

import { Link } from "react-tiger-transition";

const Contact = (datat) => {

    let history = useHistory();

    const [validated, setValidated] = useState(false);
    const [clssLoading, setClssLoading] = useState(false);

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {
            setClssLoading(true);

            PostService.regisContact(data).then(
                (res) => {
                    // console.log(res.status)
                    if (res.status === 200) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: '',
                            text: res.messages,
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            history.push("/");
                            // setClssLoading(false);
                        })
                    } else {

                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: '',
                            text: res.messages,
                            showConfirmButton: false,
                            // timer: 1500
                        })
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }

        setValidated(true);
    };

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="news" />
                <div className="wrapper bg-white box-index">
                    <section className="mt-3" id="page-wrap">
                        <div className="container-fluid p-0 bg-start">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">
                                    <div className="w-100 position-relative">
                                        <div className='w-100'>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className='font-14'>
                                                            <Link to="/" transition='default-fade' className="a-default">หน้าแรก</Link>
                                                            <span className='mx-2'>|</span>
                                                            <span className='text-decoration-underline'>ติดต่อเรา</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 mt-4'>
                                            <div className="container mb-5 bg-white">
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className='pt-5'>
                                                            <div>
                                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3874.380397821139!2d100.04246371483117!3d13.81618199030581!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x852141cf78533094!2zMTPCsDQ4JzU4LjMiTiAxMDDCsDAyJzQwLjgiRQ!5e0!3m2!1sth!2sth!4v1666750845989!5m2!1sth!2sth" width="100%" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                                            </div>
                                                            <div className='topic-news weight-600 mb-3 mt-4'>ติดต่อเรา</div>
                                                            <div className='mb-2'>โรงเรียนสาธิต มหาวิทยาลัยศิลปากร <br />
                                                                6 ถนนราชมรรคาใน ต.พระปฐมเจดีย์ อ.เมือง จ.นครปฐม 73000</div>
                                                            <div><img src={tel} alt="" style={{ height: "15px" }} /> : 034-250005, 063-2068498</div>
                                                            <div><img src={mail} alt="" style={{ height: "15px" }} /> : satitdsu@su.ac.th</div>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="container-fluid p-0" style={{ background: "#F3F3F3" }}>
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className="container mb-5">
                                                            <div className="row justify-content-center">
                                                                <div className="col-12 col-md-12 col-lg-12 text-start">
                                                                    <div className='mt-5' >
                                                                        <div className='mb-3'>Send us a Message</div>
                                                                        <div>
                                                                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                                                                <div className='row'>
                                                                                    <div className="col-12 col-md-6 col-lg-6">
                                                                                        <div className='pt-3'>
                                                                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                                                <Form.Control type="text" name='fullName' className='form-control register-custom ps-0 font-14' placeholder='Full Name' required />
                                                                                            </Form.Group>
                                                                                        </div>
                                                                                        <div className='pt-3'>
                                                                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                                                <Form.Control type="text" name='phone' className='form-control register-custom ps-0 font-14' placeholder='Phone Number' required />
                                                                                            </Form.Group>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-md-6 col-lg-6">
                                                                                        <div className='pt-3'>
                                                                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                                                <Form.Control type="text" name='email' className='form-control register-custom ps-0 font-14' placeholder='Email Address' required />
                                                                                            </Form.Group>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-md-12 col-lg-12">
                                                                                        <div className='pt-3'>
                                                                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                                                <textarea name="message" className='form-control register-custom ps-0 font-14' rows="1" cols="50" placeholder='Message' required></textarea>
                                                                                            </Form.Group>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='row'>
                                                                                    <div className="col-12 col-md-12 col-lg-12">
                                                                                        <div className='pt-5 text-end'>
                                                                                            <Link to="/" transition='default-fade' className='btn-link-default pe-3'>ยกเลิก</Link>
                                                                                            <button type='submit' className="btn-blue" >
                                                                                                {clssLoading ? <span><i className="fa fa-spinner fa-spin" /></span> : <span>ส่ง</span>}
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default Contact;