import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import { useParams, useHistory } from 'react-router-dom';
// import { compareAsc, format } from 'date-fns';
import Moment from 'moment';

import InputMask from 'react-input-mask';

import Header from '../../layout/header';
import Footer from '../../layout/footer';

import { Link } from "react-tiger-transition";
import Swal from 'sweetalert2'

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import PostService from "../../components/services_member/post.service";
import GetService from "../../components/services_member/get.service";
import ModalLoginpage from "../../layout/modal/modalLogin";

import loading from '../../assets/img/loading.gif';
import bg from '../../assets/img/bg-contest.jpg';
import a1 from '../../assets/img/a-1.jpg';
import calendar from '../../assets/img/calendar.svg';
import search from '../../assets/img/searchM.svg';

const Detail = (datat) => {

    const memberdata = JSON.parse(localStorage.getItem("member_data"));

    const { id } = useParams();

    let history = useHistory();

    const [dataContest, setDataContest] = useState([]);

    const fetchData = useCallback(async (id, member_id) => {

        GetService.getAllContest(id, member_id).then(

            (response) => {

                console.log(response)
                if (response.status === 200) {
                    setDataContest(response.data)
                }
            }, (error) => {
                // window.location.reload();
            }
        )
    });

    useEffect(() => {
        fetchData(id, datat.member ? datat.member.mem : null)
    }, []);

    const [validated, setValidated] = useState(false);
    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})

    const [modalLoginpage, setModalLoginpage] = useState({ view: false });

    const handleModalhide = () => {
        setModalLoginpage({ view: false })
        setTimeout(window.location.reload(), 3000);
    }

    const cancleApp = (id) => {

        const dataf = new FormData();
        dataf.append("id", id);
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.isConfirmed) {
                PostService.delContest(dataf).then(
                    (res) => {
                        console.log(res.status)
                        if (res.status === 200) {
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: '',
                                text: res.messages,
                                showConfirmButton: false,
                                timer: 1500
                            }).then(function () {
                                // history.push("/");
                                window.location.reload();
                            })
                        } else {

                            Swal.fire({
                                position: 'center',
                                icon: 'error',
                                title: '',
                                text: res.messages,
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }
        })

        // alert(id_mem)
    }

    const handleNoregis = () => {
        Swal.fire({
            title: 'ขออภัย !',
            text: "เฉพาะนักเรียนที่สามารถเข้าร่วมการประกวดการแข่งขันได้",
            icon: 'warning',
            showConfirmButton: false,
            timer: 4000
        })
    }

    // console.log(datat.member.mem)

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="home" />
                {dataContest ?
                    <div className="wrapper bg-white box-index">
                        <section className="mt-3" id="page-wrap">
                            <div className="container-fluid p-0 bg-start" style={{ backgroundImage: `url(${bg})` }}>
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12 position-relative">
                                        <div className="w-100 position-relative">
                                            {/* <img src={bg} className="w-100" /> */}
                                            <div className='w-100'>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-12 col-md-12 col-lg-12 text-start">
                                                            <div className='font-14'>
                                                                <Link to="/" transition='default-fade' className="a-default">หน้าแรก</Link>
                                                                <span className='mx-2'>|</span>
                                                                <Link to="/contest/contest" transition='default-fade' className="a-default">การประกวดการแข่งขัน</Link>
                                                                <span className='mx-2'>|</span>
                                                                <span className='text-decoration-underline'>
                                                                    {dataContest.topic}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-5">
                                                        <div className="col-12 col-md-12 col-lg-12 text-start">
                                                            <div className='topic-news weight-600 mb-3'>การประกวดการแข่งขัน</div>
                                                            {datat.member ?
                                                                null
                                                                : <div className='box-login-alert p-4 position-relative' style={{ opacity: datat.member ? 1 : 1 }}>
                                                                    <div className='color-white font-18 weight-500 pt-3 ps-3'>กรุณาเข้าสู่ระบบ</div>
                                                                    <div className='color-white font-14 weight-300 pb-3 ps-3'>
                                                                        - กรุณาเข้าสู่ระบบเพื่อทำรายการที่ท่านต้องการ หากท่านไม่ทำการเข้าสู่ระบบท่านจะไม่สามารถทำรายการได้ <br />
                                                                        - กรณีที่ยังไม่มีข้อมูลสำหรับเข้าสู่ระบบ ให้ทำการลงทะเบียน
                                                                    </div>
                                                                    <div className='color-white box-absolute-right-center pe-5'>
                                                                        <button className='btn-seemore' onClick={() => setModalLoginpage({ view: true })}>เข้าสู่ระบบ</button>
                                                                    </div>
                                                                </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="container mb-5 bg-white">
                                                    <div className="row justify-content-center">
                                                        <div className="col-12 col-md-12 col-lg-12 text-start">
                                                            <div className='shadow-sm py-md-5 px-md-5'>
                                                                <div className='row'>
                                                                    <div className="col-12 col-md-12 col-lg-4 text-start">
                                                                        <div className='position-relative gar-black'>
                                                                            <img src={process.env.REACT_APP_API_URL + "/uploads/contest/" + dataContest.id + '/' + dataContest.img} alt="" className="w-100" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-12 col-lg-8 text-start">
                                                                        <div className='px-3 px-md-4'>
                                                                            <div className='pt-3 pt-md-0'>
                                                                                <h2>{dataContest.topic}</h2>
                                                                            </div>
                                                                            <div className='mt-5 mb-5'>
                                                                                {datat.member ? datat.member.type === "student" ?
                                                                                    dataContest.data_app ?
                                                                                        <span className="btn-red cursor-pointer" onClick={() => cancleApp(dataContest.data_app)}>ยกเลิก </span>
                                                                                        :
                                                                                        <>
                                                                                            <Link to={"/contest/register/" + dataContest.id} transition='default-fade' className='btn-blue'>เข้าร่วม</Link>
                                                                                        </>
                                                                                    : <>
                                                                                        <span className="btn-blue cursor-pointer" onClick={(e) => handleNoregis()} >เข้าร่วม</span>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <span className="btn-blue cursor-pointer" onClick={() => setModalLoginpage({ view: true })} >เข้าร่วม</span>
                                                                                    </>

                                                                                }

                                                                            </div>
                                                                            <hr />
                                                                            <div className='font-22 weight-500 mb-2 mt-5'>รายละเอียด</div>
                                                                            <div className='row'>
                                                                                <div className="col-12 col-md-12 col-lg-12 text-start">
                                                                                    <div className='mb-4'>
                                                                                        <div className='weight-300'>{dataContest.school}</div>
                                                                                    </div>
                                                                                    {/* <div className='mb-4'>
                                                                                        <div className='font-18 weight-500 mb-1'>รายละเอียด</div>
                                                                                        <div className='weight-300'>{dataContest.school}</div>
                                                                                    </div> */}
                                                                                    <div> <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                                                                                        <a href={process.env.REACT_APP_API_URL + "/uploads/contest/" + dataContest.id + '/' + dataContest.pdf_file} target="_blank" className='ms-2 btn-default text-decoration-underline weight-300'>
                                                                                            อ่านรายละเอียดเพิ่มเติม
                                                                                        </a>
                                                                                    </div>
                                                                                    {dataContest.certificate ?
                                                                                        <div className='mt-2'> <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                                                                                            <a href={process.env.REACT_APP_API_URL + "/uploads/contest/" + dataContest.id + '/' + dataContest.certificate} target="_blank" className='ms-2 btn-default text-decoration-underline weight-300'>
                                                                                                ดาวน์โหลดเอกสาร
                                                                                            </a>
                                                                                        </div>
                                                                                        : null}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                    : null}
            </div >

            {modalLoginpage.view ?
                <ModalLoginpage show={modalLoginpage.view} onHide={() => setModalLoginpage({ view: false })} handleModalhide={(e) => handleModalhide()} />
                : null}
        </>
    );
};

export default Detail;