import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Form, Col, Row } from "react-bootstrap";
import { useParams, useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';

import Header from '../layout/header';
import Footer from '../layout/footer';

import { Link } from "react-tiger-transition";
import Swal from 'sweetalert2';
import Moment from 'moment';

import AuthService from "../components/services_member/auth.service";
import GetService from "../components/services_member/get.service";

import loading from '../assets/img/loading.gif';
import bg from '../assets/img/bg-register.jpg';
import eye from '../assets/img/eye.svg';
import iuser from '../assets/img/user.svg';

const Newpassword = () => {

    const { id } = useParams();

    let history = useHistory();

    const [dataotp, setDataotp] = useState(false);

    const fetchData = useCallback(async (id) => {

        GetService.getOtp(id).then(

            (response) => {
                if (response.status === 200) {
                    setDataotp(true)
                }
            }, (error) => {
                // window.location.reload();
            }
        )
    });

    useEffect(() => {
        fetchData(id)
    }, []);

    const [validated, setValidated] = useState(false);
    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})

    const handleSubmit = (event) => {

        event.preventDefault();

        const newErrors = findFormErrors()

        const form = event.currentTarget;

        const data = new FormData(event.target);
        
        if (Object.keys(newErrors).length > 0) {

            setErrors(newErrors)
        } else {
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            } else {
                AuthService.resetpass(data).then(
                    (res) => {
                        console.log(res)

                        if (res.status === 200) {

                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: '',
                                text: "ตั้งค่ารหัสผ่านใหม่เรียบร้อยแล้ว",
                                showConfirmButton: false,
                                timer: 1500
                            }).then(function () {
                                history.push("/");
                            })
                        } else {
                            setErrors({ otp: "This OTP cannot be used.!" });
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: '',
                                text: "ไม่สามารถตั้งค่ารหัสผ่านใหม่ได้กรุณาติดต่อผู้ดูแล",
                                showConfirmButton: false,
                                timer: 1500
                            }).then(function () {
                                history.push("/");
                            })
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }
        }
        setValidated(true);
    };

    const findFormErrors = () => {

        const { password, password2 } = form

        const newErrors = {};

        // const emailval = errorsemail;

        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        // const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{6,})");
        // const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");


        // if (!emailRegex.test(email)) {
        //     newErrors.email = 'This email cannot be used.!'
        // }


        // if(emailval === email) {
        //     newErrors.email = 'This email cannot be used.!'
        // }

        // if (!strongRegex.test(password)) {
        //     newErrors.password = 'This Password cannot be used.!'
        // }
        if (password2 !== password) {
            newErrors.password2 = 'Confirm Password Not Match!'
        }

        return newErrors
    }
    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        })
    }

    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordShown2, setPasswordShown2] = useState(false);

    // Password toggle handler
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    const togglePassword2 = () => {
        setPasswordShown2(!passwordShown2);
    };

    const autoTab2 = (obj) => {

        var obj_l = obj.target.value;

        const newErrors = findFormErrors()

        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors)
        } else {
            setErrors({})
        }
    };
    
    // console.log(errors)

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="home" />
                <div className="wrapper bg-white box-index">
                    <section className="" id="page-wrap">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">
                                    <div className="w-100 position-relative d-none d-md-block">
                                        <img src={bg} className="w-100 border-blue" />
                                        <div className='position-absolute absolute-navigation w-100'>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className='font-14'>
                                                            <Link to="/" transition='default-fade' className="a-default">หน้าแรก</Link>
                                                            <span className='mx-2'>|</span>
                                                            <span className='text-decoration-underline'>ข้อมูลส่วนตัว</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='position-absolute absolute-center w-100'>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className='topic-news weight-600'>ตั้งค่ารหัสผ่านใหม่</div>
                                                        <div className='font-14'></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* mobile */}
                                    <div className="w-100 position-relative d-block d-md-none border-blue pb-3 bg-center" style={{ backgroundImage: `url(${bg})` }}>
                                        {/* <img src={bg} className="w-100 border-blue" /> */}
                                        <div className='absolute-navigation w-100'>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className='font-14'>
                                                            <Link to="/" transition='default-fade' className="a-default">หน้าแรก</Link>
                                                            <span className='mx-2'>|</span>
                                                            <span className='text-decoration-underline'>ตั้งค่ารหัสผ่านใหม่</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 mt-3'>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className='topic-news weight-600'>ข้อมูลส่วนตัว</div>
                                                        <div className='font-14'>ตั้งค่ารหัสผ่านใหม่</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container p-md-0 pt-5 box-news">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-6 col-lg-4 text-start">
                                    {dataotp ?
                                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                            <div className="row mb-5">

                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <Form.Group as={Col} md="12" className="position-relative mt-3" controlId="validationCustom01">
                                                        <Form.Label className='text-gray mb-1 font-14'>รหัสผ่านใหม่</Form.Label>
                                                        <Form.Control
                                                            required
                                                            type={passwordShown ? "text" : "password"}
                                                            placeholder="รหัสผ่านใหม่"
                                                            className=""
                                                            name="password"
                                                            minLength={6}
                                                            onKeyUp={autoTab2}
                                                            onChange={e => setField('password', e.target.value)}
                                                            isInvalid={!!errors.password}
                                                        />
                                                        <Form.Control.Feedback type='invalid'>
                                                            {errors.password}
                                                        </Form.Control.Feedback>
                                                        <span className="sh-pass cursor-pointer" onClick={togglePassword}>
                                                            <img src={eye} alt="" className="" />
                                                            {passwordShown ? <span className='hide-password'></span> : null}
                                                        </span>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="12" className="position-relative mt-3" controlId="validationCustom01">
                                                        <Form.Label className='text-gray mb-1 font-14'>ยืนยันรหัสผ่านใหม่</Form.Label>
                                                        <Form.Control
                                                            required
                                                            type={passwordShown2 ? "text" : "password"}
                                                            placeholder="ยืนยันรหัสผ่านใหม่"
                                                            className=""
                                                            name="password2"
                                                            minLength={6}
                                                            onKeyUp={autoTab2}
                                                            onChange={e => setField('password2', e.target.value)}
                                                            isInvalid={!!errors.password2}
                                                        />
                                                        <span className="sh-pass cursor-pointer" onClick={togglePassword2}>
                                                            <img src={eye} alt="" className="" />
                                                            {passwordShown2 ? <span className='hide-password'></span> : null}
                                                        </span>

                                                        <Form.Control.Feedback type='invalid' className='position-absolute text-end pe-4'>
                                                            {errors.password2}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <input type="hidden" name="otp" defaultValue={id} />
                                                </div>
                                            </div>
                                            <div className="row mt-5">
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className='text-end pt-4'>
                                                        <Link to='/' className="btn-default pe-3">ยกเลิก</Link>
                                                        <button type='submit' className="btn-blue">อัปเดต</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                        : <><div className='text-center'>Link นี้ไม่สามารถใช้งานได้หรือหมดอายุแล้ว</div></>}
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </div>

        </>
    );
};

export default Newpassword;