import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Link } from "react-tiger-transition";
import Swal from 'sweetalert2'

import folder from '../../assets/img/folder.svg';
import teacher from '../../assets/img/icon-teacher.svg';
import student from '../../assets/img/icon-student.svg';
import guest from '../../assets/img/icon-guest.svg';
import loading from '../../assets/img/loading.gif';

import AuthService from "../../components/services_member/auth.service";

const ModalLogin = ({ handleModalhide, ...datat }) => {

    const [validated, setValidated] = useState(false);
    const [validatedreset, setValidatedreset] = useState(false);
    const [boxRegister, setBoxregister] = useState({ id: 0 });
    const [clssLoading, setClssLoading] = useState(false);
    const [errors, setErrors] = useState(false)
    const [errorsreset, setErrorsreset] = useState(false)

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        // console.log(data.get('name'));
        // const { email,password,password2 } = event.target.elements;   
        // var errors = [];

        // console.log(errors.push("tel"));

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {
            setClssLoading(true);
            // Swal.fire({
            //     position: 'center',
            //     html: `<div class="p-5"><img src='${loading}' class="icon-loading"/></div>`,
            //     title: '',
            //     text: "จองโต๊ะเรียบร้อยแล้ว",
            //     showConfirmButton: false,
            // });

            AuthService.login(data).then(
                (res) => {
                    if (res.status === 200) {
                        // history.push("/business");                              
                        // setClssLoading(false);
                        // history.push("/branch");
                        handleModalhide();

                        // datat.onHide();
                        // refreshData();
                        // setdatau(res.status);
                        // setClssLoading(false);
                        // console.log(res)
                    } else {
                        setErrors(true)
                        setClssLoading(false);
                    }
                },
                (error) => {
                    // console.log(error);
                    setClssLoading(false);
                    setErrors(true)
                }
            );
        }

        setValidated(true);
    };

    const handleSubmitreset = (event) => {

        event.preventDefault();
        const form = event.currentTarget;
        const data = new FormData(event.target);

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {
            setClssLoading(true);

            AuthService.checkEmail(data).then(
                (res) => {
                    // console.log(res)
                    if (res.status === 200) {

                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: '',
                            text: 'โปรดตรวจสอบอีเมลของท่าน',
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            handleModalhide();
                        })

                    } else {
                        setErrorsreset(true)
                        setClssLoading(false);
                    }
                },
                (error) => {
                    setClssLoading(false);
                    setErrorsreset(true)
                }
            );
        }

        setValidated(true);
    };

    return (
        <>
            <Modal {...datat} className="modal-custom" size="md" backdrop="static" centered keyboard={false}>
                <Modal.Header closeButton className='btn-close-white'>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                {boxRegister.id === "1" ? <>
                    <Modal.Body className="bg-gray-modal">
                        <div className="d-block w-100 scroll-custom">
                            <div className='h-100per-modal py-3 px-4'>
                                <div className='text-white text-center'>
                                    <h5>กรุณาเลือกคำตอบ</h5>
                                </div>
                                <div className='mt-4'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <Link to="/register/teacher" transition='default-fade' className="font-14 a-select-answer">
                                                <div className='text-center bg-select-answer rounded'>
                                                    <div className='py-3'><img src={teacher} className="border-blue" style={{ height: "60px" }} /></div>
                                                    <div className='weight-500'>ครู</div>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className='col-md-4 my-3 my-md-0'>
                                            <Link to="/register/student" transition='default-fade' className="font-14 a-select-answer">
                                                <div className='text-center bg-select-answer rounded'>
                                                    <div className='py-3'><img src={student} className="border-blue" style={{ height: "60px" }} /></div>
                                                    <div className='weight-500'>นักเรียน</div>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className='col-md-4'>
                                            <Link to="/register/guest" transition='default-fade' className="font-14 a-select-answer">
                                                <div className='text-center bg-select-answer rounded'>
                                                    <div className='py-3'><img src={guest} className="border-blue" style={{ height: "60px" }} /></div>
                                                    <div className='weight-500'>บุคคลทั่วไป</div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </> : boxRegister.id === "2" ? <>
                    <Modal.Body className="bg-gray-modal">
                        <div className="d-block w-100 scroll-custom">
                            <div className='h-100per-modal py-3 px-4'>
                                <div className='text-white'>
                                    <h5>ลืมรหัสผ่าน</h5>
                                </div>
                                <div className='mt-4'>
                                    <Form noValidate validated={validatedreset} onSubmit={handleSubmitreset}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            {/* <Form.Label>Email address</Form.Label> */}
                                            <Form.Control type="email" name='email' className='form-control input-custom ps-0 font-14' placeholder='Username' required />
                                            <Form.Text className="text-end d-block color-white font-12 weight-300">
                                                <em>* อีเมลที่ลงทะเบียน</em>
                                            </Form.Text>
                                        </Form.Group>
                                        {errorsreset ? (
                                            <div className='font-12 mb-0 text-center' style={{ color: "#dc3545" }}>This email address does not exist in the database. !</div>
                                        ) : ""}

                                        <button className='btn-login d-block w-100 py-2 mt-5' type="submit">
                                            {clssLoading ? <span><i className="fa fa-spinner fa-spin" /></span> : <span>Confirm</span>}
                                        </button>

                                    </Form>
                                </div>

                            </div>
                        </div>
                    </Modal.Body>

                </>
                    : <>
                        <Modal.Body className="bg-gray-modal">
                            <div className="d-block w-100 scroll-custom">
                                <div className='h-100per-modal py-3 px-4'>
                                    <div className='text-white'>
                                        <h5>เข้าสู่ระบบ</h5>
                                    </div>
                                    <div className='mt-4'>
                                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                {/* <Form.Label>Email address</Form.Label> */}
                                                <Form.Control type="email" name='email' className='form-control input-custom ps-0 font-14' placeholder='Username' required />
                                                <Form.Text className="text-end d-block color-white font-12 weight-300">
                                                    <em>* อีเมลที่ลงทะเบียน</em>
                                                </Form.Text>
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                {/* <Form.Label>Password</Form.Label> */}
                                                <Form.Control type="password" name='password' className='form-control input-custom ps-0 font-14' placeholder='Password' required />
                                                <Form.Text className="text-end d-block color-white font-12 weight-300">
                                                    <em>* รหัสผ่านที่ลงทะเบียน</em>
                                                </Form.Text>
                                            </Form.Group>
                                            {errors ? (
                                                <div className='font-12 mb-0 text-center' style={{ color: "#dc3545" }}>Invalid email or password !</div>
                                            ) : ""}

                                            <button className='btn-login d-block w-100 py-2 mt-5' type="submit">
                                                {clssLoading ? <span><i className="fa fa-spinner fa-spin" /></span> : <span>Log in</span>}
                                            </button>
                                            <div onClick={() => setBoxregister({ id: "2" })} className='mt-3 color-white text-end cursor-pointer a-default'>ลืมรหัสผ่าน ?</div>
                                        </Form>
                                    </div>

                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='bg-gray-modal d-block'>
                            <div className='text-white text-start py-4 px-4'>
                                <h5 className='m-0'>ลงทะเบียนเข้าร่วมงาน</h5>
                                <div className='mb-3 mt-1'>
                                    <Link to="/" transition='default-fade' className="font-12 a-white-default">
                                        <img src={folder} style={{ width: "15px" }} /> ดูกำหนดการ
                                    </Link>
                                </div>
                                <div>
                                    <span onClick={() => setBoxregister({ id: "1" })} className="btn-seemore w-100 d-block text-center cursor-pointer">ลงทะเบียน</span>
                                </div>
                            </div>
                        </Modal.Footer>
                    </>

                }


            </Modal>
        </>
    );
};

export default ModalLogin;