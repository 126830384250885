import React from 'react';

import Header from '../../layout/header';
import Footer from '../../layout/footer';

import { Link } from "react-tiger-transition";

import song1 from '../../assets/img/song-1.png';
import song2 from '../../assets/img/song-2.png';

const Song = (datat) => {

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="news" />
                <div className="wrapper bg-white box-index">
                    <section className="mt-3" id="page-wrap">
                        <div className="container-fluid p-0 bg-start">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">
                                    <div className="w-100 position-relative">
                                        <div className='w-100'>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className='font-14'>
                                                            <Link to="/" transition='default-fade' className="a-default">หน้าแรก</Link>
                                                            <span className='mx-2'>|</span>
                                                            <span className='text-decoration-underline'>เพลง สาธิตวิชาการ ครั้งที่ 8</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 mt-4'>
                                            <div className="container mb-5 bg-white">
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-9 col-lg-9 text-start">
                                                        <div className='pt-3'>
                                                            <div className='topic-news weight-600 mb-0 pt-5 mt-md-5 text-center'>เพลงประจำงานสาธิตวิชาการ ครั้งที่ 8</div>
                                                            <div className='my-3 d-none d-md-block'>
                                                                <iframe width="100%" height="500" src="https://www.youtube.com/embed/P_H76zlilZI" title="YouTube video player"
                                                                    frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                    allowFullScreen></iframe>
                                                            </div>
                                                            <div className='my-3 d-block d-md-none'>
                                                                <iframe width="100%" height="300" src="https://www.youtube.com/embed/P_H76zlilZI" title="YouTube video player"
                                                                    frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                    allowFullScreen></iframe>
                                                            </div>
                                                            <div className='text-end'>เพลง : “ดวงตาแห่งปัญญา”</div>
                                                            <div className='text-end'>ศิลปิน : Slot Machine</div>
                                                            <div className='mt-3 weight-300 mb-5' style={{ textIndent: "2.5em" }}>
                                                                วง Slot Machine เป็นศิลปินในสังกัดของบริษัท เทโร มิวสิค จำกัด เป็นวงดนตรีที่มีชื่อเสียง มีผลงานที่เป็นที่รู้จักและได้รับยอมอย่างกว้างขวาง ประกอบกับมีสมาชิกในวงดนตรีเป็นศิษย์เก่าของโรงเรียนสาธิต มหาวิทยาลัย
                                                                ศิลปากร (มัธยมศึกษา) อันเป็นความภาคภูมิใจของทางโรงเรียนเป็นอย่างมาก ซึ่งทางวง Slot Machine ได้ให้ความร่วมมือกับทางโรงเรียนด้วยการแต่งเพลง “ดวงตาแห่งปัญญา” เพื่อใช้เป็นเพลงประจำงานสาธิตวิชาการ ครั้งที่ 8 เนื่องในโอกาสที่โรงเรียนสาธิต มหาวิทยาลัยศิลปากร ทั้ง 2 โรงเรียน ได้รับเกียรติเป็นเจ้าภาพจัดงาน “สาธิตวิชาการ ครั้งที่ 8”
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-4 col-lg-4 text-start">
                                                        <div>
                                                            <img src={song1} className="w-100" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-4 col-lg-4 text-start">
                                                        <div>
                                                            <img src={song2} className="w-100" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-9 col-lg-9 text-start">
                                                        <div>
                                                            <div className='topic-news weight-600 mb-0 mt-md-5 text-start'>ประวัติ</div>
                                                            <div className='mt-3 weight-300' style={{ textIndent: "2.5em" }}>
                                                                คุณคาริญญ์ยวัฒ ดุรงค์จิรกานต์ (เฟิด) [ชื่อเดิม : ศริส หอมหวล] นักร้องนำวง Slot Machine และ คุณอธิราช ปิ่นทอง (แก๊ก) มือเบส วง Slot Machine ศิษย์เก่าโรงเรียนสาธิต มหาวิทยาลัยศิลปากร (มัธยมศึกษา) รุ่นที่ 25 รหัสประจำตัวนักเรียน I401057 (เฟิด) และ I401073 (แก๊ก) เข้าศึกษาระดับชั้นมัธยมศึกษาปีที่ 1 – 6 เมื่อปีพุทธศักราช 2540 - 2546
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='topic-news weight-600 mb-0 mt-md-5 text-start'>ข้อความจากครูถึงลูกศิษย์</div>
                                                            <div className='mt-3 weight-300'>
                                                                สวัสดี First และ แก๊ก
                                                            </div>
                                                            <div className='weight-300 mb-3' style={{ textIndent: "2.5em" }}>
                                                                ครูยินดีด้วยกับความสำเร็จของ First และแก๊ก ที่ได้เป็นศิลปินนักร้องนักดนตรีตามที่ตัวเองได้ทุ่มเทฝึกฝนและใฝ่ฝันอยากเป็นตั้งแต่เด็ก ทั้ง First และแก๊กเป็นตัวอย่างที่ดีของนักเรียนโรงเรียนสาธิต มหาวิทยาลัยศิลปากร (มัธยมศึกษา) คือ เป็นนักเรียน นักกีฬา นักกิจกรรม รักการเรียน งกคะแนน มีสัมมาคารวะ รับผิดชอบงานที่ได้รับมอบหมายเป็นอย่างดี เรียนเป็นเรียน เล่นเป็นเล่น และเป็นเด็กดี ครูดุ กัด เพื่อนแซว ก็ไม่เคยโกรธ
                                                            </div>
                                                            <div className='weight-300 mb-3' style={{ textIndent: "2.5em" }}>
                                                                First จะนั่งหล่อแถวหน้าๆ นิ่งๆ เงียบๆ จะแซวเพื่อนแซวครูกลับบ้าง แต่ละคำก็เด็ดๆ ค่ะ First จะเป็นที่หมายปองของเพื่อนร่วมชั้นปี ของรุ่นพี่และของรุ่นน้อง ส่วนแก๊กจะนั่งหล่อหลังห้อง มีมุกตลกตลอด กัดเพื่อน กัดครู และโดนกัดตอบตลอด เรียนแบบสนุกสนาน ชอบหมายปองน้องทุกชั้นปี
                                                            </div>
                                                            <div className='weight-300 mb-3' style={{ textIndent: "2.5em" }}>
                                                                ครูก็ขออวยพรให้ทั้ง First และแก๊กประสบผลสำเร็จในชีวิตทุกๆ ด้านนะคะ สร้างผลงานเพลงที่มีคุณภาพและไพเราะให้ทุกคนได้ฟัง และเป็นศิลปินนักร้องที่เป็นตัวอย่างที่ดีกับทุก ๆ คนนะคะ
                                                            </div>
                                                            <div className='weight-300 mb-3' style={{ textIndent: "2.5em" }}>
                                                                I wish both of you success in your lives.
                                                            </div>
                                                            <div className='weight-300 mb-3 text-end mt-5' >
                                                                With love and best wishes <br />
                                                                อ.ดุจดาว ศรีคง<br />
                                                                30 พฤศจิกายน 2565

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default Song;