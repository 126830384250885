import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import Header from '../layout/header';
import Footer from '../layout/footerHome';

// import { Carousel } from 'react-responsive-carousel';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Moment from 'moment';
import ReactPlayeryoutube from 'react-player/youtube'
import { Link } from "react-tiger-transition";
import GetService from "../components/services_member/get.service";

import sorn from '../assets/img/sorn.svg';
import seemore from '../assets/img/seemore.svg';

import bg from '../assets/img/bg.jpg';
import bgM from '../assets/img/bg_m.jpg';
import bg2 from '../assets/img/bg2.png';
import bg2M from '../assets/img/bg2M.png';
import logoall from '../assets/img/logo.jpg';
import logoallM from '../assets/img/logoM.jpg';
import sponsor from '../assets/img/sponsor.png';

const Home = () => {

    const handleDragStart = (e) => e.preventDefault();

    const responsive = {
        0: { items: 1.2 },
        568: { items: 2 },
        1024: { items: 5 },
    };

    const [dataPresentation, setDataPresentation] = useState([]);
    const [dataBanner, setDataBanner] = useState([]);
    const [dataNews, setDataNews] = useState([]);
    const [dataContest, setDataContest] = useState([]);

    const fetchData = useCallback(async (id) => {

        GetService.getAllBanner(id).then(

            (response) => {
                if (response.status === 200) {
                    setDataBanner(response.data)
                }
            }, (error) => {
                // window.location.reload();
            }
        )

        GetService.getAllPresentation(id).then(

            (response) => {
                if (response.status === 200) {
                    setDataPresentation(response.data)
                }
            }, (error) => {
                // window.location.reload();
            }
        )

        GetService.getAllNews(id).then(
            (response) => {
                // console.log(response)
                if (response.status === 200) {
                    setDataNews(response.data)
                }
            }, (error) => {
                // window.location.reload();
            }
        )

        GetService.getAllContest(id).then(
            (response) => {
                // console.log(response)
                if (response.status === 200) {
                    setDataContest(response.data)
                    // setDataPresentationSearch(response.data)
                    // localStorage.setItem("member_data", JSON.stringify(response.data_member));
                    // setDatamember(response.data_member);
                }
            }, (error) => {
                // window.location.reload();
            }
        )
    });

    useEffect(() => {
        fetchData(0)
    }, []);


    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="home" />
                <div className="wrapper bg-white box-index">
                    {/* <div className='py-5'>Coming soon</div> */}
                    <section className="" id="page-wrap">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">
                                    <div className="w-100 position-relative">
                                        <div className='banner-custom'>

                                            <AliceCarousel mouseTracking
                                                touchTracking={false}
                                                infinite
                                                handleDragStart
                                                controlsStrategy="alternate"
                                                renderPrevButton={() => {
                                                    return <p className="position-absolute">
                                                        <img src={sorn} className="sorn-left" />
                                                    </p>
                                                }}
                                                renderNextButton={() => {
                                                    return <p className="position-absolute end-0">
                                                        <img src={sorn} className="sorn-right" />
                                                    </p>
                                                }} >
                                                {dataBanner.length ? dataBanner.map((val, index) => (
                                                    <div className='position-relative gar-black' key={index}>
                                                        <img src={process.env.REACT_APP_API_URL + "/uploads/banner/" + val.id + '/' + val.img} alt="" className="w-100" />
                                                        <div className='position-absolute box-text-home d-none d-md-block'>
                                                            {/* <Link to='/' className="hbtn hb-fill-right">เข้าร่วม</Link> */}
                                                        </div>
                                                    </div>
                                                )) : null}

                                            </AliceCarousel>
                                        </div>
                                    </div>

                                    {/* <div className='d-block d-md-none text-start pt-3 ps-2'>
                                        <Link to='/' className="hbtn hb-fill-right">เข้าร่วม</Link>
                                    </div> */}

                                    {/* <div className='box-num-time w-100 end-0'>
                                        <div className="container">
                                            <div className='row justify-content-end'>
                                                <div className='col-12 col-md-6 col-lg-6 text-end'>
                                                    <div className='px-3 px-md-5 py-4 text-start shadow bg-white position-relative overflow-hidden d-inline-block'>
                                                        <span className='mum-time'></span>
                                                        <div>กำลังจะมาในอีก...</div>
                                                        <div className='d-inline-block'>
                                                            <span className='number-time weight-600 color-light-blue'>
                                                                120<span className='text-time weight-400 color-black'> วัน</span>
                                                            </span>
                                                        </div>
                                                        <div className='d-inline-block'>
                                                            <span className='number-time weight-600 ms-3 color-light-blue'>
                                                                12<span className='text-time weight-400 color-black' > ชั่วโมง</span>
                                                            </span>
                                                        </div>
                                                        <div className='d-inline-block'>
                                                            <span className='number-time weight-600 ms-md-3 color-light-blue'>
                                                                20<span className='text-time weight-400 color-black'> นาที</span>
                                                            </span>
                                                        </div>
                                                        <div className='d-inline-block'>
                                                            <span className='number-time weight-600 ms-3 color-light-blue'>
                                                                10<span className='text-time weight-400 color-black'> วินาที</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container p-md-0 pt-5 box-news">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-10 col-lg-10 text-start">
                                    <div className=''>
                                        <div className='box-news-home'>
                                            <div className='color-light-blue' style={{ marginBottom: "-5px" }}>NEWS</div>
                                            <div className='position-relative' style={{ borderBottom: "2px solid #E4E4E4", height: "27px" }}>
                                                <span className='topic-news weight-600 bg-white position-absolute start-0 top-0 pe-3'>ข่าวสาร</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-5 pt-3'>
                                        <div className='row justify-content-center'>
                                            <div className='col-12 col-md-5 col-lg-5'>
                                                {dataNews.length ? dataNews.sort((a, b) => b.id - a.id).slice(0, 1).map((val, index) => (
                                                    <div key={index}>
                                                        <div>
                                                            <img src={process.env.REACT_APP_API_URL + "/uploads/news/" + val.id + '/' + val.img} className="w-100" />
                                                        </div>
                                                        <div className='weight-500 font-18 py-3'>
                                                            {val.topic}
                                                        </div>
                                                        <div className='weight-300'>
                                                            {val.sub_topic}
                                                        </div>
                                                        <div className='py-3'>
                                                            <Link to={"/news/detail/" + val.id} className="color-light-blue see-more">
                                                                อ่านเพิ่มเติม <img src={seemore} className="img-seemore" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                )) : <div className='py-5 text-center'>- No data -</div>}
                                            </div>
                                            <div className='col-12 col-md-7 col-lg-7 text-start'>
                                                <div className='ps-md-5 mt-5 mt-md-0'>
                                                    {dataNews.length ? dataNews.sort((a, b) => b.id - a.id).slice(0, 3).map((val, index) => (
                                                        <div className='border-loop mb-4' key={index}>
                                                            <div className='weight-500 font-18'>
                                                                {val.topic}
                                                            </div>
                                                            <div className='py-3'>
                                                                <Link to={"/news/detail/" + val.id} className="color-light-blue see-more">
                                                                    อ่านเพิ่มเติม <img src={seemore} className="img-seemore" />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    )) : <div className='py-5 text-center'>- No data -</div>}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* การบรรยาย */}
                    <section>
                        <div className="container-fluid p-0 py-5 bg-gray" >
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-12 col-lg-12 text-start">
                                    <div className="container p-md-0">
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-md-12 col-lg-12 text-start">
                                                <div className='box-news-home'>
                                                    <div className='position-relative pb-3'>
                                                        <span className='topic-news weight-600'>การบรรยาย/นำเสนอ</span>
                                                    </div>
                                                    <div>
                                                        <div className='thumnail-custom'>
                                                            <AliceCarousel mouseTracking
                                                                mouseDragEnabled
                                                                touchTracking={true}
                                                                responsive={responsive}
                                                                // infinite
                                                                handleDragStart
                                                                controlsStrategy="alternate"
                                                                renderPrevButton={() => {
                                                                    return <p className="position-absolute">
                                                                        <img src={sorn} className="sorn-left" />
                                                                    </p>
                                                                }}
                                                                renderNextButton={() => {
                                                                    return <p className="position-absolute end-0">
                                                                        <img src={sorn} className="sorn-right" />
                                                                    </p>
                                                                }} >
                                                                {dataPresentation.length ? dataPresentation.map((val, index) => (
                                                                    <div className='position-relative gar-black' key={index}>
                                                                        <img src={process.env.REACT_APP_API_URL + "/uploads/presentation/" + val.id + '/' + val.img} alt="" className="w-100" />
                                                                        <div className='mb-3 px-1'>
                                                                            <div className='pb-4' style={{ minHeight: "120px" }}>
                                                                                <div className='weight-500 ps-5 pt-2 position-relative'>
                                                                                    <div className='position-absolute start-0 top-0'>
                                                                                        <div className='day-sum font-bb'>{Moment(val.date_active).format('DD')}</div>
                                                                                        <div className='month-sum font-bb'>{Moment(val.date_active).format('MMM')}</div>
                                                                                    </div>
                                                                                    <div className='ps-3'>
                                                                                        {val.topic}
                                                                                        <div className='color-gray weight-400 font-12'>{Moment(val.date_active).format('HH:mm')}  |  {val.lecturer_name}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <Link to={'/presentation/detail/' + val.id} className="btn-blue">เข้าร่วม</Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )) : null}

                                                            </AliceCarousel>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    
                    <section>
                        <div className="container p-md-0 pt-5 mt-5 ">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-10 col-lg-10 text-start position-relative" style={{ zIndex: 1 }}>
                                    <div className=''>
                                        <div className='box-news-home'>
                                            <div className='position-relative text-center'>
                                                <span className='topic-news weight-600 bg-white '>การประกวดแข่งขัน</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='pt-4'>
                                        <div className='row align-items-start'>
                                            {dataContest.length ? dataContest.map((val, index) => (
                                                <div className='col-12 col-md-4 col-lg-4 p-3' key={index}>
                                                    <Link to={"/contest/detail/" + val.id} className="img-hover">
                                                        <img src={process.env.REACT_APP_API_URL + "/uploads/contest/" + val.id + '/' + val.img} alt="" className="w-100" />
                                                        <div className="overlay shadow">
                                                            <div className="overlay-text">
                                                                <div className='weight-600'>
                                                                    {val.topic}
                                                                </div>
                                                                <div className='font-14 weight-300 d-none d-md-block'>{val.school}</div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='box-section-youtube'>
                        <div className="container-fluid p-0">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-12 col-lg-12 text-start ">
                                    <div className='position-relative'>
                                        <img src={bg} className="w-100 d-none d-md-block" />
                                        <img src={bgM} className="w-100 d-block d-md-none" />
                                        <div className='box-youtube'>
                                            <div className="container">
                                                <div className='row '>
                                                    <div className='col-12 col-md-12 col-lg-12'>
                                                        <div className='color-white font-20 pb-3'>The Demonstration School of <br /> Silpakorn University</div>
                                                    </div>
                                                    <div className='col-12 col-md-6 col-lg-6'>

                                                        <div className='d-none d-md-block'>
                                                            <ReactPlayeryoutube controls width='100%' height='300px' url="https://www.youtube.com/watch?v=-Kh1siMreW8" />
                                                        </div>
                                                        <div className='d-block d-md-none'>
                                                            <ReactPlayeryoutube controls width='100%' height='100' url="https://www.youtube.com/watch?v=-Kh1siMreW8" />
                                                        </div>

                                                    </div>
                                                    <div className='col-12 col-md-6 col-lg-6'>
                                                        <div className='present-height'>
                                                            <div className='row'>
                                                                <div className='col-6 col-md-5 col-lg-5 pb-3 ps-md-4'>
                                                                    <div className='d-none d-md-block'>
                                                                        <ReactPlayeryoutube controls width='100%' height='150px' url="https://www.youtube.com/watch?v=YNW5cRprbZ0" />
                                                                    </div>
                                                                    <div className='d-block d-md-none'>
                                                                        <ReactPlayeryoutube controls width='100%' height='100px' url="https://www.youtube.com/watch?v=YNW5cRprbZ0" />
                                                                    </div>
                                                                </div>
                                                                <div className='col-6 col-md-7 col-lg-7 ps-md-5'>
                                                                    <a href="https://www.youtube.com/watch?v=YNW5cRprbZ0" target="_blank" className='color-white font-mn weight-400'>
                                                                        Presentation สาธิตวิชาการ ครั้งที่ 8
                                                                    </a>
                                                                </div>

                                                                <div className='col-6 col-md-5 col-lg-5 pb-3 ps-md-4'>
                                                                    <div className='d-none d-md-block'>
                                                                        <ReactPlayeryoutube controls width='100%' height='150px' url="https://www.youtube.com/watch?v=P_H76zlilZI" />
                                                                    </div>
                                                                    <div className='d-block d-md-none'>
                                                                        <ReactPlayeryoutube controls width='100%' height='100px' url="https://www.youtube.com/watch?v=P_H76zlilZI" />
                                                                    </div>
                                                                </div>
                                                                <div className='col-6 col-md-7 col-lg-7 ps-md-5'>
                                                                    <a href="https://www.youtube.com/watch?v=P_H76zlilZI" target="_blank" className='color-white font-mn weight-400'>
                                                                        เพลงประจำงานสาธิตวิชาการ ครั้งที่ 8 <br />
                                                                        ชื่อเพลง : “ดวงตาแห่งปัญญา”<br />
                                                                        ศิลปิน : Slot Machine
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container-fluid p-0">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-12 col-lg-12 text-start position-relative">
                                    <img src={bg2} className="w-100 d-none d-md-block" />
                                    <img src={bg2M} className="w-100 d-block d-md-none" />
                                    <div className='box-absolute-center position-absolute w-100 px-2 px-md-0'>
                                        <div className="container">
                                            <div className='row justify-content-center'>
                                                <div className='col-12 col-md-8 col-lg-8 text-center'>
                                                    <div className='font-22 pb-1 weight-600'>
                                                        โครงการสาธิตวิชาการ ครั้งที่ 8
                                                    </div>
                                                    <div className='pb-3 weight-400 color-light-blue'>
                                                        SATIT INNOVATION AND CREATIVITY FOR EDUCATION 4.0
                                                    </div>
                                                    <div>
                                                        สำหรับปีการศึกษา 2565 โรงเรียนสาธิต มหาวิทยาลัยศิลปากร (มัธยมศึกษา) และ โรงเรียนสาธิต มหาวิทยาลัยศิลปากร (ปฐมวัยและประถมศึกษา) คณะศึกษาศาสตร์ มหาวิทยาลัยศิลปากร วิทยาเขตพระราชวังสนามจันทร์ ได้รับเกียรติให้เป็นเจ้าภาพในการจัดงานสาธิตวิชาการ ครั้งที่ 8 ภายใต้แนวคิด “จินตนาการประสานภูมิปัญญา พัฒนานวัตกรรมสร้างสรรค์ ก้าวทันโลกดิจิทัล” (Satit Innovation and Creativity for Education 4.0)
                                                        ระหว่างวันที่ 8 - 9 ธันวาคม 2565 ในรูปแบบออนไลน์
                                                    </div>
                                                    <div className='mt-5'>
                                                        <Link to="/" className="btn-seemore">อ่านเพิ่มเติม</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-12 col-lg-12 text-start position-relative py-5">
                                    <img src={logoall} className="w-100 d-none d-md-block" />
                                    <img src={logoallM} className="w-100 d-block d-md-none" />
                                </div>
                                {/* <div className="col-12 col-md-12 col-lg-12 text-start position-relative py-5">
                                    <div className='font-22 pb-1 weight-600 text-center'>
                                        ผู้สนับสนุน
                                    </div>
                                    <div className='text-center'>
                                        <div className="row justify-content-center">
                                            <div className="col-6 col-md-4 col-lg-4">
                                                <img src={sponsor} className="w-100" />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </section>

                    <Footer />
                </div>
            </div>

        </>
    );
};

export default Home;