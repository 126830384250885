import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import { useParams } from 'react-router-dom';
// import { compareAsc, format } from 'date-fns';
import Moment from 'moment';
import InputMask from 'react-input-mask';

import Header from '../../layout/header';
import Footer from '../../layout/footer';

import { Link } from "react-tiger-transition";
import Swal from 'sweetalert2'

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";
import GetService from "../../components/services_member/get.service";

import loading from '../../assets/img/loading.gif';
import bg from '../../assets/img/bg-presentation.jpg';
import a1 from '../../assets/img/a-1.jpg';
import calendar from '../../assets/img/calendar.svg';
import search from '../../assets/img/searchM.svg';

import ModalLogin from "../../layout/modal/modalLogin";

const Presentation = (datat) => {

    // const memberdata = localStorage.getItem("member_data");

    // if (!memberdata) {
    //     // window.location.href = "/";
    //     // console.log('no login')
    // } else {
    //     // console.log('login')
    // }

    const [dateday, setDateday] = useState(Moment(new Date()).format('DD/MM/yyyy'));

    const dateoption = {
        locale: {
            format: 'DD/MM/YYYY'
        },
        startDate: dateday,
        // maxDate: dateday,
        singleDatePicker: true,
        autoApply: true,
    };

    const [dataPresentation, setDataPresentation] = useState([]);
    const [dataPresentationSearch, setDataPresentationSearch] = useState([]);
    const [dataMember, setDataMember] = useState();

    const fetchData = useCallback(async (id) => {
        // console.log(user)
        // if (user) {

        GetService.getAllPresentation(id).then(

            (response) => {

                // console.log(response)
                if (response.status === 200) {
                    setDataPresentation(response.data)
                    setDataPresentationSearch(response.data)
                    // localStorage.setItem("member_data", JSON.stringify(response.data_member));
                    // setDatamember(response.data_member);
                }
            }, (error) => {
                // window.location.reload();
            }
        )
        // }
    });

    useEffect(() => {
        fetchData(0)

        // let timer1 = setTimeout(setDataMember(memberdata), 500);
        // return () => {
        //     clearTimeout(timer1);
        // };        
    }, []);

    const handledateday = (event) => {
        // fetchData(event.target.value)
        // setDateday(event.target.value)
        // .sort((a,b)=> Moment(a.date_kitchen) - Moment(b.date_kitchen))

        // setOrderview()
        // alert(event.target.value)
        // setDataPresentation(dataPresentation.filter(valb => Moment(valb.date_active).format('DD/MM/YYYY') === event.target.value))
    }

    const [modalLogin, setModalLogin] = useState({ view: false });

    // console.log(datat.member)
    const handleModalhide = () => {
        setModalLogin({ view: false })
        setTimeout(window.location.reload(), 3000);
    }

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="home" datamember={datat.datamember} />
                <div className="wrapper bg-white box-index">
                    <section className="mt-3" id="page-wrap">
                        <div className="container-fluid p-0 bg-start" style={{ backgroundImage: `url(${bg})` }}>
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">
                                    <div className="w-100 position-relative">
                                        <div className='w-100'>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className='font-14'>
                                                            <Link to="/" transition='default-fade' className="a-default">หน้าแรก</Link>
                                                            <span className='mx-2'>|</span>
                                                            <span className='text-decoration-underline'>การบรรยาย/นำเสนอ</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='w-100 mt-5'>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className='topic-news weight-600 mb-3'>การบรรยาย/นำเสนอ</div>
                                                        {datat.member ? null :
                                                            <div className='box-login-alert p-4 position-relative' style={{ opacity: datat.member ? 0 : 1 }}>
                                                                <div className='color-white font-18 weight-500 pt-3 ps-3'>กรุณาเข้าสู่ระบบ</div>
                                                                <div className='color-white font-14 weight-300 pb-3 ps-3'>
                                                                    - กรุณาเข้าสู่ระบบเพื่อทำรายการที่ท่านต้องการ หากท่านไม่ทำการเข้าสู่ระบบท่านจะไม่สามารถทำรายการได้ <br />
                                                                    - กรณีที่ยังไม่มีข้อมูลสำหรับเข้าสู่ระบบ ให้ทำการลงทะเบียน
                                                                </div>
                                                                <div className='color-white box-absolute-right-center pe-5'>
                                                                    <button className='btn-seemore' onClick={() => setModalLogin({ view: true })}>เข้าสู่ระบบ</button>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="container mb-5 bg-white">
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className='shadow-sm px-1 px-md-4 py-5'>
                                                            {/* <div className='py-5 text-center'>Coming soon</div> */}
                                                            <div className='row'>
                                                                <div className="col-12 col-md-12 col-lg-12 text-start pb-5">
                                                                    <div className='row'>
                                                                        <div className="col-12 col-md-1 col-lg-1 text-start">
                                                                            <div className='text-start py-3 weight-500 d-inline-block me-3'>ค้นหา</div>
                                                                        </div>
                                                                        <div className="col-7 col-md-9 col-lg-9 text-start">
                                                                            <div className='position-relative'>
                                                                                <Form.Group controlId="">
                                                                                    <input type="text" className="form-control py-2 pe-5 text-end input-custom-2" name="key" placeholder='Search' />
                                                                                    <div className='position-absolute' style={{ top: "10px", right: "12px" }}>
                                                                                        <img src={search} alt="" className="" style={{ width: "22px" }} />
                                                                                    </div>
                                                                                </Form.Group>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-5 col-md-2 col-lg-2 text-start">
                                                                            <div className='position-relative d-inline-block'>
                                                                                <Form.Group controlId="dob">
                                                                                    <DateRangePicker initialSettings={dateoption} onApply={(e) => (handledateday(e))}>
                                                                                        <input type="text" className="form-control px-2 py-2 input-custom-2" name="dateday" />
                                                                                    </DateRangePicker >
                                                                                    <div className='position-absolute' style={{ top: "10px", right: "12px" }}>
                                                                                        <img src={calendar} alt="" className="" style={{ width: "17px" }} />
                                                                                    </div>
                                                                                </Form.Group>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='font-24 weight-600 mb-4'>บรรยาย</div>
                                                            <div className='row'>
                                                                {dataPresentation.length ? dataPresentation.filter(valb => valb.cat_id === '0').map((val, index) => (

                                                                    <div className="col-12 col-md-4 col-lg-4 text-start pb-3" key={index}>
                                                                        <div className='position-relative gar-black'>
                                                                            <img src={process.env.REACT_APP_API_URL + "/uploads/presentation/" + val.id + '/' + val.img} alt="" className="w-100" />
                                                                            <div className='mb-3 px-1'>
                                                                                <div className='pb-4' style={{ minHeight: "120px" }}>
                                                                                    <div className='weight-500 ps-5 pt-2 position-relative'>
                                                                                        <div className='position-absolute start-0 top-0'>
                                                                                            <div className='day-sum font-bb'>{Moment(val.date_active).format('DD')}</div>
                                                                                            <div className='month-sum font-bb'>{Moment(val.date_active).format('MMM')}</div>

                                                                                        </div>
                                                                                        <div className='ps-3'>
                                                                                            {val.topic}
                                                                                            <div className='color-gray weight-400 font-12'>{Moment(val.date_active).format('HH:mm')}  |  {val.lecturer_name}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <Link to={'/presentation/detail/' + val.id} className="btn-blue">เข้าร่วม</Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                                    : <div className='py-5 text-center'>- No data -</div>}
                                                            </div>
                                                            <hr />
                                                            <div className='font-24 weight-600 mb-3 mt-3'>นำเสนอ</div>
                                                            <div className='row'>
                                                                {dataPresentation.length ? dataPresentation.filter(valb => valb.cat_id === '1').map((val, index) => (

                                                                    <div className="col-12 col-md-4 col-lg-4 text-start pb-3" key={index}>
                                                                        <div className='position-relative gar-black'>
                                                                            <img src={process.env.REACT_APP_API_URL + "/uploads/presentation/" + val.id + '/' + val.img} alt="" className="w-100" />
                                                                            <div className='mb-3 px-1'>
                                                                                <div className='pb-4' style={{ minHeight: "120px" }}>
                                                                                    <div className='weight-500 ps-5 pt-2 position-relative'>
                                                                                        <div className='position-absolute start-0 top-0'>
                                                                                            <div className='day-sum font-bb'>{Moment(val.date_active).format('DD')}</div>
                                                                                            <div className='month-sum font-bb'>{Moment(val.date_active).format('MMM')}</div>

                                                                                        </div>
                                                                                        <div className='ps-3'>
                                                                                            {val.topic}
                                                                                            <div className='color-gray weight-400 font-12'>{Moment(val.date_active).format('HH:mm')}  |  {val.lecturer_name}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <Link to={'/presentation/detail/' + val.id} className="btn-blue">เข้าร่วม</Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                                    : <div className='py-5 text-center'>- No data -</div>}
                                                            </div>
                                                            <hr />
                                                            <div className='font-24 weight-600 mb-3 mt-3'>อบรมเชิงปฎิบัติการ</div>
                                                            <div className='row'>
                                                                {dataPresentation.length ? dataPresentation.filter(valb => valb.cat_id === '2').map((val, index) => (

                                                                    <div className="col-12 col-md-4 col-lg-4 text-start pb-3" key={index}>
                                                                        <div className='position-relative gar-black'>
                                                                            <img src={process.env.REACT_APP_API_URL + "/uploads/presentation/" + val.id + '/' + val.img} alt="" className="w-100" />
                                                                            <div className='mb-3 px-1'>
                                                                                <div className='pb-4' style={{ minHeight: "120px" }}>
                                                                                    <div className='weight-500 ps-5 pt-2 position-relative'>
                                                                                        <div className='position-absolute start-0 top-0'>
                                                                                            <div className='day-sum font-bb'>{Moment(val.date_active).format('DD')}</div>
                                                                                            <div className='month-sum font-bb'>{Moment(val.date_active).format('MMM')}</div>

                                                                                        </div>
                                                                                        <div className='ps-3'>
                                                                                            {val.topic}
                                                                                            <div className='color-gray weight-400 font-12'>{Moment(val.date_active).format('HH:mm')}  |  {val.lecturer_name}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <Link to={'/presentation/detail/' + val.id} className="btn-blue">เข้าร่วม</Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                                    : <div className='py-5 text-center'>- No data -</div>}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </div>
            {modalLogin.view ?
                <ModalLogin show={modalLogin.view} onHide={() => setModalLogin({ view: false })} handleModalhide={(e) => handleModalhide()} />
                : null}
        </>
    );
};

export default Presentation;