import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import { useParams, useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';

import Header from '../layout/header';
import Footer from '../layout/footer';

import { Link } from "react-tiger-transition";
import Swal from 'sweetalert2';
import Moment from 'moment';

import AuthService from "../components/services_member/auth.service";

import loading from '../assets/img/loading.gif';
import bg from '../assets/img/bg-register.jpg';
import eye from '../assets/img/eye.svg';
import iuser from '../assets/img/user.svg';

const Register = () => {

    const user = JSON.parse(localStorage.getItem("member"));
    const [token, setToken] = useState(user);

    if (!user) {
        window.location.href = "/";
        // console.log('no login')
    } else {
        // console.log('login')
    }

    const memberdata = localStorage.getItem("member_data");

    const { id } = useParams();

    let history = useHistory();

    const [validated, setValidated] = useState(false);
    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})

    const findFormErrors = () => {

        const { email, password } = form

        const newErrors = {};

        // const emailval = errorsemail;

        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{6,})");
        // const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");


        if (!emailRegex.test(email)) {
            newErrors.email = 'This email cannot be used.!'
        }


        // if(emailval === email) {
        //     newErrors.email = 'This email cannot be used.!'
        // }

        if (!strongRegex.test(password)) {
            newErrors.password = 'This Password cannot be used.!'
        }
        return newErrors
    }

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        })
    }

    const [passwordShown, setPasswordShown] = useState(false);

    // Password toggle handler
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const [reqOther, setOther] = useState(false);
    const [reqOther2, setOther2] = useState(false);
    const [reqOther3, setOther3] = useState();

    const [valposition, setValposition] = useState([]);
    // const [valposition, setValposition] = useState(JSON.parse(memberdata).position_in);
    const [valposition2, setValposition2] = useState([]);
    const [valposition3, setValposition3] = useState([]);
    const [valposition4, setValposition4] = useState([]);

    const [checkedList, setCheckedList] = useState([]);
    // const [checkedList, setCheckedList] = useState(valposition.length ? (valposition) : []);
    const [checkedList3, setCheckedList3] = useState([]);
    const [checkedList4, setCheckedList4] = useState([]);

    useEffect(() => {
        if (JSON.parse(memberdata).type === "teacher") {
            console.log(JSON.parse(memberdata).under_in)
            let po1 = JSON.parse(JSON.parse(memberdata).position_in);
            // let po2 = JSON.parse(JSON.parse(memberdata).under_in);
            let po3 = JSON.parse(JSON.parse(memberdata).level);
            

            setValposition(JSON.parse(JSON.parse(memberdata).position_in))
            // setValposition(JSON.parse(memberdata).position_in)
            setValposition2((JSON.parse(memberdata).under_in))

            setValposition3(JSON.parse(JSON.parse(memberdata).level))
            

            setCheckedList(po1.length ? po1 : [])
            setCheckedList3(po3.length ? po3 : [])
            

            setOther(po1.filter(valb => valb.match(new RegExp("อื่นๆ", "i"))).length ? true : false)
            setOther3(po3.filter(valb => valb.match(new RegExp("อื่นๆ", "i"))).length ? true : false)
        }

        let po4 = JSON.parse(JSON.parse(memberdata).survey);
        setValposition4(JSON.parse(JSON.parse(memberdata).survey));
        setCheckedList4(po4.length ? po4 : []);
    }, []);



    const handleChangeEE = (event) => {

        // if (e.target.value === "อื่นๆ") {
        //     setOther(true)
        // } else {
        //     setOther(false)
        // }
        const value = event.target.value;
        const isChecked = event.target.checked;

        if (isChecked) {
            //Add checked item into checkList
            if (value === "อื่นๆ") {
                setOther(true);
            }

            setCheckedList([...checkedList, value]);
        } else {
            //Remove unchecked item from checkList
            if (value === "อื่นๆ") {
                setOther(false);
            }

            const filteredList = checkedList.filter((item) => item !== value);
            setCheckedList(filteredList);
        }
    }

    const handleChange = (e) => {

        // if (e.target.value === "อื่นๆ") {
        //     setOther(true)
        // } else {
        //     setOther(false)
        // }
        setOther(!reqOther)
    }

    const handleChange2 = (e) => {

        if (e.target.value === "อื่นๆ") {
            setOther2(true)
        } else {
            setOther2(false)
        }
    }

    // const handleChange3 = (e) => {
    //     // if (e.target.value === "อื่นๆ") {
    //     //     setOther3(true)
    //     // } else {
    //     //     setOther3(false)
    //     // }
    //     setOther3(!reqOther3)
    // }

    const handleChange3 = (event) => {

        const value = event.target.value;
        const isChecked = event.target.checked;

        if (isChecked) {
            //Add checked item into checkList
            if (value === "อื่นๆ") {
                setOther3(true);
            }

            setCheckedList3([...checkedList3, value]);
        } else {
            //Remove unchecked item from checkList
            if (value === "อื่นๆ") {
                setOther3(false);
            }

            const filteredList3 = checkedList3.filter((item) => item !== value);
            setCheckedList3(filteredList3);
        }
    }

    const handleChange4 = (event) => {

        const value = event.target.value;
        const isChecked = event.target.checked;

        if (isChecked) {
            //Add checked item into checkList  
            setCheckedList4([...checkedList4, value]);
        } else {
            //Remove unchecked item from checkList         
            const filteredList4 = checkedList4.filter((item) => item !== value);
            setCheckedList4(filteredList4);
        }
    }

    const handleModalLogin = () => {
        // setModalLogin({ view: true })
    }

    const Split = (string, digi) => {
        if (string) {
            const char = string.split('-');
            if (char.length > 1) {
                return char[digi];
            } else {
                return '';
            }
        }
    }

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        // console.log(data.get('position_in[]'))
        if (JSON.parse(memberdata).type === "teacher") {
            if (checkedList.length > 0 && checkedList3.length > 0 && checkedList4.length > 0) {
                // alert("yes1")
                if (form.checkValidity() === false) {
                    // alert("yes2")
                    event.preventDefault();
                    event.stopPropagation();

                } else {
                    // alert("yes3")
                    AuthService.signupUpdate(data).then(
                        (res) => {
                            if (res.status === 200) {
                                Swal.fire({
                                    position: 'center',
                                    icon: 'success',
                                    title: '',
                                    text: res.messages,
                                    showConfirmButton: false,
                                    timer: 1500
                                }).then(function () {
                                    history.push("/");
                                })
                            } else {

                                Swal.fire({
                                    position: 'center',
                                    icon: 'error',
                                    title: '',
                                    text: res.messages,
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                }
            } else {
                setErrors({ checkbox1: false })
                // alert("no")
            }
        } else {
            if (form.checkValidity() === false) {
                // alert("yes2")
                event.preventDefault();
                event.stopPropagation();

            } else {
                // alert("yes3")
                AuthService.signupUpdate(data).then(
                    (res) => {
                        if (res.status === 200) {
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: '',
                                text: res.messages,
                                showConfirmButton: false,
                                timer: 1500
                            }).then(function () {
                                history.push("/");
                            })
                        } else {

                            Swal.fire({
                                position: 'center',
                                icon: 'error',
                                title: '',
                                text: res.messages,
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }
        }

        setValidated(true);
    };

    // console.log(checkedList)
    // console.log(valposition)

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="home" />
                <div className="wrapper bg-white box-index">
                    <section className="" id="page-wrap">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">
                                    <div className="w-100 position-relative d-none d-md-block">
                                        <img src={bg} className="w-100 border-blue" />
                                        <div className='position-absolute absolute-navigation w-100'>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className='font-14'>
                                                            <Link to="/" transition='default-fade' className="a-default">หน้าแรก</Link>
                                                            <span className='mx-2'>|</span>
                                                            <span className='text-decoration-underline'>ข้อมูลส่วนตัว</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='position-absolute absolute-center w-100'>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className='topic-news weight-600'>ข้อมูลส่วนตัว</div>
                                                        <div className='font-14'><img src={iuser} style={{ height: "13px" }} /> {JSON.parse(memberdata).name} {JSON.parse(memberdata).last_name}</div>
                                                        <div className='font-14'>{Moment(JSON.parse(memberdata).date_create).format('DD/MM/YYYY')} | {Moment(JSON.parse(memberdata).date_create).format('HH:mm')}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* mobile */}
                                    <div className="w-100 position-relative d-block d-md-none border-blue pb-3 bg-center" style={{ backgroundImage: `url(${bg})` }}>
                                        {/* <img src={bg} className="w-100 border-blue" /> */}
                                        <div className='absolute-navigation w-100'>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className='font-14'>
                                                            <Link to="/" transition='default-fade' className="a-default">หน้าแรก</Link>
                                                            <span className='mx-2'>|</span>
                                                            <span className='text-decoration-underline'>ข้อมูลส่วนตัว</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 mt-3'>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className='topic-news weight-600'>ข้อมูลส่วนตัว</div>
                                                        <div className='font-14'><img src={iuser} style={{ height: "13px" }} /> {JSON.parse(memberdata).name} {JSON.parse(memberdata).last_name}</div>
                                                        <div className='font-14'>{Moment(JSON.parse(memberdata).date_create).format('DD/MM/YYYY')} | {Moment(JSON.parse(memberdata).date_create).format('HH:mm')}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container p-md-0 pt-5 box-news">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-10 col-lg-10 text-start">
                                    {JSON.parse(memberdata).type === "teacher" ?
                                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                            <div className="row mb-5">
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <Form.Control type="hidden" name='id' defaultValue={JSON.parse(memberdata).id} className='form-control register-custom ps-0 font-14' />
                                                    <Form.Control type="hidden" name='type' defaultValue={JSON.parse(memberdata).type} className='form-control register-custom ps-0 font-14' />
                                                    <div className=''>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label className='font-14 weight-500'>คำนำหน้าชื่อ<span className='color-red'>*</span></Form.Label>
                                                            <Form.Select aria-label="Default select example" defaultValue={JSON.parse(memberdata).prefix} name="prefix" className='form-control register-custom ps-0 font-14' required>
                                                                <option value="">เลือกคำนำหน้าชื่อ</option>
                                                                <option value="นาย">นาย</option>
                                                                <option value="นาง">นาง</option>
                                                                <option value="นางสาว">นางสาว</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className=''>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label className='font-14 weight-500'>ชื่อ <span className='color-red'>*</span></Form.Label>
                                                            <Form.Control type="text" name='name' defaultValue={JSON.parse(memberdata).name} className='form-control register-custom ps-0 font-14' placeholder='ชื่อ' required />
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className=''>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label className='font-14 weight-500'>นามสกุล <span className='color-red'>*</span></Form.Label>
                                                            <Form.Control type="text" name='lastname' defaultValue={JSON.parse(memberdata).last_name} className='form-control register-custom ps-0 font-14' placeholder='นามสกุล' required />
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className=''>
                                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                                            <Form.Label className='font-14 weight-500'>โทรศัพท์มือถือ <span className='color-red'>*</span></Form.Label>
                                                            <InputMask
                                                                mask="999-999-9999"
                                                                maskChar={null}
                                                                required
                                                                className='form-control register-custom ps-0 font-14'
                                                                type="text"
                                                                placeholder="xxx-xxx-xxxx"
                                                                name="mobile"
                                                                defaultValue={JSON.parse(memberdata).mobile}
                                                                pattern="\d{3}[\-]\d{3}[\-]\d{4}"
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <Form.Label className='font-14 weight-500'>อีเมล <span className='color-red'>*</span> <span className='font-12'>(ใช้งานได้จริง)</span></Form.Label>
                                                        <Form.Control type="email" name='email' defaultValue={JSON.parse(memberdata).email} className='form-control register-custom ps-0 font-14' placeholder='อีเมล' required />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <Form.Group className="mb-3 position-relative" controlId="formBasicPassword">
                                                        <Form.Label className='font-14 weight-500'>รหัสผ่าน <span className='color-red'>*</span></Form.Label>
                                                        {/* <Form.Control type="password" name='password' className='form-control register-custom ps-0 font-14' placeholder='รหัสผ่าน' required /> */}
                                                        <Form.Control type={passwordShown ? "text" : "password"}
                                                            name='password' placeholder='Password' required
                                                            className='form-control register-custom ps-0 font-14'
                                                            minLength={6}
                                                            defaultValue={JSON.parse(memberdata).password}
                                                        // onKeyUp={autoTab2}
                                                        // onChange={e => setField('password', e.target.value)}
                                                        // isInvalid={!!errors.password}
                                                        />
                                                        <span className="sh-pass cursor-pointer" onClick={togglePassword}>
                                                            <img src={eye} alt="" className="" style={{ width: "20px" }} />
                                                            {passwordShown ? null : <span className='hide-password'></span>}
                                                        </span>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className=''>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label className='font-14 weight-500'>ตำแหน่งทางวิชาการ <span className='color-red'>*</span></Form.Label>
                                                            <Form.Select aria-label="Default select example" defaultValue={JSON.parse(memberdata).position} name='position' className='form-control register-custom ps-0 font-14' required>
                                                                <option value="">เลือกตำแหน่งทางวิชาการ</option>
                                                                <option value="ครู/อาจารย์">ครู/อาจารย์</option>
                                                                <option value="ผู้ช่วยศาสตราจารย์">ผู้ช่วยศาสตราจารย์</option>
                                                                <option value="รองศาสตราจารย์">รองศาสตราจารย์</option>
                                                                <option value="ศาสตราจารย์">ศาสตราจารย์</option>
                                                                <option value="ไม่ระบุ">ไม่ระบุ</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </div>
                                                    <div className='mt-3'>
                                                        <Form.Label className='font-14 weight-500'>ชื่อสถาบัน/โรงเรียน</Form.Label>
                                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                                            <Form.Control type="text" name='school_name' defaultValue={JSON.parse(memberdata).school_name} className='form-control register-custom ps-0 font-14' placeholder='ชื่อสถาบัน/โรงเรียน' />
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row mt-5">
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    {valposition.length ?
                                                        <div className='mb-3'>
                                                            <Form.Label className='font-14 weight-500'>ตำแหน่งในสายงานการศึกษา<span className='color-red'>*</span></Form.Label>
                                                            {checkedList.length == 0 ?
                                                                <div>
                                                                    <div className="color-red mb-2 font-14">Please choose a checkbox.</div>
                                                                </div>
                                                                : null}
                                                            <div className="ex-checkbox">
                                                                <input type="checkbox" id="radio_one" onChange={handleChangeEE} defaultChecked={valposition.length ? valposition.filter(valb => valb === 'ผู้อำนวยการ/ครูใหญ่').length ? true : false : false} name="position_in[]" value="ผู้อำนวยการ/ครูใหญ่" />
                                                                <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                    ผู้อำนวยการ/ครูใหญ่
                                                                </label>
                                                            </div>
                                                            <div className="ex-checkbox">
                                                                <input type="checkbox" id="radio_one" onChange={handleChangeEE} defaultChecked={valposition.length ? (valposition).filter(valb => valb === 'รองผู้อำนวยการ/ผู้ช่วยผู้อำนวยการ').length ? true : false : false} name="position_in[]" value="รองผู้อำนวยการ/ผู้ช่วยผู้อำนวยการ" />
                                                                <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                    รองผู้อำนวยการ/ผู้ช่วยผู้อำนวยการ
                                                                </label>
                                                            </div>
                                                            <div className="ex-checkbox">
                                                                <input type="checkbox" id="radio_one" onChange={handleChangeEE} defaultChecked={valposition.length ? (valposition).filter(valb => valb === 'ครู/อาจารย์').length ? true : false : false} name="position_in[]" value="ครู/อาจารย์" />
                                                                <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                    ครู/อาจารย์
                                                                </label>
                                                            </div>
                                                            <div className="ex-checkbox">
                                                                <input type="checkbox" id="radio_one" onChange={handleChangeEE} defaultChecked={valposition.length ? (valposition).filter(valb => valb === 'ศึกษานิเทศก์').length ? true : false : false} name="position_in[]" value="ศึกษานิเทศก์" />
                                                                <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                    ศึกษานิเทศก์
                                                                </label>
                                                            </div>
                                                            <div className="ex-checkbox">
                                                                <input type="checkbox" id="radio_one" onChange={handleChangeEE} defaultChecked={valposition.length ? (valposition).filter(valb => valb === 'นักวิชาการ/ยักวิจัย').length ? true : false : false} name="position_in[]" value="นักวิชาการ/ยักวิจัย" />
                                                                <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                    นักวิชาการ/นักวิจัย
                                                                </label>
                                                            </div>
                                                            <div className="ex-checkbox">
                                                                <input type="checkbox" id="radio_one" onChange={handleChangeEE} defaultChecked={valposition.length ? (valposition).filter(valb => valb === 'กรรมการสถานศึกษา').length ? true : false : false} name="position_in[]" value="กรรมการสถานศึกษา" />
                                                                <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                    กรรมการสถานศึกษา
                                                                </label>
                                                            </div>
                                                            <div className="ex-checkbox">
                                                                <input type="checkbox" id="radio_one" defaultChecked={valposition.length ? (valposition).filter(valb => valb === 'อื่นๆ').length ? true : false : false} name="position_in[]" onChange={handleChangeEE} value="อื่นๆ" />
                                                                <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                    อื่นๆ
                                                                </label>
                                                                <Form.Group className="mb-3" controlId="formBasicPassword" style={{ paddingLeft: "30px" }}>
                                                                    <Form.Control type="text" name='position_more' defaultValue={JSON.parse(memberdata).position_more} required={reqOther} className='form-control register-custom ps-0 font-14' placeholder='ระบุ' />
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                        : null}
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    {valposition2.length ?
                                                        <div className='mb-3'>
                                                            <Form.Label className='font-14 weight-500'>สังกัดหน่วยงานการศึกษา <span className='color-red'>*</span></Form.Label>
                                                            <div>
                                                                <input type="radio" id="radio_one" name="under_in" className='position-absolute' value="" style={{ height: 0, opacity: "0" }} required />
                                                                <div className="invalid-feedback m-0 font-14">Please choose a checkbox.</div>
                                                            </div>
                                                            <div className="ex-radio4">
                                                                <input type="radio" id="radio_one" defaultChecked={valposition2 === 'กระทรวงการอุดมศึกษา วิจัย และนวัตกรรม (อว)' ? true : false} name="under_in" onChange={handleChange2} value="กระทรวงการอุดมศึกษา วิจัย และนวัตกรรม (อว)" />
                                                                <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                    กระทรวงการอุดมศึกษา วิจัย และนวัตกรรม (อว)
                                                                </label>
                                                            </div>
                                                            <div className="ex-radio4">
                                                                <input type="radio" id="radio_one" defaultChecked={valposition2 === 'สำนักงานคณะกรรมการการศึกษาขั้นพื้นฐาน (สพฐ)' ? true : false} name="under_in" onChange={handleChange2} value="สำนักงานคณะกรรมการการศึกษาขั้นพื้นฐาน (สพฐ)" />
                                                                <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                    สำนักงานคณะกรรมการการศึกษาขั้นพื้นฐาน (สพฐ)
                                                                </label>
                                                            </div>
                                                            <div className="ex-radio4">
                                                                <input type="radio" id="radio_one" defaultChecked={valposition2 === 'สำนักงานคณะกรรมการการส่งเสริมการศึกษาเอกชน (สช)' ? true : false} name="under_in" onChange={handleChange2} value="สำนักงานคณะกรรมการการส่งเสริมการศึกษาเอกชน (สช)" />
                                                                <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                    สำนักงานคณะกรรมการการส่งเสริมการศึกษาเอกชน (สช)
                                                                </label>
                                                            </div>
                                                            <div className="ex-radio4">
                                                                <input type="radio" id="radio_one" defaultChecked={Split(valposition2, 0).trim() === 'อื่นๆ' ? true : false} name="under_in" onChange={handleChange2} value="อื่นๆ" />
                                                                <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                    อื่นๆ
                                                                </label>
                                                                <Form.Group className="mb-3" controlId="formBasicPassword" style={{ paddingLeft: "30px" }}>
                                                                    <Form.Control type="text" name='under_in_more' defaultValue={Split(valposition2, 1).trim()} required={reqOther2} className='form-control register-custom ps-0 font-14' placeholder='ระบุ' />
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                        : null}
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    {valposition3.length ?
                                                        <div className='mb-3'>
                                                            <Form.Label className='font-14 weight-500'>ระดับชั้นเรียนที่สอนหรือทำงาน <span className='color-red'>*</span></Form.Label>
                                                            {/* {console.log(JSON.parse(valposition3).filter(valb => valb.match(new RegExp("อื่นๆ", "i"))))} */}
                                                            {checkedList3.length == 0 ?
                                                                <div>
                                                                    <div className="color-red mb-2 font-14">Please choose a checkbox.</div>
                                                                </div>
                                                                : null}
                                                            <div className="ex-checkbox">
                                                                <input type="checkbox" id="radio_one" name="level[]" onChange={handleChange3} defaultChecked={valposition3.length ? (valposition3).filter(valb => valb === 'ปฐมวัย').length ? true : false : false} value="ปฐมวัย" />
                                                                <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                    ปฐมวัย
                                                                </label>
                                                            </div>
                                                            <div className="ex-checkbox">
                                                                <input type="checkbox" id="radio_one" name="level[]" onChange={handleChange3} defaultChecked={valposition3.length ? (valposition3).filter(valb => valb === 'ประถมศึกษา').length ? true : false : false} value="ประถมศึกษา" />
                                                                <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                    ประถมศึกษา
                                                                </label>
                                                            </div>
                                                            <div className="ex-checkbox">
                                                                <input type="checkbox" id="radio_one" name="level[]" onChange={handleChange3} defaultChecked={valposition3.length ? (valposition3).filter(valb => valb === 'มัธยมศึกษา').length ? true : false : false} value="มัธยมศึกษา" />
                                                                <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                    มัธยมศึกษา
                                                                </label>
                                                            </div>
                                                            <div className="ex-checkbox">
                                                                <input type="checkbox" id="radio_one" name="level[]" onChange={handleChange3} defaultChecked={valposition3.length ? (valposition3).filter(valb => valb === 'อุดมศึกษา').length ? true : false : false} value="อุดมศึกษา" />
                                                                <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                    อุดมศึกษา
                                                                </label>
                                                            </div>
                                                            <div className="ex-checkbox">
                                                                <input type="checkbox" id="radio_one" name="level[]" defaultChecked={valposition3.length ? (valposition3).filter(valb => valb.match(new RegExp("อื่นๆ", "i"))).length ? true : false : false} onChange={handleChange3} value="อื่นๆ" />
                                                                <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                    อื่นๆ
                                                                </label>
                                                                <Form.Group className="mb-3" controlId="formBasicPassword" style={{ paddingLeft: "30px" }}>
                                                                    <Form.Control type="text" defaultValue={JSON.parse(memberdata).level_more} name='level_more' required={reqOther3} className='form-control register-custom ps-0 font-14' placeholder='ระบุ' />
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                        : null}
                                                </div>
                                            </div>
                                            {valposition4.length ? <>
                                                <div className="row mt-5">
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <Form.Label className='font-14 weight-500'>กรุณาตอบแบบสำรวจกิจกรรมที่ท่านสนใจจะเข้าร่วม (เลือกได้มากกว่า 1 ข้อ) <span className='color-red'>*</span></Form.Label>
                                                        {checkedList4.length == 0 ?
                                                            <div>
                                                                <div className="color-red mb-2 font-14">Please choose a checkbox.</div>
                                                            </div>
                                                            : null}
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className='mb-3'>
                                                            <div className="ex-checkbox">
                                                                <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'พิธีเปิดงานสาธิตวิชาการ ครั้งที่ 8 วันที่ 8 ธ.ค. 65 (Zoom Meeting)').length ? true : false} value="พิธีเปิดงานสาธิตวิชาการ ครั้งที่ 8 วันที่ 8 ธ.ค. 65 (Zoom Meeting)" />
                                                                <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                    พิธีเปิดงานสาธิตวิชาการ ครั้งที่ 8 วันที่ 8 ธ.ค. 65 (Zoom Meeting)
                                                                </label>
                                                            </div>
                                                            <div className="ex-checkbox">
                                                                <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การบรรยายทางวิชาการ').length ? true : false} value="การบรรยายทางวิชาการ" />
                                                                <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                    การบรรยายทางวิชาการ
                                                                </label>
                                                            </div>
                                                            <div className="ex-checkbox">
                                                                <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การนำเสนอผลงานวิจัยและนวัตกรรม').length ? true : false} value="การนำเสนอผลงานวิจัยและนวัตกรรม" />
                                                                <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                    การนำเสนอผลงานวิจัยและนวัตกรรม
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การบรรยายพิเศษ').length ? true : false} value="การบรรยายพิเศษ" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การบรรยายพิเศษ
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การอบรมสัมมนาเชิงปฏิบัติการ').length ? true : false} value="การอบรมสัมมนาเชิงปฏิบัติการ" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การอบรมสัมมนาเชิงปฏิบัติการ
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'นิทรรศการทางวิชาการ Online ผ่าน Application Artsteps').length ? true : false} value="นิทรรศการทางวิชาการ Online ผ่าน Application Artsteps" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                นิทรรศการทางวิชาการ Online ผ่าน Application Artsteps
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <div>
                                                            <Form.Label className='font-14 weight-500'>กิจกรรมการประกวด/แข่งขันทักษะทางวิชาการ (มี 12 กิจกรรม 32 ประเภทของการให้รางวัล)</Form.Label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การวาดภาพระบายสี ').length ? true : false} value="การวาดภาพระบายสี " />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การวาดภาพระบายสี
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การประกวดโครงงานวิทยาศาสตร์และเทคโนโลยี').length ? true : false} value="การประกวดโครงงานวิทยาศาสตร์และเทคโนโลยี" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การประกวดโครงงานวิทยาศาสตร์และเทคโนโลยี
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การแข่งขันคณิตศาสตร์ ตอบคำถามจากโจทย์ปัญหา').length ? true : false} value="การแข่งขันคณิตศาสตร์ ตอบคำถามจากโจทย์ปัญหา" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การแข่งขันคณิตศาสตร์ ตอบคำถามจากโจทย์ปัญหา
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การแข่งขันภาษาไทย คำใดเขียนผิด').length ? true : false} value="การแข่งขันภาษาไทย คำใดเขียนผิด" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การแข่งขันภาษาไทย คำใดเขียนผิด
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การแข่งขันภาษาไทย ลับสมอง ประลองปัญญา ด้วยปริศนาพะหมี').length ? true : false} value="การแข่งขันภาษาไทย ลับสมอง ประลองปัญญา ด้วยปริศนาพะหมี" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การแข่งขันภาษาไทย ลับสมอง ประลองปัญญา ด้วยปริศนาพะหมี
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การแข่งขันภาษาอังกฤษ ตอบปัญหาภาษาอังกฤษ (Grammar)').length ? true : false} value="การแข่งขันภาษาอังกฤษ ตอบปัญหาภาษาอังกฤษ (Grammar)" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การแข่งขันภาษาอังกฤษ ตอบปัญหาภาษาอังกฤษ (Grammar)
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การประกวดวาดภาพ Digital Painting').length ? true : false} value="การประกวดวาดภาพ Digital Painting" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การประกวดวาดภาพ Digital Painting
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การแข่งขันคณิตศาสตร์ คณิตคิดเร็ว').length ? true : false} value="การแข่งขันคณิตศาสตร์ คณิตคิดเร็ว" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การแข่งขันคณิตศาสตร์ คณิตคิดเร็ว
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การประกวดโครงงานคณิตศาสตร์ หัวข้อ').length ? true : false} value="การประกวดโครงงานคณิตศาสตร์ หัวข้อ" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การประกวดโครงงานคณิตศาสตร์ หัวข้อ
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การแข่งขันภาษาไทย แต่งกลอนสด').length ? true : false} value="การแข่งขันภาษาไทย แต่งกลอนสด" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การแข่งขันภาษาไทย แต่งกลอนสด
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การแข่งขันภาษาอังกฤษ Odd One Out').length ? true : false} value="การแข่งขันภาษาอังกฤษ Odd One Out" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การแข่งขันภาษาอังกฤษ Odd One Out
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'The Changemaker Project: นำเสนอนวัตกรรมสร้างสรรค์สังคม').length ? true : false} value="The Changemaker Project: นำเสนอนวัตกรรมสร้างสรรค์สังคม" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                “The Changemaker Project: นำเสนอนวัตกรรมสร้างสรรค์สังคม”
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        หมายเหตุ : โปรดอ่านรายละเอียดเงื่อนไขในการสมัครเข้าร่วมกิจกรรมในหัวข้อ <Link to="/presentation/presentation">การบรรยาย/นำเสนอ</Link> และ <Link to="/contest/contest">การประกวดแข่งขัน</Link>
                                                    </div>
                                                </div>
                                            </> : null}
                                            <div className="row mt-5">
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    {/* <div className='mb-5'>
                                                        <Form.Label className='font-14 weight-500'>ที่อยู่สถาบัน/โรงเรียน</Form.Label>
                                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                                            <Form.Control type="text" name='school_address' defaultValue={JSON.parse(memberdata).school_address} className='form-control register-custom ps-0 font-14' placeholder='ที่อยู่สถาบัน/โรงเรียน' />
                                                        </Form.Group>
                                                    </div> */}
                                                    <div className='text-end pt-4'>
                                                        <Link to='/' className="btn-default pe-3">ยกเลิก</Link>
                                                        <button type='submit' className="btn-blue">อัปเดต</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                        : JSON.parse(memberdata).type === "student" ?
                                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                                <div className="row mb-5">
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <Form.Control type="hidden" name='id' defaultValue={JSON.parse(memberdata).id} className='form-control register-custom ps-0 font-14' />
                                                        <Form.Control type="hidden" name='type' defaultValue={JSON.parse(memberdata).type} className='form-control register-custom ps-0 font-14' />
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label className='font-14 weight-500'>ชื่อ <span className='color-red'>*</span></Form.Label>
                                                            <Form.Control type="text" name='name' defaultValue={JSON.parse(memberdata).name} className='form-control register-custom ps-0 font-14' placeholder='ชื่อ' required />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label className='font-14 weight-500'>นามสกุล <span className='color-red'>*</span></Form.Label>
                                                            <Form.Control type="text" name='lastname' defaultValue={JSON.parse(memberdata).last_name} className='form-control register-custom ps-0 font-14' placeholder='นามสกุล' required />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label className='font-14 weight-500'>เพศ <span className='color-red'>*</span></Form.Label>
                                                            <Form.Select aria-label="Default select example" defaultValue={JSON.parse(memberdata).sex} name="sex" className='form-control register-custom ps-0 font-14' required>
                                                                <option value="">เลือกเพศ</option>
                                                                <option value="เพศชาย">เพศชาย</option>
                                                                <option value="เพศหญิง">เพศหญิง</option>
                                                                <option value="ไม่ระบุเพศ">ไม่ระบุเพศ</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                                            <Form.Label className='font-14 weight-500'>อายุ <span className='color-red'>*</span></Form.Label>
                                                            <Form.Control type="number" min={0} name='age' defaultValue={JSON.parse(memberdata).age} className='form-control register-custom ps-0 font-14' placeholder='อายุ' required />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                                            <Form.Label className='font-14 weight-500'>โทรศัพท์มือถือ <span className='color-red'>*</span></Form.Label>
                                                            <InputMask
                                                                mask="999-999-9999"
                                                                maskChar={null}
                                                                required
                                                                className='form-control register-custom ps-0 font-14'
                                                                type="text"
                                                                placeholder="xxx-xxx-xxxx"
                                                                name="mobile"
                                                                pattern="\d{3}[\-]\d{3}[\-]\d{4}"
                                                                defaultValue={JSON.parse(memberdata).mobile}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                                            <Form.Label className='font-14 weight-500'>Line ID</Form.Label>
                                                            <Form.Control type="text" name='line' defaultValue={JSON.parse(memberdata).line} className='form-control register-custom ps-0 font-14' placeholder='Line ID' />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                                            <Form.Label className='font-14 weight-500'>อีเมล <span className='color-red'>*</span> <span className='font-12'>(ใช้งานได้จริง)</span></Form.Label>
                                                            <Form.Control type="email" name='email' defaultValue={JSON.parse(memberdata).email} className='form-control register-custom ps-0 font-14' placeholder='อีเมล' required />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <Form.Group className="mb-3 position-relative" controlId="formBasicPassword">
                                                            <Form.Label className='font-14 weight-500'>รหัสผ่าน <span className='color-red'>*</span></Form.Label>
                                                            {/* <Form.Control type="password" name='password' className='form-control register-custom ps-0 font-14' placeholder='รหัสผ่าน' required /> */}
                                                            <Form.Control type={passwordShown ? "text" : "password"}
                                                                name='password' placeholder='Password' required
                                                                className='form-control register-custom ps-0 font-14'
                                                                minLength={6}
                                                                defaultValue={JSON.parse(memberdata).password}
                                                            />
                                                            <span className="sh-pass cursor-pointer" onClick={togglePassword}>
                                                                <img src={eye} alt="" className="" style={{ width: "20px" }} />
                                                                {passwordShown ? null : <span className='hide-password'></span>}
                                                            </span>
                                                        </Form.Group>
                                                    </div>

                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label className='font-14 weight-500'>ชั้นที่กำลังศึกษา/ระดับชั้น <span className='color-red'>*</span></Form.Label>
                                                            <Form.Select aria-label="Default select example" defaultValue={JSON.parse(memberdata).position} name='position' className='form-control register-custom ps-0 font-14' required>
                                                                <option value="">เลือกชั้นที่กำลังศึกษา/ระดับชั้น</option>
                                                                <option value="ประถมศึกษาปีที่ 1">ประถมศึกษาปีที่ 1</option>
                                                                <option value="ประถมศึกษาปีที่ 2">ประถมศึกษาปีที่ 2</option>
                                                                <option value="ประถมศึกษาปีที่ 3">ประถมศึกษาปีที่ 3</option>
                                                                <option value="ประถมศึกษาปีที่ 4">ประถมศึกษาปีที่ 4</option>
                                                                <option value="ประถมศึกษาปีที่ 5">ประถมศึกษาปีที่ 5</option>
                                                                <option value="ประถมศึกษาปีที่ 6">ประถมศึกษาปีที่ 6</option>
                                                                <option value="มัธยมศึกษาปีที่ 1">มัธยมศึกษาปีที่ 1</option>
                                                                <option value="มัธยมศึกษาปีที่ 2">มัธยมศึกษาปีที่ 2</option>
                                                                <option value="มัธยมศึกษาปีที่ 3">มัธยมศึกษาปีที่ 3</option>
                                                                <option value="มัธยมศึกษาปีที่ 4">มัธยมศึกษาปีที่ 4</option>
                                                                <option value="มัธยมศึกษาปีที่ 5">มัธยมศึกษาปีที่ 5</option>
                                                                <option value="มัธยมศึกษาปีที่ 6">มัธยมศึกษาปีที่ 6</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                                            <Form.Label className='font-14 weight-500'>ชื่อโรงเรียน <span className='color-red'>*</span></Form.Label>
                                                            <Form.Control type="text" name='school_name' defaultValue={JSON.parse(memberdata).school_name} className='form-control register-custom ps-0 font-14' placeholder='ที่อยู่สถาบัน/โรงเรียน' required />
                                                        </Form.Group>
                                                    </div>

                                                </div>
                                                {valposition4.length ? <>
                                                <div className="row mt-5">
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <Form.Label className='font-14 weight-500'>กรุณาตอบแบบสำรวจกิจกรรมที่ท่านสนใจจะเข้าร่วม (เลือกได้มากกว่า 1 ข้อ) <span className='color-red'>*</span></Form.Label>
                                                        {checkedList4.length == 0 ?
                                                            <div>
                                                                <div className="color-red mb-2 font-14">Please choose a checkbox.</div>
                                                            </div>
                                                            : null}
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className='mb-3'>
                                                            <div className="ex-checkbox">
                                                                <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'พิธีเปิดงานสาธิตวิชาการ ครั้งที่ 8 วันที่ 8 ธ.ค. 65 (Zoom Meeting)').length ? true : false} value="พิธีเปิดงานสาธิตวิชาการ ครั้งที่ 8 วันที่ 8 ธ.ค. 65 (Zoom Meeting)" />
                                                                <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                    พิธีเปิดงานสาธิตวิชาการ ครั้งที่ 8 วันที่ 8 ธ.ค. 65 (Zoom Meeting)
                                                                </label>
                                                            </div>
                                                            <div className="ex-checkbox">
                                                                <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การบรรยายทางวิชาการ').length ? true : false} value="การบรรยายทางวิชาการ" />
                                                                <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                    การบรรยายทางวิชาการ
                                                                </label>
                                                            </div>
                                                            <div className="ex-checkbox">
                                                                <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การนำเสนอผลงานวิจัยและนวัตกรรม').length ? true : false} value="การนำเสนอผลงานวิจัยและนวัตกรรม" />
                                                                <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                    การนำเสนอผลงานวิจัยและนวัตกรรม
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การบรรยายพิเศษ').length ? true : false} value="การบรรยายพิเศษ" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การบรรยายพิเศษ
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การอบรมสัมมนาเชิงปฏิบัติการ').length ? true : false} value="การอบรมสัมมนาเชิงปฏิบัติการ" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การอบรมสัมมนาเชิงปฏิบัติการ
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'นิทรรศการทางวิชาการ Online ผ่าน Application Artsteps').length ? true : false} value="นิทรรศการทางวิชาการ Online ผ่าน Application Artsteps" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                นิทรรศการทางวิชาการ Online ผ่าน Application Artsteps
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <div>
                                                            <Form.Label className='font-14 weight-500'>กิจกรรมการประกวด/แข่งขันทักษะทางวิชาการ (มี 12 กิจกรรม 32 ประเภทของการให้รางวัล)</Form.Label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การวาดภาพระบายสี ').length ? true : false} value="การวาดภาพระบายสี " />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การวาดภาพระบายสี
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การประกวดโครงงานวิทยาศาสตร์และเทคโนโลยี').length ? true : false} value="การประกวดโครงงานวิทยาศาสตร์และเทคโนโลยี" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การประกวดโครงงานวิทยาศาสตร์และเทคโนโลยี
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การแข่งขันคณิตศาสตร์ ตอบคำถามจากโจทย์ปัญหา').length ? true : false} value="การแข่งขันคณิตศาสตร์ ตอบคำถามจากโจทย์ปัญหา" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การแข่งขันคณิตศาสตร์ ตอบคำถามจากโจทย์ปัญหา
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การแข่งขันภาษาไทย คำใดเขียนผิด').length ? true : false} value="การแข่งขันภาษาไทย คำใดเขียนผิด" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การแข่งขันภาษาไทย คำใดเขียนผิด
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การแข่งขันภาษาไทย ลับสมอง ประลองปัญญา ด้วยปริศนาพะหมี').length ? true : false} value="การแข่งขันภาษาไทย ลับสมอง ประลองปัญญา ด้วยปริศนาพะหมี" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การแข่งขันภาษาไทย ลับสมอง ประลองปัญญา ด้วยปริศนาพะหมี
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การแข่งขันภาษาอังกฤษ ตอบปัญหาภาษาอังกฤษ (Grammar)').length ? true : false} value="การแข่งขันภาษาอังกฤษ ตอบปัญหาภาษาอังกฤษ (Grammar)" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การแข่งขันภาษาอังกฤษ ตอบปัญหาภาษาอังกฤษ (Grammar)
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การประกวดวาดภาพ Digital Painting').length ? true : false} value="การประกวดวาดภาพ Digital Painting" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การประกวดวาดภาพ Digital Painting
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การแข่งขันคณิตศาสตร์ คณิตคิดเร็ว').length ? true : false} value="การแข่งขันคณิตศาสตร์ คณิตคิดเร็ว" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การแข่งขันคณิตศาสตร์ คณิตคิดเร็ว
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การประกวดโครงงานคณิตศาสตร์ หัวข้อ').length ? true : false} value="การประกวดโครงงานคณิตศาสตร์ หัวข้อ" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การประกวดโครงงานคณิตศาสตร์ หัวข้อ
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การแข่งขันภาษาไทย แต่งกลอนสด').length ? true : false} value="การแข่งขันภาษาไทย แต่งกลอนสด" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การแข่งขันภาษาไทย แต่งกลอนสด
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การแข่งขันภาษาอังกฤษ Odd One Out').length ? true : false} value="การแข่งขันภาษาอังกฤษ Odd One Out" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การแข่งขันภาษาอังกฤษ Odd One Out
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'The Changemaker Project: นำเสนอนวัตกรรมสร้างสรรค์สังคม').length ? true : false} value="The Changemaker Project: นำเสนอนวัตกรรมสร้างสรรค์สังคม" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                “The Changemaker Project: นำเสนอนวัตกรรมสร้างสรรค์สังคม”
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        หมายเหตุ : โปรดอ่านรายละเอียดเงื่อนไขในการสมัครเข้าร่วมกิจกรรมในหัวข้อ <Link to="/presentation/presentation">การบรรยาย/นำเสนอ</Link> และ <Link to="/contest/contest">การประกวดแข่งขัน</Link>
                                                    </div>
                                                </div>
                                            </> : null}
                                                <div className="row mt-5">
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <div className='text-end pt-4'>
                                                            <Link to='/' className="btn-default pe-3">ยกเลิก</Link>
                                                            <button type='submit' className="btn-blue">อัปเดต</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                            :
                                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                                <div className="row mb-5">
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <Form.Control type="hidden" name='id' defaultValue={JSON.parse(memberdata).id} className='form-control register-custom ps-0 font-14' />
                                                        <Form.Control type="hidden" name='type' defaultValue={JSON.parse(memberdata).type} className='form-control register-custom ps-0 font-14' />
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label className='font-14 weight-500'>ชื่อ <span className='color-red'>*</span></Form.Label>
                                                            <Form.Control type="text" name='name' defaultValue={JSON.parse(memberdata).name} className='form-control register-custom ps-0 font-14' placeholder='ชื่อ' required />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label className='font-14 weight-500'>นามสกุล <span className='color-red'>*</span></Form.Label>
                                                            <Form.Control type="text" name='lastname' defaultValue={JSON.parse(memberdata).last_name} className='form-control register-custom ps-0 font-14' placeholder='นามสกุล' required />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label className='font-14 weight-500'>เพศ <span className='color-red'>*</span></Form.Label>
                                                            <Form.Select aria-label="Default select example" name="sex" defaultValue={JSON.parse(memberdata).sex} className='form-control register-custom ps-0 font-14' required>
                                                                <option value="">เลือกเพศ</option>
                                                                <option value="เพศชาย">เพศชาย</option>
                                                                <option value="เพศหญิง">เพศหญิง</option>
                                                                <option value="ไม่ระบุเพศ">ไม่ระบุเพศ</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                                            <Form.Label className='font-14 weight-500'>อายุ <span className='color-red'>*</span></Form.Label>
                                                            <Form.Control type="number" min={0} name='age' defaultValue={JSON.parse(memberdata).age} className='form-control register-custom ps-0 font-14' placeholder='อายุ' required />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                                            <Form.Label className='font-14 weight-500'>โทรศัพท์มือถือ <span className='color-red'>*</span></Form.Label>
                                                            <InputMask
                                                                mask="999-999-9999"
                                                                maskChar={null}
                                                                required
                                                                className='form-control register-custom ps-0 font-14'
                                                                type="text"
                                                                placeholder="xxx-xxx-xxxx"
                                                                name="mobile"
                                                                defaultValue={JSON.parse(memberdata).mobile}
                                                                pattern="\d{3}[\-]\d{3}[\-]\d{4}"
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                                            <Form.Label className='font-14 weight-500'>Line ID</Form.Label>
                                                            <Form.Control type="text" name='line' defaultValue={JSON.parse(memberdata).line} className='form-control register-custom ps-0 font-14' placeholder='ที่อยู่สถาบัน/โรงเรียน' />
                                                        </Form.Group>
                                                    </div>

                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                                            <Form.Label className='font-14 weight-500'>อีเมล <span className='color-red'>*</span> <span className='font-12'>(ใช้งานได้จริง)</span></Form.Label>
                                                            <Form.Control type="email" name='email' defaultValue={JSON.parse(memberdata).email} className='form-control register-custom ps-0 font-14' placeholder='อีเมล' required />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <Form.Group className="mb-3 position-relative" controlId="formBasicPassword">
                                                            <Form.Label className='font-14 weight-500'>รหัสผ่าน <span className='color-red'>*</span></Form.Label>
                                                            {/* <Form.Control type="password" name='password' className='form-control register-custom ps-0 font-14' placeholder='รหัสผ่าน' required /> */}
                                                            <Form.Control type={passwordShown ? "text" : "password"}
                                                                name='password' placeholder='Password' required
                                                                className='form-control register-custom ps-0 font-14'
                                                                minLength={6}
                                                                defaultValue={JSON.parse(memberdata).password}
                                                            />
                                                            <span className="sh-pass cursor-pointer" onClick={togglePassword}>
                                                                <img src={eye} alt="" className="" style={{ width: "20px" }} />
                                                                {passwordShown ? null : <span className='hide-password'></span>}
                                                            </span>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label className='font-14 weight-500'>ตำแหน่งทางวิชาการ <span className='color-red'>*</span></Form.Label>
                                                            <Form.Select aria-label="Default select example" name='position' defaultValue={JSON.parse(memberdata).position} className='form-control register-custom ps-0 font-14' required>
                                                                <option value="">เลือกตำแหน่งทางวิชาการ</option>
                                                                <option value="ครู/อาจารย์">ครู/อาจารย์</option>
                                                                <option value="ผู้ช่วยศาสตราจารย์">ผู้ช่วยศาสตราจารย์</option>
                                                                <option value="รองศาสตราจารย์">รองศาสตราจารย์</option>
                                                                <option value="ศาสตราจารย์">ศาสตราจารย์</option>
                                                                <option value="ไม่ระบุ">ไม่ระบุ</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                {valposition4.length ? <>
                                                <div className="row mt-5">
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <Form.Label className='font-14 weight-500'>กรุณาตอบแบบสำรวจกิจกรรมที่ท่านสนใจจะเข้าร่วม (เลือกได้มากกว่า 1 ข้อ) <span className='color-red'>*</span></Form.Label>
                                                        {checkedList4.length == 0 ?
                                                            <div>
                                                                <div className="color-red mb-2 font-14">Please choose a checkbox.</div>
                                                            </div>
                                                            : null}
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className='mb-3'>
                                                            <div className="ex-checkbox">
                                                                <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'พิธีเปิดงานสาธิตวิชาการ ครั้งที่ 8 วันที่ 8 ธ.ค. 65 (Zoom Meeting)').length ? true : false} value="พิธีเปิดงานสาธิตวิชาการ ครั้งที่ 8 วันที่ 8 ธ.ค. 65 (Zoom Meeting)" />
                                                                <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                    พิธีเปิดงานสาธิตวิชาการ ครั้งที่ 8 วันที่ 8 ธ.ค. 65 (Zoom Meeting)
                                                                </label>
                                                            </div>
                                                            <div className="ex-checkbox">
                                                                <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การบรรยายทางวิชาการ').length ? true : false} value="การบรรยายทางวิชาการ" />
                                                                <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                    การบรรยายทางวิชาการ
                                                                </label>
                                                            </div>
                                                            <div className="ex-checkbox">
                                                                <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การนำเสนอผลงานวิจัยและนวัตกรรม').length ? true : false} value="การนำเสนอผลงานวิจัยและนวัตกรรม" />
                                                                <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                    การนำเสนอผลงานวิจัยและนวัตกรรม
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การบรรยายพิเศษ').length ? true : false} value="การบรรยายพิเศษ" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การบรรยายพิเศษ
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การอบรมสัมมนาเชิงปฏิบัติการ').length ? true : false} value="การอบรมสัมมนาเชิงปฏิบัติการ" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การอบรมสัมมนาเชิงปฏิบัติการ
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'นิทรรศการทางวิชาการ Online ผ่าน Application Artsteps').length ? true : false} value="นิทรรศการทางวิชาการ Online ผ่าน Application Artsteps" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                นิทรรศการทางวิชาการ Online ผ่าน Application Artsteps
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <div>
                                                            <Form.Label className='font-14 weight-500'>กิจกรรมการประกวด/แข่งขันทักษะทางวิชาการ (มี 12 กิจกรรม 32 ประเภทของการให้รางวัล)</Form.Label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การวาดภาพระบายสี ').length ? true : false} value="การวาดภาพระบายสี " />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การวาดภาพระบายสี
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การประกวดโครงงานวิทยาศาสตร์และเทคโนโลยี').length ? true : false} value="การประกวดโครงงานวิทยาศาสตร์และเทคโนโลยี" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การประกวดโครงงานวิทยาศาสตร์และเทคโนโลยี
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การแข่งขันคณิตศาสตร์ ตอบคำถามจากโจทย์ปัญหา').length ? true : false} value="การแข่งขันคณิตศาสตร์ ตอบคำถามจากโจทย์ปัญหา" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การแข่งขันคณิตศาสตร์ ตอบคำถามจากโจทย์ปัญหา
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การแข่งขันภาษาไทย คำใดเขียนผิด').length ? true : false} value="การแข่งขันภาษาไทย คำใดเขียนผิด" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การแข่งขันภาษาไทย คำใดเขียนผิด
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การแข่งขันภาษาไทย ลับสมอง ประลองปัญญา ด้วยปริศนาพะหมี').length ? true : false} value="การแข่งขันภาษาไทย ลับสมอง ประลองปัญญา ด้วยปริศนาพะหมี" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การแข่งขันภาษาไทย ลับสมอง ประลองปัญญา ด้วยปริศนาพะหมี
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การแข่งขันภาษาอังกฤษ ตอบปัญหาภาษาอังกฤษ (Grammar)').length ? true : false} value="การแข่งขันภาษาอังกฤษ ตอบปัญหาภาษาอังกฤษ (Grammar)" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การแข่งขันภาษาอังกฤษ ตอบปัญหาภาษาอังกฤษ (Grammar)
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การประกวดวาดภาพ Digital Painting').length ? true : false} value="การประกวดวาดภาพ Digital Painting" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การประกวดวาดภาพ Digital Painting
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การแข่งขันคณิตศาสตร์ คณิตคิดเร็ว').length ? true : false} value="การแข่งขันคณิตศาสตร์ คณิตคิดเร็ว" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การแข่งขันคณิตศาสตร์ คณิตคิดเร็ว
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การประกวดโครงงานคณิตศาสตร์ หัวข้อ').length ? true : false} value="การประกวดโครงงานคณิตศาสตร์ หัวข้อ" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การประกวดโครงงานคณิตศาสตร์ หัวข้อ
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การแข่งขันภาษาไทย แต่งกลอนสด').length ? true : false} value="การแข่งขันภาษาไทย แต่งกลอนสด" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การแข่งขันภาษาไทย แต่งกลอนสด
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'การแข่งขันภาษาอังกฤษ Odd One Out').length ? true : false} value="การแข่งขันภาษาอังกฤษ Odd One Out" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                การแข่งขันภาษาอังกฤษ Odd One Out
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox">
                                                            <input type="checkbox" id="radio_one" name="survey[]" onChange={handleChange4} defaultChecked={(valposition4).filter(valb => valb === 'The Changemaker Project: นำเสนอนวัตกรรมสร้างสรรค์สังคม').length ? true : false} value="The Changemaker Project: นำเสนอนวัตกรรมสร้างสรรค์สังคม" />
                                                            <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                “The Changemaker Project: นำเสนอนวัตกรรมสร้างสรรค์สังคม”
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        หมายเหตุ : โปรดอ่านรายละเอียดเงื่อนไขในการสมัครเข้าร่วมกิจกรรมในหัวข้อ <Link to="/presentation/presentation">การบรรยาย/นำเสนอ</Link> และ <Link to="/contest/contest">การประกวดแข่งขัน</Link>
                                                    </div>
                                                </div>
                                            </> : null}
                                                <div className="row mt-5">
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <div className='text-end pt-4'>
                                                            <Link to='/' className="btn-default pe-3">ยกเลิก</Link>
                                                            <button type='submit' className="btn-blue">อัปเดต</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </div>

        </>
    );
};

export default Register;