import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const signup = (data) => {
  return axios
    .post(API_URL + "/api/v1/register/signup", data)
    .then((response) => {
      if (response.data.status === 200) {
        localStorage.setItem("member", JSON.stringify(response.data.data));
        
      }
      return response.data;
    });
};

const signupUpdate = (data) => {
  return axios
    .post(API_URL + "/api/v1/register/signupUpdate", data)
    .then((response) => {
      if (response.data.status === 200) {
        localStorage.removeItem("member");
        localStorage.removeItem("member_data");

        // localStorage.setItem("member", JSON.stringify(response.data.data));
      }
      return response.data;
    });
};

const login = (data) => {
  return axios
    .post(API_URL + "/api/v1/register/login", data)
    .then((response) => {
      if (response.data.status === 200) {
        localStorage.setItem("member", JSON.stringify(response.data.data));        
      }
      return response.data;

    });
};

const checkEmail = (data) => {
  return axios
    .post(API_URL + "/api/v1/register/checkEmail", data)
    .then((response) => {
      return response.data;
    });
};

const resetpass = (data) => {
  return axios
    .post(API_URL + "/api/v1/register/resetpassword", data)
    .then((response) => {
      return response.data;
    });
};


const logout = () => {
  localStorage.removeItem("member");
  localStorage.removeItem("member_data");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("member"));
};


const authService = {
  signup,
  signupUpdate,
  resetpass,
  login,
  logout,
  getCurrentUser,
  checkEmail
};

export default authService;