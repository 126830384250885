import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { slide as Menu } from 'react-burger-menu';

import { Form, InputGroup, Button, Dropdown, DropdownButton } from 'react-bootstrap';

import ModalLogin from "../layout/modal/modalLogin";

import search from '../assets/img/search.svg';
import searchM from '../assets/img/searchM.svg';
import sorn from '../assets/img/sorn.svg';
import logo from '../assets/img/logo.png';

import tel from '../assets/img/Tel.svg';
import mail from '../assets/img/Letter.svg';
import iuser from '../assets/img/user.svg';
import close from '../assets/img/close.svg';

import special_lecture from '../assets/pdf/special_lecture.pdf';
import seminar from '../assets/pdf/seminar.pdf';
import presentation from '../assets/pdf/presentation.pdf';
import skills from '../assets/pdf/skills.pdf';

import { Link } from "react-tiger-transition";


import PostService from "../components/services_member/post.service";

// import Menu from '../components/menu';

const Header = (data) => {

    let history = useHistory();

    const user = JSON.parse(localStorage.getItem("member"));
    const memberdata = JSON.parse(localStorage.getItem("member_data"));

    if (!user) {
        // window.location.href = "/";
        // console.log('no login')
    } else {
        // console.log('login')
    }

    const [datamember, setDatamember] = useState();

    const fetchData = useCallback(async () => {
        // console.log(user)
        if (user) {
            if (memberdata) {
                // console.log('login')
                setDatamember(memberdata);
            } else {
                PostService.getAllPrivatePosts().then(

                    (response) => {
                        if (response.status === 200) {
                            localStorage.setItem("member_data", JSON.stringify(response.data_member));
                            setDatamember(response.data_member);
                        }
                    }, (error) => {
                        // window.location.reload();
                    }
                )
            }
        }
    });

    useEffect(() => {
        let timer1 = setTimeout(() => fetchData(), 500);
        return () => {
            clearTimeout(timer1);
        };
    }, []);

    const [menuOpen, setMenuOpen] = useState(false);
    const [menusubOpen, setMenusubOpen] = useState({ view: false, id: 0 });
    const [modalLogin, setModalLogin] = useState({ view: false });

    const handleMenuChange = (state) => {
        setMenuOpen(state.isOpen)
    }

    const toggleMenu = () => {
        // setMenuOpen(true); 
        setMenuOpen(false)
    }

    const handleMenusubChange = (state, id) => {
        if (menusubOpen.id === id) {
            setMenusubOpen({ view: !menusubOpen.view, id: id })
        } else {
            setMenusubOpen({ view: true, id: id })
        }

    }
    // const Split = (string, digi) => {
    //     if (string) {
    //         const char = string.split(',');
    //         return char[digi];
    //     }
    // }

    const handleModalhide = () => {
        setModalLogin({ view: false })
        fetchData();

        setTimeout(window.location.reload(), 3000);

    }

    const Logout = () => {
        localStorage.removeItem("member");
        localStorage.removeItem("member_data");
        window.location.reload();
    }

    // console.log(datamember)

    return (
        <>
            <div className="d-block d-md-none menu-res">
                {/* <div>444</div> */}
                <div className="line-gra position-absolute"></div>
                <div className='logo-home me-5 text-start'>
                    <Link to="/" transition='default-fade' className='btn-link-default border-0 pe-0 '>
                        <img src={logo} alt="" className="pt-1" style={{ width: "50%" }} />
                    </Link>
                </div>
                <div className='right' >
                    <Menu className='bg-menu-m box-bg-img-cover' right pageWrapId={"page-wrap"} uterContainerId={"outer-container"} isOpen={menuOpen} onStateChange={(state) => handleMenuChange(state)}>
                        <div onClick={() => toggleMenu()} className='py-3 text-end px-3 font-20'>
                            <img src={close} className="" style={{ width: "15px" }} />
                        </div>
                        <div className='weight-400 font-14 text-end px-3 pt-4'>
                            <div><img src={tel} alt="" style={{ height: "15px" }} /> 034-250005, 063-2068498</div>
                            <div><img src={mail} alt="" style={{ height: "15px" }} /> satitdsu@su.ac.th</div>
                        </div>
                        <div className='mt-3 px-3 ps-4'>
                            <div className='d-inline-block'>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="Search"
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        className="text-end input-custom py-1"
                                    />
                                    <Button className="btn btn-default" id="button-addon2 p-0" style={{ border: 'unset', background: "unset" }}>
                                        <img src={searchM} alt="" className="" style={{ width: "25px" }} />
                                    </Button>
                                </InputGroup>
                            </div>
                            <div className='d-block d-md-inline-block'>

                                {datamember ?
                                    <div className=''>
                                        <DropdownButton id="dropdown-basic-button" title={datamember.name + ' ' + datamember.last_name} className="login-menu un">
                                            <Link to='/profile' transition='default-fade' className="dropdown-item">ข้อมูลส่วนตัว</Link>
                                            <Link to='/history_presentation' transition='default-fade' className="dropdown-item">ประวัติการอบรม/สัมมนา</Link>
                                            <Link to='/history_contest' transition='default-fade' className="dropdown-item">ประวัติการประกวดการแข่งขัน</Link>
                                            {/* <Link to='/' transition='default-fade' className="dropdown-item">พิมพ์ประกาศนียบัตร</Link> */}
                                            <Dropdown.Item onClick={() => Logout()}>ออกจากระบบ</Dropdown.Item>
                                        </DropdownButton>
                                    </div>
                                    :
                                    <div className=''>
                                        <button className="btn-login" onClick={() => setModalLogin({ view: true })}>เข้าสู่ระบบ</button>
                                    </div>
                                }
                            </div>
                        </div>
                        <ul className='menu-m'>
                            <li className={menusubOpen.id === 1 ? menusubOpen.view ? "nav-item has-submenu menu-open" : "nav-item has-submenu" : "nav-item has-submenu"}>
                                <a className="nav-link" href="#" onClick={(state) => handleMenusubChange(state, 1)}>
                                    สาธิตวิชาการ ครั้งที่ 8 <img src={sorn} className="arrow90 ms-2" style={{ width: "7px" }} />
                                </a>
                                <ul className="submenu collapse">
                                    <li>
                                        <Link to="/home/historyhost" transition='default-fade' className="btn-link-default">
                                            ประวัติการเป็นเจ้าภาพจัดงานสาธิตวิชาการ
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/home/reason" transition='default-fade' className="btn-link-default">
                                            หลักการและเหตุผล
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/home/objective" transition='default-fade' className="btn-link-default">
                                            วัตถุประสงค์ของโครงการ
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/home/expected" transition='default-fade' className="btn-link-default">
                                            ผลที่คาดว่าจะได้รับ
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/home/institution" transition='default-fade' className="btn-link-default">
                                            โรงเรียนสาธิต 22 สถาบัน
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/home/symbol" transition='default-fade' className="btn-link-default">
                                            การออกแบบสัญลักษณ์ประจำงาน
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/home/mascot" transition='default-fade' className="btn-link-default">
                                            การออกแบบ Mascot ประจำงาน
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/home/song" transition='default-fade' className="btn-link-default">
                                            เพลง สาธิตวิชาการ ครั้งที่ 8
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/home/contact" transition='default-fade' className="btn-link-default">
                                            ติดต่อเรา
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={menusubOpen.id === 2 ? menusubOpen.view ? "nav-item has-submenu menu-open" : "nav-item has-submenu" : "nav-item has-submenu"}>
                                <a className="nav-link" href="#" onClick={(state) => handleMenusubChange(state, 2)}>
                                    กำหนดการ <img src={sorn} className="arrow90 ms-2" style={{ width: "7px" }} />
                                </a>
                                <ul className="submenu collapse">
                                    <li>
                                        <Link to="/bridalGown" transition='default-fade' className="btn-link-default">
                                            การประชุมเตรียมงานและแถลงข่าวฯ
                                        </Link>
                                    </li>
                                    <li>
                                        <a href={special_lecture} target="_blank" className="btn-link-default">
                                            พิธีเปิดงานสาธิตวิชาการครั้งที่ 8
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a href={special_lecture} target="_blank" className="btn-link-default">
                                            การบรรยายพิเศษ
                                        </a>
                                    </li>
                                    <li>
                                        <Link to="/bridalGown" transition='default-fade' className="btn-link-default">
                                            การบรรยายวิชาการ
                                        </Link>
                                    </li> */}
                                    <li>
                                        <a href={seminar} target="_blank" className="btn-link-default">
                                            การอบรม สัมมนาเชิงปฎิบัติการ
                                        </a>
                                    </li>
                                    <li>
                                        <a href={presentation} target="_blank" className="btn-link-default">
                                            การนำเสนองานวิจัยและนวัฒกรรม
                                        </a>
                                    </li>
                                    {/* <li>
                                        <Link to="/bridalGown" transition='default-fade' className="btn-link-default">
                                            การนำเสนอผลงานนักเรียน
                                        </Link>
                                    </li> */}
                                    <li>
                                        <a href={skills} target="_blank" className="btn-link-default">
                                            การแข่งทักษะทางวิชาการ
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/news/news" transition='default-fade' className={data.page === "news" ? "btn-link-default un active" : "btn-link-default un"}>
                                    ข่าวสาร
                                </Link>
                            </li>
                            <li>
                                <Link to="/presentation/presentation" transition='default-fade' className={data.page === "presentation" ? "btn-link-default un active" : "btn-link-default un"}>
                                    การบรรยาย/นำเสนอ
                                </Link>
                            </li>
                            <li>
                                <Link to="/contest/contest" transition='default-fade' className={data.page === "contest" ? "btn-link-default un active" : "btn-link-default un"}>
                                    การประกวดแข่งขัน
                                </Link>
                            </li>
                            <li>
                                <a href="https://www.artsteps.com/view/633bb7f09f8fd82122497011" target="_blank" className={data.page === "contest" ? "btn-link-default un active" : "btn-link-default un"}>
                                    นิทรรศการ
                                </a>
                            </li>
                            <li className={menusubOpen.id === 3 ? menusubOpen.view ? "nav-item has-submenu menu-open" : "nav-item has-submenu" : "nav-item has-submenu"}>
                                <a className="nav-link" href="#" onClick={(state) => handleMenusubChange(state, 3)}>
                                    ข้อมูลอื่นๆ <img src={sorn} className="arrow90 ms-2" style={{ width: "7px" }} />
                                </a>
                                <ul className="submenu collapse">
                                    <li>
                                        <Link to="/bridalGown" transition='default-fade' className="btn-link-default">
                                            หนังสือเชิญเข้าร่วมกิจกรรม<br />สาธิตวิชาการครั้งที่ 8
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/bridalGown" transition='default-fade' className="btn-link-default">
                                            ข้อมูลที่พัก
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/bridalGown" transition='default-fade' className="btn-link-default">
                                            ข้อมูลศูนย์ประชุม
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/bridalGown" transition='default-fade' className="btn-link-default">
                                            รายชื่อผู้ประสานงาน
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/gallery" transition='default-fade' className={data.page === "gallery" ? "btn-link-default un active" : "btn-link-default un"}>
                                    คลังภาพ
                                </Link>
                            </li>
                        </ul>
                    </Menu>
                </div>
            </div>

            <header className='position-sticky top-0 d-none d-md-block bg-menu-top'>
                <div className='bg-white text-end'>
                    <div className="container font-14 py-2">
                        <div className='d-inline-block'><img src={tel} alt="" style={{ height: "15px" }} /> 034-250005, 063-2068498</div>
                        <div className='d-inline-block px-4'>|</div>
                        <div className='d-inline-block me-3'><img src={mail} alt="" style={{ height: "15px" }} /> satitdsu@su.ac.th</div>
                    </div>
                </div>
                <div className='line-gra position-absolute'></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12 position-relative">
                            <ul className='menu-top mb-0 p-0 w-100'>
                                <li>
                                    <div className='logo-home'>
                                        <Link to="/" transition='default-fade' className='btn-link-default border-0 px-0 '>
                                            <img src={logo} alt="" className="" />
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <DropdownButton id="dropdown-basic-button" title="สาธิตวิชาการ ครั้งที่ 8" className="dp-menu">
                                        <Dropdown.Item href="/home/historyhost">ประวัติการเป็นเจ้าภาพจัดงานสาธิตวิชาการ</Dropdown.Item>
                                        <Dropdown.Item href="/home/reason">หลักการและเหตุผล</Dropdown.Item>
                                        <Dropdown.Item href="/home/objective">วัตถุประสงค์ของโครงการ</Dropdown.Item>
                                        <Dropdown.Item href="/home/expected">ผลที่คาดว่าจะได้รับ</Dropdown.Item>
                                        <Dropdown.Item href="/home/institution">โรงเรียนสาธิต 22 สถาบัน</Dropdown.Item>
                                        <Dropdown.Item href="/home/symbol">การออกแบบสัญลักษณ์ประจำงาน</Dropdown.Item>
                                        <Dropdown.Item href="/home/mascot">การออกแบบ Mascot ประจำงาน</Dropdown.Item>
                                        <Dropdown.Item href="/home/song">เพลง สาธิตวิชาการ ครั้งที่ 8</Dropdown.Item>
                                        <Dropdown.Item href="/home/contact">ติดต่อเรา</Dropdown.Item>
                                    </DropdownButton>
                                </li>
                                <li>
                                    <DropdownButton id="dropdown-basic-button" title="กำหนดการ" className="dp-menu">
                                        <Dropdown.Item href="#/action-1">การประชุมเตรียมงานและแถลงข่าวฯ</Dropdown.Item>
                                        {/* <Dropdown.Item href="#/action-2">พิธีเปิดงานสาธิตวิชาการครั้งที่ 8</Dropdown.Item> */}
                                        <Dropdown.Item href={special_lecture} target="_blank">พิธีเปิดงานและการบรรยายพิเศษ</Dropdown.Item>
                                        {/* <Dropdown.Item href="#/action-3">การบรรยายวิชาการ</Dropdown.Item> */}
                                        <Dropdown.Item href={seminar} target="_blank">การอบรม สัมมนาเชิงปฎิบัติการ</Dropdown.Item>
                                        <Dropdown.Item href={presentation} target="_blank">การนำเสนองานวิจัยและนวัฒกรรม</Dropdown.Item>
                                        {/* <Dropdown.Item href="#/action-3">การนำเสนอผลงานนักเรียน</Dropdown.Item> */}
                                        <Dropdown.Item href={skills} target="_blank">การแข่งทักษะทางวิชาการ</Dropdown.Item>
                                    </DropdownButton>
                                </li>
                                <li>
                                    <Link to="/news/news" transition='default-fade' className={data.page === "news" ? "btn-link-default un active" : "btn-link-default un"}>
                                        ข่าวสาร
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/presentation/presentation" transition='default-fade' className={data.page === "presentation" ? "btn-link-default un active" : "btn-link-default un"}>
                                        การบรรยาย/นำเสนอ
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contest/contest" transition='default-fade' className={data.page === "contest" ? "btn-link-default un active" : "btn-link-default un"}>
                                        การประกวดแข่งขัน
                                    </Link>
                                </li>
                                <li>
                                    <a href="https://www.artsteps.com/view/633bb7f09f8fd82122497011" target="_blank" className={data.page === "contest" ? "btn-link-default un active" : "btn-link-default un"}>
                                        นิทรรศการ
                                    </a>
                                </li>
                                <li>
                                    <DropdownButton id="dropdown-basic-button" title="ข้อมูลอื่นๆ" className="dp-menu un">
                                        <Link to='/' transition='default-fade' className="dropdown-item">หนังสือเชิญเข้าร่วมกิจกรรม<br />สาธิตวิชาการครั้งที่ 8</Link>
                                        <Link to='/' transition='default-fade' className="dropdown-item">ข้อมูลที่พัก</Link>
                                        <Link to='/' transition='default-fade' className="dropdown-item">ข้อมูลศูนย์ประชุม</Link>
                                        <Link to='/' transition='default-fade' className="dropdown-item">รายชื่อผู้ประสานงาน</Link>
                                    </DropdownButton>
                                </li>
                                <li>
                                    <Link to="/gallery" transition='default-fade' className={data.page === "gallery" ? "btn-link-default un active" : "btn-link-default un"}>
                                        คลังภาพ
                                    </Link>
                                </li>
                            </ul>
                            <div className='menu-top-right text-end'>
                                <div className='mt-3'>
                                    <div className='d-inline-block me-3'>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder="Search"
                                                aria-label="Recipient's username"
                                                aria-describedby="basic-addon2"
                                                className="text-end input-custom py-1"
                                            />
                                            <Button className="btn btn-default" id="button-addon2 p-0" style={{ border: 'unset', background: "unset" }}>
                                                <img src={search} alt="" className="" style={{ width: "25px" }} />
                                            </Button>
                                        </InputGroup>
                                    </div>
                                    {datamember ?
                                        <div className='d-inline-block'>
                                            <DropdownButton id="dropdown-basic-button" title={datamember.name + ' ' + datamember.last_name} className="login-menu un">
                                                <Link to='/profile' transition='default-fade' className="dropdown-item">ข้อมูลส่วนตัว</Link>
                                                <Link to='/history_presentation' transition='default-fade' className="dropdown-item">ประวัติการอบรม/สัมมนา</Link>
                                                <Link to='/history_contest' transition='default-fade' className="dropdown-item">ประวัติการประกวดการแข่งขัน</Link>
                                                {/* <Link to='/' transition='default-fade' className="dropdown-item">พิมพ์ประกาศนียบัตร</Link> */}
                                                <Dropdown.Item onClick={() => Logout()}>ออกจากระบบ</Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                        :
                                        <div className='d-inline-block'>
                                            <button className="btn-login" onClick={() => setModalLogin({ view: true })}>เข้าสู่ระบบ</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {modalLogin.view ?
                <ModalLogin show={modalLogin.view} onHide={() => setModalLogin({ view: false })} handleModalhide={(e) => handleModalhide()} />
                : null}
        </>
    );
};

export default Header;