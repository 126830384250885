import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

// import iconfb from '../assets/img/icon-fb.svg';
// import iconig from '../assets/img/icon-ig.svg';
// import iconyoutube from '../assets/img/icon-youtube.svg';
// import icontiktok from '../assets/img/icon-tiktok.svg';
// import btnline from '../assets/img/button-line.svg';
import mail from '../assets/img/Letter-W.svg';
import tel from '../assets/img/Tel-W.svg';
import fb from '../assets/img/Facebook.svg';
import logofoot from '../assets/img/logo-footer.svg';

import ssong from '../assets/vdo/instrumental.wav';

import { Link } from "react-tiger-transition";

const Footer = (data) => {

    let history = useHistory();

    // const { t } = useTranslation();
    const url = window.location.pathname;

    const [autoplay, setAutoplay] = useState(false);

    const [memenu, setMemenu] = useState('0');

    useEffect(() => {
        const timer = setTimeout(() => {
            setAutoplay(true)
          }, 2000);
       
    }, []);

    return (
        <>
            <div className='box-footer' style={{ background: "#1C184A" }}>
                <div className='row'>
                    <div className="col-12 col-md-6 col-lg-4 text-start">
                        <div className='pb-3'>
                            <img src={logofoot} className="pe-3" style={{ width: "100px" }} />
                            <img src={logofoot} className="pe-3" style={{ width: "100px" }} />
                        </div>
                        <div className='color-white weight-300 font-14'>
                            โรงเรียนสาธิต มหาวิทยาลัยศิลปากร (มัธยมศึกษา) <br className='d-none d-md-flex' />
                            โรงเรียนสาธิต มหาวิทยาลัยศิลปากร (ปฐมวัยและประถมศึกษา) <br className='d-none d-md-flex' />
                            เลขที่ 6 ถ.ราชมรรคาใน ต.พระปฐมเจดีย์ อ.เมือง จ.นครปฐม 73000
                        </div>
                        <div className='color-white weight-300 mt-4 font-14'>
                            <div><img src={tel} alt="" style={{ height: "15px" }} /> 034-250005, 063-2068498</div>
                            <div><img src={mail} alt="" style={{ height: "15px" }} /> satitdsu@su.ac.th</div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5 text-start mt-5 mt-md-0 mt-lg-0 pe-md-5 position-relative">
                        <div className='position-absolute absolute-center box-absolute-res'>
                            <h5 className='color-white weight-500 mt-3 font-mn'>Facebook Page</h5>
                            <div>
                                <a href="" className='click-page'>
                                    <img src={fb} alt="" style={{ height: "25px" }} /> โรงเรียนสาธิต มหาวิทยาลัยศิลปากร (มัธยมศึกษา)
                                </a>
                            </div>
                            <div className='my-3'>
                                <a href="" className='click-page'>
                                    <img src={fb} alt="" style={{ height: "25px" }} /> โรงเรียนสาธิต มหาวิทยาลัยศิลปากร (ปฐมวัยและประถมศึกษา)
                                </a>
                            </div>
                            <div>
                                <a href="" className='click-page'>
                                    <img src={fb} alt="" style={{ height: "25px" }} /> คณะศึกษาศาสตร์ มหาวิทยาลัยศิลปากร
                                </a>
                            </div>
                            { autoplay ?
                            <div className='mt-3'>
                                <audio controls autoPlay>
                                    <source src={ssong} type="audio/mpeg" />
                                    Your browser does not support the audio element.
                                </audio>
                            </div>
                            : null }
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 text-start mt-5 mt-md-5 mt-lg-0">
                        {/* <div className='color-white weight-300 d-none d-md-block'>
                            <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FSatitAcademic8th&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                                width="320" height="280" style={{ border: "none", overflow: "hidden" }} />
                        </div>
                        <div className='color-white weight-300 d-block d-md-none'>
                            <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FSatitAcademic8th&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                                width="280" height="280" style={{ border: "none", overflow: "hidden" }} />
                        </div> */}
                    </div>
                    <div className="col-12 col-md-12 col-lg-12 text-start pt-5 pt-md-0">
                        <div className='color-white mt-lg-0 weight-300 font-14'>Copyright © 2022 โรงเรียนสาธิตมหาวิทยาลัยศิลปากร</div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Footer;