import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import { useParams, useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';

import Header from '../layout/header';
import Footer from '../layout/footer';

import { Link } from "react-tiger-transition";
import Swal from 'sweetalert2';
import Moment from 'moment';

import AuthService from "../components/services_member/auth.service";
import GetService from "../components/services_member/get.service";
import ModalFrom from "../layout/modal/modalFrom";

import loading from '../assets/img/loading.gif';
import bg from '../assets/img/bg-history.png';
import eyedetail from '../assets/img/eye-detail.svg';
import iuser from '../assets/img/user.svg';
import search from '../assets/img/search.svg';

const Historycontest = () => {

    const user = JSON.parse(localStorage.getItem("member"));
    const [token, setToken] = useState(user);

    if (!user) {
        window.location.href = "/";
        // console.log('no login')
    } else {
        // console.log('login')
    }

    const memberdata = localStorage.getItem("member_data");

    const { id } = useParams();

    let history = useHistory();

    const [dataMap, setDataMap] = useState([]);
    const [dataMapSearch, setDataMapSearch] = useState([]);
    const [modalFrom, setModalFrom] = useState({ view: false });

    const fetchData = useCallback(async (table, memberId) => {

        GetService.getAllHistory(table, memberId).then(
            (response) => {
                // console.log(response)
                if (response.status === 200) {
                    setDataMap(response.data)
                    setDataMapSearch(response.data)
                    // localStorage.setItem("member_data", JSON.stringify(response.data_member));
                    // setDatamember(response.data_member);
                    // console.log(iddetail)
                }
            }, (error) => {
                // window.location.reload();
            }
        )
    });

    useEffect(() => {
        fetchData('contest', JSON.parse(memberdata).id)
    }, []);

    const handleModalhide = () => {
        setModalFrom({ view: false })
    }

    const handleKeyUp = (e) => {
        // console.log(e.target.value);
        // console.log(event.code);
        let keyword = e.target.value;
        if (keyword !== '') {
            const results = dataMap.filter(valb => valb.topic.match(new RegExp(keyword, "i")));
            setDataMapSearch(results)
        } else {
            setDataMapSearch(dataMap)
        }
    };

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="home" />
                <div className="wrapper bg-white box-index">
                    <section className="" id="page-wrap">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">
                                    <div className="w-100 position-relative d-none d-md-block">
                                        <img src={bg} className="w-100 border-blue" />
                                        <div className='position-absolute absolute-navigation w-100'>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className='font-14'>
                                                            <Link to="/" transition='default-fade' className="a-default">หน้าแรก</Link>
                                                            <span className='mx-2'>|</span>
                                                            <span className='text-decoration-underline'>ประวัติการประกวดแข่งขัน</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='position-absolute absolute-center w-100'>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className='topic-news weight-600'>ประวัติการประกวดแข่งขัน</div>
                                                        <div className='font-14'><img src={iuser} style={{ height: "13px" }} /> {JSON.parse(memberdata).name} {JSON.parse(memberdata).last_name}</div>
                                                        <div className='font-14'>{Moment(JSON.parse(memberdata).date_create).format('DD/MM/YYYY')} | {Moment(JSON.parse(memberdata).date_create).format('HH:mm')}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='position-absolute box-absolute-his w-100'>
                                            <div className="container">
                                                <div className='box-tag color-white px-4 py-4 text-start' style={{ background: "#1C184A" }}>
                                                    <div className='d-inline-block me-3'>ค้นหา</div>
                                                    <div className='d-inline-block position-relative w-50'>
                                                        <Form.Group controlId="">
                                                            <input type="text" onKeyUp={handleKeyUp} className="form-control py-1 text-end input-custom font-14" name="key" placeholder='Search' />
                                                            <div className='position-absolute' style={{ top: "1px", right: "-30px" }}>
                                                                <img src={search} alt="" className="" style={{ width: "22px" }} />
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* mobile */}
                                    <div className='d-block d-md-none '>


                                        <div className="w-100 position-relative border-blue pb-3 bg-center" style={{ backgroundImage: `url(${bg})` }}>
                                            {/* <img src={bg} className="w-100 border-blue" /> */}
                                            <div className='absolute-navigation w-100'>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-12 col-md-12 col-lg-12 text-start">
                                                            <div className='font-14'>
                                                                <Link to="/" transition='default-fade' className="a-default">หน้าแรก</Link>
                                                                <span className='mx-2'>|</span>
                                                                <span className='text-decoration-underline'>ประวัติการประกวดแข่งขัน</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-100 mt-3'>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-12 col-md-12 col-lg-12 text-start">
                                                            <div className='topic-news weight-600'>ประวัติการประกวดแข่งขัน</div>
                                                            <div className='font-14'><img src={iuser} style={{ height: "13px" }} /> {JSON.parse(memberdata).name} {JSON.parse(memberdata).last_name}</div>
                                                            <div className='font-14'>{Moment(JSON.parse(memberdata).date_create).format('DD/MM/YYYY')} | {Moment(JSON.parse(memberdata).date_create).format('HH:mm')}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 mt-3'>
                                            <div className="container p-0">
                                                <div className='box-tag color-white px-4 py-4 text-start' style={{ background: "#1C184A" }}>
                                                    <div className='d-inline-block me-3'>ค้นหา</div>
                                                    <div className='d-inline-block position-relative w-75'>
                                                        <Form.Group controlId="">
                                                            <input type="text" onKeyUp={handleKeyUp} className="form-control py-1 text-end input-custom font-14" name="key" placeholder='Search' />
                                                            <div className='position-absolute' style={{ top: "1px", right: "-30px" }}>
                                                                <img src={search} alt="" className="" style={{ width: "22px" }} />
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='box-history mt-md-5'>
                        <div className="container p-md-0 bg-white">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-12 col-lg-12 text-start px-0 px-md-4">

                                    <div className='shadow-sm px-3 px-md-5 pt-3 pb-5 mt-3 mb-5'>
                                        <div className='row'>
                                            <div className='col-12 col-md-12 col-lg-12'>
                                                {dataMapSearch.length ? dataMapSearch.sort((a, b) => b.id - a.id).map((val, index) => (
                                                    <div className='border-loop py-2' key={index}>
                                                        <div className='row align-items-center'>
                                                            <div className='col-12 col-md-8 col-lg-8'>
                                                                {val.topic}
                                                            </div>
                                                            <div className='col-6 col-md-4 col-lg-4 py-3 py-md-0 text-end'>
                                                                { val.position !== '0' ? val.status_cer ? JSON.parse(memberdata).status_form === '1' ?
                                                                    <a onClick={() => setModalFrom({ view: true, id: val.id,text:val.topic, type: "cer",dataCompete: JSON.parse(val.compete),dataTrainee: JSON.parse(val.trainee) })} className="cursor-pointer btn-border d-inline-block w-auto px-4 me-3">
                                                                        <i className="fa fa-certificate"></i> ใบประกาศนียบัตร
                                                                    </a>
                                                                    :
                                                                    <a onClick={() => setModalFrom({ view: true, id: JSON.parse(memberdata).id, type: "form" })} className="cursor-pointer btn-border d-inline-block w-auto px-4 me-3">
                                                                        <i className="fa fa-certificate"></i> ใบประกาศนียบัตร
                                                                    </a>
                                                                    : null : null }
                                                                <Link to={"/contest/detail/" + val.contest_id} transition='default-fade' className="btn-border d-inline-block w-auto px-4">
                                                                    <img src={eyedetail} alt="" className="" style={{ width: "20px" }} /> ดูรายละเอียด
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )) : <div className='py-5 text-center'>- No data -</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </div>

            {modalFrom.view ?
                <ModalFrom show={modalFrom.view} type={{type:modalFrom.type,cat:'contest',id:modalFrom.id}} text={modalFrom.text} dataCompete={modalFrom.dataCompete} dataTrainee={modalFrom.dataTrainee} onHide={() => setModalFrom({ view: false })} id={JSON.parse(memberdata).id} handleModalhide={(e) => handleModalhide()} />
                : null}

        </>
    );
};

export default Historycontest;