import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getAllBusinessPosts = () => {
  return axios.get(API_URL + "/business/business_all", { headers: authHeader.authHeaderMember() });
};
const getAllBusinessOne = () => {
  return axios.get(API_URL + "/business/business_one", { headers: authHeader.authHeaderBusiness() })
  .then((response) => {
    return response.data;
  });
};

const getAllBranchPosts = () => {
  return axios.get(API_URL + "/branch/branch_all", { headers: authHeader.authHeaderMember() })
  .then((response) => {
    return response.data;
  });
};

// const getAllBranchPosts = (id) => {
//   return axios.post(API_URL + "/branch/branch_all", {id});
// };

const getAllPrivatePosts = () => {
  return axios.get(API_URL + "/register/data_private", { headers: authHeader.authHeaderMember() });
};

const getAllPresentation = (id,member) => {
  return axios.get(API_URL + "/api/v1/presentation/" + id + '?member='+member)
  .then((response) => {
    return response.data;
  });
};

const getAllContest = (id,member) => {
  return axios.get(API_URL + "/api/v1/contest/" + id + '?member='+member)
  .then((response) => {
    return response.data;
  });
};

const getAllBanner = (id) => {
  return axios.get(API_URL + "/api/v1/banner")
  .then((response) => {
    return response.data;
  });
};

const getAllNews = (id) => {
  return axios.get(API_URL + "/api/v1/news/" + id )
  .then((response) => {
    return response.data;
  });
};
const getAllGallery = (id) => {
  return axios.get(API_URL + "/api/v1/gallery/" + id )
  .then((response) => {
    return response.data;
  });
};
const getAllHistory = (table,memberID) => {
  return axios.get(API_URL + "/api/v1/history/" + memberID + "?table="+table )
  .then((response) => {
    return response.data;
  });
};
const getAllHost = () => {
  return axios.get(API_URL + "/api/v1/host" )
  .then((response) => {
    return response.data;
  });
};
const getAllHome = (id) => {
  return axios.get(API_URL + "/api/v1/home/"+id )
  .then((response) => {
    return response.data;
  });
};

const getOtp = (id) => {
  return axios.get(API_URL + "/api/v1/otp/"+id )
  .then((response) => {
    return response.data;
  });
};

const getService = {
  getAllBusinessPosts,
  getAllPrivatePosts,
  getAllBusinessOne,
  getAllBranchPosts,
  getAllPresentation,
  getAllBanner,
  getAllContest,
  getAllNews,
  getAllHistory,
  getAllHost,
  getAllHome,
  getOtp,
  getAllGallery
};

export default getService;