import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import { useParams } from 'react-router-dom';
// import { compareAsc, format } from 'date-fns';
import Moment from 'moment';
import InputMask from 'react-input-mask';

import Header from '../../layout/header';
import Footer from '../../layout/footer';

import { Link } from "react-tiger-transition";
import Swal from 'sweetalert2'

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";
import GetService from "../../components/services_member/get.service";

import loading from '../../assets/img/loading.gif';
import bg from '../../assets/img/bg-contest.jpg';
import a1 from '../../assets/img/a-1.jpg';
import calendar from '../../assets/img/calendar.svg';
import search from '../../assets/img/searchM.svg';

import ModalLogin from "../../layout/modal/modalLogin";

const Contest = (datat) => {

    // const memberdata = localStorage.getItem("member_data");

    // if (!memberdata) {
    //     // window.location.href = "/";
    //     // console.log('no login')
    // } else {
    //     // console.log('login')
    // }

    const [dateday, setDateday] = useState(Moment(new Date()).format('DD/MM/yyyy'));

    const dateoption = {
        locale: {
            format: 'DD/MM/YYYY'
        },
        startDate: dateday,
        // maxDate: dateday,
        singleDatePicker: true,
        autoApply: true,
    };

    const [dataMap, setDataMap] = useState([]);
    const [dataPresentationSearch, setDataPresentationSearch] = useState([]);
    const [dataMember, setDataMember] = useState();

    const fetchData = useCallback(async (id) => {
        // console.log(user)
        // if (user) {

        GetService.getAllContest(id).then(
            (response) => {
                // console.log(response)
                if (response.status === 200) {
                    setDataMap(response.data)
                    // setDataPresentationSearch(response.data)
                    // localStorage.setItem("member_data", JSON.stringify(response.data_member));
                    // setDatamember(response.data_member);
                }
            }, (error) => {
                // window.location.reload();
            }
        )
        // }
    });

    useEffect(() => {
        fetchData(0)
    }, []);

    const handledateday = (event) => {

    }

    const [modalLogin, setModalLogin] = useState({ view: false });

    const handleModalhide = () => {
        setModalLogin({ view: false })
        setTimeout(window.location.reload(), 3000);
    }

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="home" datamember={datat.datamember} />
                <div className="wrapper bg-white box-index">
                    <section className="mt-3" id="page-wrap">
                        <div className="container-fluid p-0 bg-start" style={{ backgroundImage: `url(${bg})` }}>
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">
                                    <div className="w-100 position-relative">
                                        <div className='w-100'>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className='font-14'>
                                                            <Link to="/" transition='default-fade' className="a-default">หน้าแรก</Link>
                                                            <span className='mx-2'>|</span>
                                                            <span className='text-decoration-underline'>การประกวดแข่งขัน</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='w-100 mt-5'>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className='topic-news weight-600 mb-3'>การประกวดแข่งขัน</div>
                                                        {datat.member ?
                                                            null
                                                            :
                                                            <div className='box-login-alert p-4 position-relative'>
                                                                <div className='color-white font-18 weight-500 pt-3 ps-3'>กรุณาเข้าสู่ระบบ</div>
                                                                <div className='color-white font-14 weight-300 pb-3 ps-3'>
                                                                    - กรุณาเข้าสู่ระบบเพื่อทำรายการที่ท่านต้องการ หากท่านไม่ทำการเข้าสู่ระบบท่านจะไม่สามารถทำรายการได้ <br />
                                                                    - กรณีที่ยังไม่มีข้อมูลสำหรับเข้าสู่ระบบ ให้ทำการลงทะเบียน
                                                                </div>
                                                                <div className='color-white box-absolute-right-center pe-5'>
                                                                    <button className='btn-seemore' onClick={() => setModalLogin({ view: true })}>เข้าสู่ระบบ</button>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="container mb-5 bg-white">
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className='shadow-sm px-1 px-md-4 py-5'>
                                                            <div className='row'>
                                                                <div className="col-12 col-md-12 col-lg-12 text-start pb-5">
                                                                    <div className='row'>
                                                                        <div className="col-12 col-md-3 col-lg-3 text-start">
                                                                            <div className='text-start py-3 weight-500 d-inline-block me-3'>ค้นหาการประกวดแข่งขัน</div>
                                                                        </div>
                                                                        <div className="col-7 col-md-7 col-lg-7 text-start">
                                                                            <div className='position-relative'>
                                                                                <Form.Group controlId="">
                                                                                    <input type="text" className="form-control py-2 pe-5 text-end input-custom-2" name="key" placeholder='Search' />
                                                                                    <div className='position-absolute' style={{ top: "10px", right: "12px" }}>
                                                                                        <img src={search} alt="" className="" style={{ width: "22px" }} />
                                                                                    </div>
                                                                                </Form.Group>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-5 col-md-2 col-lg-2 text-start">
                                                                            <div className='position-relative d-inline-block'>
                                                                                <Form.Group controlId="dob">
                                                                                    <DateRangePicker initialSettings={dateoption} onApply={(e) => (handledateday(e))}>
                                                                                        <input type="text" className="form-control px-2 py-2 input-custom-2" name="dateday" />
                                                                                    </DateRangePicker >
                                                                                    <div className='position-absolute' style={{ top: "10px", right: "12px" }}>
                                                                                        <img src={calendar} alt="" className="" style={{ width: "17px" }} />
                                                                                    </div>
                                                                                </Form.Group>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row px-5'>
                                                                {dataMap.length ? dataMap.map((val, index) => (
                                                                    <div className='col-12 col-md-4 col-lg-4 p-3' key={index}>
                                                                        <Link to={"/contest/detail/" + val.id} className="img-hover">
                                                                            <img src={process.env.REACT_APP_API_URL + "/uploads/contest/" + val.id + '/' + val.img} alt="" className="w-100" />
                                                                            <div className="overlay shadow">
                                                                                <div className="overlay-text">
                                                                                    <div className='weight-600'>
                                                                                        {val.topic}
                                                                                    </div>
                                                                                    <div className='font-14 weight-300 d-none d-md-block'>{val.school}</div>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                ))
                                                                    : <div className='py-5 text-center'>- No data -</div>}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </div>
            {modalLogin.view ?
                <ModalLogin show={modalLogin.view} onHide={() => setModalLogin({ view: false })} handleModalhide={(e) => handleModalhide()} />
                : null}
        </>
    );
};

export default Contest;