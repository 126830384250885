import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import { useParams } from 'react-router-dom';
// import { compareAsc, format } from 'date-fns';
import Moment from 'moment';

import Header from '../../layout/header';
import Footer from '../../layout/footer';

import { Link } from "react-tiger-transition";

import GetService from "../../components/services_member/get.service";

import Modalgallery from "../../layout/modal/modalGallery";

const Gallerydetail = (datat) => {

    const { idde } = useParams();

    // const [dataMap, setDataMap] = useState([]);
    const [dataMapSearch, setDataMapSearch] = useState([]);
    const [modalgallery, setModalgallery] = useState({ view: false });

    const fetchData = useCallback(async (idde) => {

        GetService.getAllGallery(idde).then(
            (response) => {
                // console.log(response)
                if (response.status === 200) {
                    // setDataMap(response.data)
                    setDataMapSearch(response.data)
                }
            }, (error) => {
                // window.location.reload();
            }
        )
    });

    useEffect(() => {
        fetchData(idde)
    }, []);

    // console.log(idde)

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="news" />
                <div className="wrapper bg-white box-index">
                    <section className="mt-3" id="page-wrap">
                        <div className="container-fluid p-0 bg-start">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">
                                    <div className="w-100 position-relative">
                                        <div className='w-100'>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className='font-14'>
                                                            <Link to="/" transition='default-fade' className="a-default">หน้าแรก</Link>
                                                            <span className='mx-2'>|</span>
                                                            <Link to="/gallery" transition='default-fade' className="a-default">คลังภาพ</Link>
                                                            <span className='mx-2'>|</span>
                                                            <span className='text-decoration-underline'>{dataMapSearch.topic}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 mt-4'>
                                            <div className="container mb-5 bg-white">
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className='py-5'>
                                                            <div className='row'>
                                                                <div className='col-12 col-md-12 col-lg-12 p-3 pt-0' >
                                                                    <div className='topic-news weight-600 mb-3'>{dataMapSearch.topic}</div>
                                                                    <div className='weight-300 font-14'>
                                                                        {Moment(dataMapSearch.date_active).format('DD/MM/YYYY')} | {Moment(dataMapSearch.date_active).format('HH:mm')}
                                                                    </div>
                                                                    <div className='weight-300 font-14'>
                                                                        สถานที่ : {dataMapSearch.location} | โดย : {dataMapSearch.by}
                                                                    </div>
                                                                    <div className='weight-300 pt-3 px-2'>
                                                                        <div className='row'>
                                                                            { dataMapSearch.img ?
                                                                            JSON.parse(dataMapSearch.img).map((vall, indexl) => (
                                                                                <div className='col-4 col-md-3 col-lg-2 p-1' key={indexl} > 
                                                                                    <img onClick={() => setModalgallery({ view: true, data: JSON.parse(dataMapSearch.img), active: indexl, id: dataMapSearch.id })}
                                                                                     src={process.env.REACT_APP_API_URL + "/uploads/gallery/" + dataMapSearch.id + '/' + vall} alt="" className="w-100 cursor-pointer" />
                                                                                </div>
                                                                            ))
                                                                            : null   }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </div>
            {modalgallery.view ?
                <Modalgallery show={modalgallery.view} onHide={() => setModalgallery({ view: false })} gallery={modalgallery.data} active={modalgallery.active} id={modalgallery.id} />
                : null}
        </>
    );
};

export default Gallerydetail;