import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import sorn from '../../assets/img/sorn.svg';

const Modalgallery = (datat) => {

    const handleDragStart = (e) => e.preventDefault();

    const responsive = {
        0: { items: 1 },
        568: { items: 1 },
        1024: { items: 1 },
    };

    console.log(datat)

    return (
        <>
            <Modal {...datat} className="modal-custom" size="md" backdrop="static" centered keyboard={false}>
                <Modal.Header closeButton className='btn-close-white'>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className="d-block w-100 scroll-custom">
                        <div className='h-100per-modal'>

                            <div className='gallery-full'>
                                <AliceCarousel mouseTracking
                                    responsive={responsive}
                                    infinite
                                    controlsStrategy="alternate"
                                    disableDotsControls
                                    // autoHeight
                                    activeIndex={datat.active}
                                    renderPrevButton={() => {
                                        return <p className="" style={{ left: "30px" }}>
                                            <img src={sorn} className="sorn-left" />
                                        </p>
                                    }}
                                    renderNextButton={() => {
                                        return <p className="" style={{ right: "30px" }}>
                                            <img src={sorn} className="sorn-right" />
                                        </p>
                                    }} >
                                    {
                                        (datat.gallery).map((value, index) => (
                                            <img src={process.env.REACT_APP_API_URL + "/uploads/gallery/" + datat.id + "/" + value} onDragStart={handleDragStart} className="w-100" key={index} />
                                        ))
                                    }
                                </AliceCarousel>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Modalgallery;