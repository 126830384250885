import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Modal, Button, Form, Col, Row } from "react-bootstrap";
import { useParams, useHistory } from 'react-router-dom';
// import { compareAsc, format } from 'date-fns';
import Moment from 'moment';

import InputMask from 'react-input-mask';

import Header from '../../layout/header';
import Footer from '../../layout/footer';

import { Link } from "react-tiger-transition";
import Swal from 'sweetalert2'

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import PostService from "../../components/services_member/post.service";
import GetService from "../../components/services_member/get.service";
import ModalLoginpage from "../../layout/modal/modalLogin";

import loading from '../../assets/img/loading.gif';
import bg from '../../assets/img/bg-contest.jpg';
import a1 from '../../assets/img/a-1.jpg';
import calendar from '../../assets/img/calendar.svg';
import search from '../../assets/img/searchM.svg';

import dellist from '../../assets/img/i=plus.svg';
import addlist from '../../assets/img/i=plus.svg';

const Detail = (datat) => {

    const { id } = useParams();

    let history = useHistory();

    const [dataContest, setDataContest] = useState([]);
    const [loadingView, setLoadingView] = useState(false);

    const fetchData = useCallback(async (id, member_id) => {

        GetService.getAllContest(id, member_id).then(

            (response) => {

                console.log(response)
                if (response.status === 200) {
                    setDataContest(response.data)
                }
            }, (error) => {
                // window.location.reload();
            }
        )
    });

    useEffect(() => {
        fetchData(id, datat.member ? datat.member.mem : null)
    }, []);

    const [validated, setValidated] = useState(false);
    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})

    const [modalLoginpage, setModalLoginpage] = useState({ view: false });

    const handleModalhide = () => {
        setModalLoginpage({ view: false })
        setTimeout(window.location.reload(), 3000);
    }

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        for (let ik = 0; ik < selectedFile.length; ik++) {
            data.append("posterFile[]", selectedFile[ik]);
        }

        for (let i = 0; i < selectedFile2.length; i++) {
            data.append("docFile[]", selectedFile2[i]);
        }

        // console.log(inputFields2)

        // data.append("file_doc", id);

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {

            setLoadingView(true)

            PostService.regisContest(data).then(
                (res) => {
                    // console.log(res.status)
                    if (res.status === 200) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: '',
                            text: res.messages,
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            history.push("/contest/contest");
                            // window.location.reload()
                            // onHideadd();
                        })
                        // datat.onHide();
                        setLoadingView(false);
                    } else {

                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: '',
                            text: res.messages,
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            history.push("/contest/contest");
                        })

                        setLoadingView(false);
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }

        setValidated(true);
    };

    const [inputFields, setInputFields] = useState([{ "more_name": "" }]);
    const [inputFields2, setInputFields2] = useState([{ "more_name": "" }]);

    // const handleFormChange = (index, event) => {
    //     let data = [...inputFields];
    //     data[index][event.target.more_name] = event.target.more_price;
    //     setInputFields(data);
    // }



    const addFields = () => {
        let newfield = { more_name: '' }
        setInputFields([...inputFields, newfield])
    }

    const removeFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1)
        setInputFields(data)
    }

    const addFields2 = () => {
        let newfield = { more_name: '' }
        setInputFields2([...inputFields2, newfield])
    }
    const removeFields2 = (index) => {
        let data = [...inputFields2];
        data.splice(index, 1)
        setInputFields2(data)
    }

    const fileInput = useRef(null);
    const [selectedFile, setSelectedFile] = useState([]);
    const [selectedFileerror, setSelectedFileerror] = useState(null);

    const handleFileInput = (e) => {
        // handle validations
        // setSelectedFile(e.target.files[0])
        // const file = e.target.files[0];
        const file = e.target.files[0];
        // if ((file.size / 1024) > 2048) {
        //     setSelectedFileerror("File size cannot exceed more than 2MB");
        //     fileInput.current.value = null;
        // } else {
        //     setSelectedFile(e.target.files[0])
        // }


        setSelectedFile([...selectedFile, file])
        // console.log((file.size/ 1024))
        // console.log(formatBytes(file.size))
    }

    const delPoster = (index) => {
        // setSelectedFile();
        setSelectedFile([
            ...selectedFile.slice(0, index),
            ...selectedFile.slice(index + 1)
        ]);

        if (selectedFile.length - 1 == 0) {
            //     alert(0)
            // }else{
            fileInput.current.value = null;
            //     alert(9)
        }
    }

    const fileInput2 = useRef(null);
    const [selectedFile2, setSelectedFile2] = useState([]);
    const [selectedFileerror2, setSelectedFileerror2] = useState(null);

    const handleFileInput2 = (e) => {
        // handle validations
        const file = e.target.files[0];

        // const uploaded = [...selectedFile2];
        // console.log(file)
        if ((file.size / 1024) > 102400) {
            setSelectedFileerror2("File size cannot exceed more than 100MB");
            // fileInput2.current.value = null;
        } else {
            // uploaded.push(file)
            setSelectedFileerror2(null);
            setSelectedFile2([...selectedFile2, file])
        }
    }
    // console.log(selectedFile2)

    const delPoster2 = (index) => {
        // setSelectedFile2();

        setSelectedFile2([
            ...selectedFile2.slice(0, index),
            ...selectedFile2.slice(index + 1)
        ]);

        if (selectedFile2.length - 1 == 0) {
            //     alert(0)
            // }else{
            fileInput2.current.value = null;
            //     alert(9)
        }
        // console.log(selectedFile2.length - 1)

    }

    const formatBytes = (bytes, decimals = 2) => {
        if (!+bytes) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    const cancleApp = (id) => {

        const dataf = new FormData();
        dataf.append("id", id);
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.isConfirmed) {
                PostService.delContest(dataf).then(
                    (res) => {
                        console.log(res.status)
                        if (res.status === 200) {
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: '',
                                text: res.messages,
                                showConfirmButton: false,
                                timer: 1500
                            }).then(function () {
                                // history.push("/");
                                window.location.reload();
                            })
                        } else {

                            Swal.fire({
                                position: 'center',
                                icon: 'error',
                                title: '',
                                text: res.messages,
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }
        })

        // alert(id_mem)
    }

    // console.log(selectedFile2)

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="home" />
                {dataContest ?
                    <div className="wrapper bg-white box-index">
                        {loadingView ?
                            <div className='position-fixed box-loading'>
                                <div className='box-absolute-center position-absolute'>
                                    <img src={loading} style={{ width: "60px" }} />
                                    <div className='mt-3'>กรุณารอสักครู่...</div>
                                </div>
                            </div>
                            : null}
                        <section className="mt-3" id="page-wrap">
                            <div className="container-fluid p-0 bg-start">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12 position-relative">
                                        <div className="w-100 position-relative">
                                            {/* <img src={bg} className="w-100" /> */}
                                            <div className='w-100'>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-12 col-md-12 col-lg-12 text-start">
                                                            <div className='font-14'>
                                                                <Link to="/" transition='default-fade' className="a-default">หน้าแรก</Link>
                                                                <span className='mx-2'>|</span>
                                                                <Link to="/contest/contest" transition='default-fade' className="a-default">การประกวดการแข่งขัน</Link>
                                                                <span className='mx-2'>|</span>
                                                                <Link to={"/contest/detail/" + id} transition='default-fade' className="a-default">{dataContest.topic}</Link>
                                                                <span className='mx-2'>|</span>
                                                                <span className='text-decoration-underline'>
                                                                    เข้าร่วม
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-5">
                                                        <div className="col-12 col-md-12 col-lg-12 text-start">
                                                            <div className='topic-news weight-500 mb-3'>รายละเอียดการสมัครเข้าร่วมการประกวด</div>
                                                            {datat.member ?
                                                                null
                                                                : <div className='box-login-alert p-4 position-relative' style={{ opacity: datat.member ? 1 : 1 }}>
                                                                    <div className='color-white font-18 weight-500 pt-3 ps-3'>กรุณาเข้าสู่ระบบ</div>
                                                                    <div className='color-white font-14 weight-300 pb-3 ps-3'>
                                                                        - กรุณาเข้าสู่ระบบเพื่อทำรายการที่ท่านต้องการ หากท่านไม่ทำการเข้าสู่ระบบท่านจะไม่สามารถทำรายการได้ <br />
                                                                        - กรณีที่ยังไม่มีข้อมูลสำหรับเข้าสู่ระบบ ให้ทำการลงทะเบียน
                                                                    </div>
                                                                    <div className='color-white box-absolute-right-center pe-5'>
                                                                        <button className='btn-seemore' onClick={() => setModalLoginpage({ view: true })}>เข้าสู่ระบบ</button>
                                                                    </div>
                                                                </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="container mb-5 bg-white">
                                                    <div className="row justify-content-center">
                                                        <div className="col-12 col-md-12 col-lg-12 text-start">
                                                            <div className=''>
                                                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                                                    <div className='row'>
                                                                        <div className="col-12 col-md-6 col-lg-6">
                                                                            <div className=''>
                                                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                                    <Form.Label className='font-14 weight-500'>ชื่อโรงเรียน <span className='color-red'>*</span></Form.Label>
                                                                                    <Form.Control type="text" name='schoolName' className='form-control register-custom ps-0 font-14' placeholder='ชื่อโรงเรียน' required />
                                                                                </Form.Group>
                                                                            </div>
                                                                        </div>
                                                                        {dataContest.team === '2' ?
                                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                                <div className=''>
                                                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                                        <Form.Label className='font-14 weight-500'>จำนวนสมาชิก <span className='color-red'>*</span></Form.Label>
                                                                                        <Form.Control type="number" min={0} name='qty' className='form-control register-custom ps-0 font-14' placeholder='จำนวนสมาชิก' required />
                                                                                    </Form.Group>
                                                                                </div>
                                                                            </div>
                                                                            : null}
                                                                    </div>
                                                                    <div className='row'>
                                                                        {dataContest.class ?
                                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                                <div className='mb-3'>
                                                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                                        <Form.Label className='font-14 weight-500 d-block'>ระดับชั้น <span className='color-red'>*</span></Form.Label>
                                                                                        <Form.Select aria-label="Default select example" name="class" className='form-control register-custom ps-0 font-14' required>
                                                                                            <option value="">เลือกระดับชั้น</option>
                                                                                            {dataContest.class ? JSON.parse(dataContest.class).filter(valb => valb === '1').length ? <>
                                                                                                <option value="1">ประถมศึกษาปีที่ 1</option>
                                                                                                <option value="2">ประถมศึกษาปีที่ 2</option>
                                                                                                <option value="3">ประถมศึกษาปีที่ 3</option>
                                                                                            </> : null : null}

                                                                                            {dataContest.class ? JSON.parse(dataContest.class).filter(valb => valb === '2').length ? <>
                                                                                                <option value="4">ประถมศึกษาปีที่ 4</option>
                                                                                                <option value="5">ประถมศึกษาปีที่ 5</option>
                                                                                                <option value="6">ประถมศึกษาปีที่ 6</option>
                                                                                            </> : null : null}

                                                                                            {dataContest.class ? JSON.parse(dataContest.class).filter(valb => valb === '3').length ? <>
                                                                                                <option value="7">มัธยมศึกษาปีที่ 1</option>
                                                                                                <option value="8">มัธยมศึกษาปีที่ 2</option>
                                                                                                <option value="9">มัธยมศึกษาปีที่ 3</option>
                                                                                            </> : null : null}
                                                                                            {dataContest.class ? JSON.parse(dataContest.class).filter(valb => valb === '4').length ? <>
                                                                                                <option value="10">มัธยมศึกษาปีที่ 4</option>
                                                                                                <option value="11">มัธยมศึกษาปีที่ 5</option>
                                                                                                <option value="12">มัธยมศึกษาปีที่ 6</option>
                                                                                            </> : null : null}
                                                                                        </Form.Select>
                                                                                    </Form.Group>
                                                                                </div>
                                                                            </div>
                                                                            : null}
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-12 col-md-6 col-lg-6">
                                                                            <div className='mb-3'>
                                                                                {inputFields.map((input, index) => index === 0 ?
                                                                                    <Row className="align-items-end mb-3" key={index}>
                                                                                        <Form.Group as={Col} md="12" className="position-relative" controlId="validationCustom01">
                                                                                            <Form.Label className="font-14 weight-500">ชื่อ-นามสกุล ผู้เข้าประกวด (กรุณาใส่คำนำหน้า)<span className='color-red'>*</span></Form.Label>
                                                                                            <Form.Control type="text" name='compete[]' placeholder='ชื่อ-นามสกุล' className='form-control register-custom ps-0 font-14' required />
                                                                                        </Form.Group>
                                                                                    </Row>
                                                                                    : null
                                                                                )}
                                                                                {inputFields.map((input, index) => (
                                                                                    index !== 0 ?
                                                                                        <Row className="align-items-end mt-3 mb-3 pifo" key={index}>
                                                                                            <Form.Group as={Col} md="12" className="position-relative" controlId="validationCustom01">
                                                                                                <Form.Control type="text" name='compete[]' placeholder='ชื่อ-นามสกุล'
                                                                                                    className='register-custom ps-0 font-14' required />
                                                                                                <span onClick={() => removeFields(index)} className="position-absolute top-0 end-0 cursor-pointer">
                                                                                                    <img src={dellist} alt="" className="" style={{ width: "22px", transform: "rotate(45deg)" }} />
                                                                                                </span>
                                                                                            </Form.Group>
                                                                                        </Row>
                                                                                        : null
                                                                                ))}
                                                                            </div>
                                                                            {dataContest.team === '2' ?
                                                                                <div className='mb-5 '>
                                                                                    <span className='cursor-pointer' onClick={addFields}>
                                                                                        <img src={addlist} alt="" className="me-2" style={{ width: "26px" }} />
                                                                                        เพิ่มรายการ
                                                                                    </span>
                                                                                </div>
                                                                                : null}

                                                                            <div className='mb-3'>
                                                                                {inputFields2.map((input, index) => index === 0 ?
                                                                                    <Row className="align-items-end mb-3" key={index}>
                                                                                        <Form.Group as={Col} md="12" className="position-relative" controlId="validationCustom01">
                                                                                            <Form.Label className="font-14 weight-500">ชื่อ-นามสกุล อาจารย์ที่ปรึกษา/ผู้ฝึกซ้อม (กรุณาใส่คำนำหน้า)<span className='color-red'>*</span></Form.Label>
                                                                                            <Form.Control type="text" name='trainee[]' placeholder='ชื่อ-นามสกุล' className='form-control register-custom ps-0 font-14' required />
                                                                                        </Form.Group>
                                                                                    </Row>
                                                                                    : null
                                                                                )}
                                                                                {inputFields2.map((input, index) => (
                                                                                    index !== 0 ?
                                                                                        <Row className="align-items-end mt-3 mb-3 pifo" key={index}>
                                                                                            <Form.Group as={Col} md="12" className="position-relative" controlId="validationCustom01">
                                                                                                <Form.Control type="text" name='trainee[]' placeholder='ชื่อ-นามสกุล'
                                                                                                    className='register-custom ps-0 font-14' required />
                                                                                                <span onClick={() => removeFields2(index)} className="position-absolute top-0 end-0 cursor-pointer">
                                                                                                    <img src={dellist} alt="" className="" style={{ width: "22px", transform: "rotate(45deg)" }} />
                                                                                                </span>
                                                                                            </Form.Group>


                                                                                        </Row>
                                                                                        : null
                                                                                ))}
                                                                            </div>
                                                                            {dataContest.team === '2' ?
                                                                                <div className='mb-5 '>
                                                                                    <span className='cursor-pointer' onClick={addFields2}>
                                                                                        <img src={addlist} alt="" className="me-2" style={{ width: "26px" }} />
                                                                                        เพิ่มรายการ
                                                                                    </span>
                                                                                </div>
                                                                                : null}
                                                                            <div className=''>
                                                                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                                                                    <Form.Label className='font-14 weight-500'>โทรศัพท์มือถือ อาจารย์ที่ปรึกษา/ผู้ฝึกซ้อม <span className='color-red'>*</span></Form.Label>
                                                                                    <InputMask
                                                                                        mask="999-999-9999"
                                                                                        maskChar={null}
                                                                                        required
                                                                                        className='form-control register-custom ps-0 font-14'
                                                                                        type="text"
                                                                                        placeholder="xxx-xxx-xxxx"
                                                                                        name="tel"
                                                                                        pattern="\d{3}[\-]\d{3}[\-]\d{4}"
                                                                                    />
                                                                                </Form.Group>
                                                                            </div>
                                                                            <Form.Control type="hidden" name='contestId' defaultValue={id} className='form-control register-custom ps-0 font-14' />
                                                                            <Form.Control type="hidden" name='memberId' defaultValue={datat.member ? datat.member.mem : null} className='form-control register-custom ps-0 font-14' />
                                                                            {dataContest.digital ? JSON.parse(dataContest.digital).filter(valb => valb === '1').length ?
                                                                                <div>
                                                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                                                        <Form.Label className='font-14 weight-500'>ชื่อผลงาน <span className='color-red'>*</span></Form.Label>
                                                                                        <Form.Control type="text" name='title_work' placeholder='ชื่อผลงาน' className='form-control register-custom ps-0 font-14' required />
                                                                                    </Form.Group>
                                                                                </div>
                                                                                : false : null}

                                                                            {dataContest.digital ? JSON.parse(dataContest.digital).filter(valb => valb === '2').length ?
                                                                                <div>
                                                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                                                        <Form.Label className='font-14 weight-500'>แนวความคิดในการสร้างสรรค์ (Concept) <span className='color-red'>*</span></Form.Label>
                                                                                        <Form.Control type="text" name='concept' placeholder='Concept' className='form-control register-custom ps-0 font-14' required />
                                                                                    </Form.Group>
                                                                                </div>
                                                                                : false : null}

                                                                            {dataContest.attach ? JSON.parse(dataContest.attach).filter(valb => valb === '1').length ?
                                                                                <div className=''>
                                                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                                                        <Form.Label className='font-14 weight-500'>Upload ไฟล์ภาพผลงานประเภท JPG (อัปโหลดได้มากกว่า 1 ไฟล์) <span className='color-red'>*</span></Form.Label>
                                                                                        <input type="file" ref={fileInput} onChange={handleFileInput} className="d-none form-control" required accept="image/jpeg" />
                                                                                        {selectedFile.length ?
                                                                                            <>
                                                                                                {selectedFile.map((val, index) => (
                                                                                                    <div className='border-loop pe-5 position-relative font-12 pb-2 mb-2' key={index}>
                                                                                                        {val.name}
                                                                                                        <span className='position-absolute end-0'>
                                                                                                            <span className='me-2'>{formatBytes(val.size)}</span>
                                                                                                            <span className='cursor-pointer' onClick={() => delPoster(index)}>
                                                                                                                <img src={dellist} alt="" className="" style={{ width: "18px", transform: "rotate(45deg)" }} />
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                ))}
                                                                                                <div className="file-uploader mt-3">
                                                                                                    <span onClick={e => fileInput.current && fileInput.current.click()} className="btn-uploadsfile py-1 px-5 font-12">
                                                                                                        อัปโหลดไฟล์
                                                                                                    </span>
                                                                                                </div>
                                                                                            </>
                                                                                            : selectedFileerror ?
                                                                                                <div className="file-uploader">
                                                                                                    <span onClick={e => fileInput.current && fileInput.current.click()} className="btn-uploadsfile py-1 px-5 font-12">
                                                                                                        อัปโหลดไฟล์
                                                                                                    </span>
                                                                                                    <div className='mt-2 font-14 color-red'>{selectedFileerror}</div>
                                                                                                </div>
                                                                                                :
                                                                                                <div className="file-uploader">

                                                                                                    <span onClick={e => fileInput.current && fileInput.current.click()} className="btn-uploadsfile py-1 px-5 font-12">
                                                                                                        อัปโหลดไฟล์
                                                                                                    </span>
                                                                                                </div>
                                                                                        }
                                                                                    </Form.Group>
                                                                                </div>
                                                                                : false : null}

                                                                            {dataContest.attach ? JSON.parse(dataContest.attach).filter(valb => valb === '2').length ?
                                                                                <div className=''>
                                                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                                                        <Form.Label className='font-14 weight-500'>อัปโหลดไฟล์อื่นๆ (อัปโหลดได้มากกว่า 1 ไฟล์ ขนาดไฟล์ไม่เกิน 100 Mb) <span className='color-red'>*</span></Form.Label>

                                                                                        <input type="file" ref={fileInput2} onChange={handleFileInput2} className="d-none form-control" required />

                                                                                        {selectedFile2.length ?
                                                                                            <>
                                                                                                {selectedFile2.map((val, index) => (
                                                                                                    <div className='border-loop pe-5 position-relative font-12 pb-2 mb-2' key={index}>
                                                                                                        {val.name}
                                                                                                        <span className='position-absolute end-0'>
                                                                                                            <span className='me-2'>{formatBytes(val.size)}</span>
                                                                                                            <span className='cursor-pointer' onClick={() => delPoster2(index)}>
                                                                                                                <img src={dellist} alt="" className="" style={{ width: "18px", transform: "rotate(45deg)" }} />
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                ))}

                                                                                                <div className='mt-2 font-14 color-red'>{selectedFileerror2}</div>
                                                                                                <div className="file-uploader mt-3">
                                                                                                    <span onClick={e => fileInput2.current && fileInput2.current.click()} className="btn-uploadsfile py-1 px-5 font-12">
                                                                                                        อัปโหลดไฟล์
                                                                                                    </span>

                                                                                                </div>
                                                                                            </>
                                                                                            : selectedFileerror2 ?
                                                                                                <>
                                                                                                    <div className='mt-2 font-14 color-red mb-3'>{selectedFileerror2}</div>
                                                                                                    <div className="file-uploader">
                                                                                                        <span onClick={e => fileInput2.current && fileInput2.current.click()} className="btn-uploadsfile py-1 px-5 font-12">
                                                                                                            อัปโหลดไฟล์
                                                                                                        </span>

                                                                                                    </div>
                                                                                                </>
                                                                                                :
                                                                                                <div className="file-uploader">
                                                                                                    <span onClick={e => fileInput2.current && fileInput2.current.click()} className="btn-uploadsfile py-1 px-5 font-12">
                                                                                                        อัปโหลดไฟล์
                                                                                                    </span>
                                                                                                </div>
                                                                                        }
                                                                                    </Form.Group>
                                                                                    <Form.Label className='font-14 weight-400'>* หากพบปัญหาติดต่อที่ facebook: satitacademic8</Form.Label>
                                                                                </div>
                                                                                : false : null}
                                                                        </div>
                                                                        <div className="col-12 col-md-12 col-lg-12">
                                                                            <div className='pt-5 text-end'>
                                                                                {datat.member ?
                                                                                    dataContest.data_app ?
                                                                                        <span className="btn-red cursor-pointer" onClick={() => cancleApp(dataContest.data_app)}>ยกเลิก </span>
                                                                                        :
                                                                                        <>
                                                                                            <Link to={"/contest/detail/" + id} transition='default-fade' className='btn-link-default pe-3'>ยกเลิก</Link>
                                                                                            <button type='submit' className="btn-blue" >ส่ง</button>
                                                                                        </>
                                                                                    :
                                                                                    <>
                                                                                        <Link to={"/contest/detail/" + id} transition='default-fade' className='btn-link-default pe-3'>ยกเลิก</Link>
                                                                                        <span className="btn-blue cursor-pointer" onClick={() => setModalLoginpage({ view: true })} >ส่ง</span>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                    : null}
            </div >

            {modalLoginpage.view ?
                <ModalLoginpage show={modalLoginpage.view} onHide={() => setModalLoginpage({ view: false })} handleModalhide={(e) => handleModalhide()} />
                : null}
        </>
    );
};

export default Detail;