import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import { useParams, useHistory } from 'react-router-dom';
// import { compareAsc, format } from 'date-fns';
import Moment from 'moment';

import InputMask from 'react-input-mask';

import Header from '../../layout/header';
import Footer from '../../layout/footer';

import { Link } from "react-tiger-transition";
import Swal from 'sweetalert2'

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import PostService from "../../components/services_member/post.service";
import GetService from "../../components/services_member/get.service";
import ModalLoginpage from "../../layout/modal/modalLogin";

import loading from '../../assets/img/loading.gif';
import bg from '../../assets/img/bg-presentation.jpg';
import a1 from '../../assets/img/a-1.jpg';
import calendar from '../../assets/img/calendar.svg';
import search from '../../assets/img/searchM.svg';

const Presentation = (datat) => {

    const { id } = useParams();

    let history = useHistory();

    const [dataPresentation, setDataPresentation] = useState([]);

    const fetchData = useCallback(async (id, member_id) => {

        GetService.getAllPresentation(id, member_id).then(

            (response) => {

                console.log(response)
                if (response.status === 200) {
                    setDataPresentation(response.data)
                }
            }, (error) => {
                // window.location.reload();
            }
        )
    });

    useEffect(() => {
        fetchData(id, datat.member ? datat.member.mem : null)
    }, []);

    const [validated, setValidated] = useState(false);
    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})

    const [modalLoginpage, setModalLoginpage] = useState({ view: false });

    const handleModalhide = () => {
        setModalLoginpage({ view: false })
        setTimeout(window.location.reload(), 3000);
    }

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {

            PostService.regisPresentation(data).then(
                (res) => {
                    console.log(res.status)
                    if (res.status === 200) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: '',
                            text: res.messages,
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            history.push("/");
                            // window.location.reload()
                            // onHideadd();

                            // localStorage.removeItem("step_1");
                            // localStorage.removeItem("step_2");
                            // localStorage.removeItem("step_3");
                        })
                        // datat.onHide();
                        // setLoadingView(false);
                    } else {

                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: '',
                            text: res.messages,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }

        setValidated(true);
    };

    const cancleApp = (id) => {        

        const dataf = new FormData();
        dataf.append("id", id);
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
          }).then((result) => {
            if (result.isConfirmed) {
                PostService.delPresentation(dataf).then(
                    (res) => {
                        console.log(res.status)
                        if (res.status === 200) {
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: '',
                                text: res.messages,
                                showConfirmButton: false,
                                timer: 1500
                            }).then(function () {
                                // history.push("/");
                                window.location.reload();
                            })
                        } else {
    
                            Swal.fire({
                                position: 'center',
                                icon: 'error',
                                title: '',
                                text: res.messages,
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }
          })

        // alert(id_mem)
    }

    // console.log(datat.member.mem)

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="home" />
                {dataPresentation ?
                    <div className="wrapper bg-white box-index">
                        <section className="mt-3" id="page-wrap">
                            <div className="container-fluid p-0 bg-start" style={{ backgroundImage: `url(${bg})` }}>
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12 position-relative">
                                        <div className="w-100 position-relative">
                                            <div className='w-100'>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-12 col-md-12 col-lg-12 text-start">
                                                            <div className='font-14'>
                                                                <Link to="/" transition='default-fade' className="a-default">หน้าแรก</Link>
                                                                <span className='mx-2'>|</span>
                                                                <Link to="/presentation/presentation" transition='default-fade' className="a-default">การบรรยาย/นำเสนอ</Link>
                                                                <span className='mx-2'>|</span>
                                                                <span className='text-decoration-underline'>
                                                                    {dataPresentation.topic}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-100 mt-5'>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-12 col-md-12 col-lg-12 text-start">
                                                            <div className='topic-news weight-600 mb-3'>การบรรยาย/นำเสนอ</div>
                                                            {datat.member ? null :
                                                                <div className='box-login-alert p-4 position-relative' style={{ opacity: datat.member ? 0 : 1 }}>
                                                                    <div className='color-white font-18 weight-500 pt-3 ps-3'>กรุณาเข้าสู่ระบบ</div>
                                                                    <div className='color-white font-14 weight-300 pb-3 ps-3'>
                                                                        - กรุณาเข้าสู่ระบบเพื่อทำรายการที่ท่านต้องการ หากท่านไม่ทำการเข้าสู่ระบบท่านจะไม่สามารถทำรายการได้ <br />
                                                                        - กรณีที่ยังไม่มีข้อมูลสำหรับเข้าสู่ระบบ ให้ทำการลงทะเบียน
                                                                    </div>
                                                                    <div className='color-white box-absolute-right-center pe-5'>
                                                                        <button className='btn-seemore' onClick={() => setModalLoginpage({ view: true })}>เข้าสู่ระบบ</button>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="container mb-5">
                                                    <div className="row justify-content-center">
                                                        <div className="col-12 col-md-12 col-lg-12 text-start">
                                                            <div className='shadow-sm pt-3 pb-5'>
                                                                <div className='row'>
                                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                                        <div className='position-relative gar-black'>
                                                                            {/* <img className="w-100" src={a1} /> */}
                                                                            <img src={process.env.REACT_APP_API_URL + "/uploads/presentation/" + dataPresentation.id + '/' + dataPresentation.img} alt="" className="w-100" />
                                                                            <div className='mb-3'>
                                                                                <div className='p-4' style={{ minHeight: "150px", backgroundColor: "#F3F3F3" }}>
                                                                                    <div className='weight-500 ps-5 pt-2 position-relative'>
                                                                                        <div className='position-absolute start-0 top-0'>
                                                                                            <div className='day-sum font-bb'>{Moment(dataPresentation.date_active).format('DD')}</div>
                                                                                            <div className='month-sum font-bb'>{Moment(dataPresentation.date_active).format('MMM')}</div>
                                                                                        </div>
                                                                                        <div className='ps-3'>
                                                                                            <h4>{dataPresentation.topic}</h4>
                                                                                            <div className='color-gray weight-400 font-12'>
                                                                                                {Moment(dataPresentation.date_active).format('HH:mm')}  |  {dataPresentation.lecturer_name}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='p-4'>
                                                                                <div className='font-22 weight-500 mb-4'>รายละเอียด</div>
                                                                                <div className='row'>
                                                                                    <div className="col-12 col-md-6 col-lg-6 text-start">
                                                                                        <div className='mb-4'>
                                                                                            <div className='font-18 weight-500 mb-1'>หัวข้อ</div>
                                                                                            <div className='weight-300'>{dataPresentation.topic}</div>
                                                                                        </div>
                                                                                        <div className='mb-4'>
                                                                                            <div className='font-18 weight-500 mb-1'>ตำแหน่ง</div>
                                                                                            <div className='weight-300'>{dataPresentation.position}</div>
                                                                                        </div>
                                                                                        <div className='mb-4'>
                                                                                            <div className='font-18 weight-500 mb-1'>วัน-เวลา</div>
                                                                                            <div className='weight-300'>
                                                                                                {Moment(dataPresentation.date_active).format('DD/MM/YYYY')} | {Moment(dataPresentation.date_active).format('HH:mm')}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-md-6 col-lg-6 text-start">
                                                                                        <div className='mb-4'>
                                                                                            <div className='font-18 weight-500 mb-1'>ชื่อวิทยากร</div>
                                                                                            <div className='weight-300'>{dataPresentation.lecturer_name}</div>
                                                                                        </div>
                                                                                        <div className='mb-4'>
                                                                                            <div className='font-18 weight-500 mb-1'>สังกัด</div>
                                                                                            <div className='weight-300'>{dataPresentation.under}</div>
                                                                                        </div>
                                                                                        <div className='mb-4'>
                                                                                            <div className='font-18 weight-500 mb-1'>Link Zoom</div>
                                                                                            <div className='weight-300'>
                                                                                                กรุณาสมัครสมาชิกเพื่อรับ Link
                                                                                                {/* {dataPresentation.link_zoom} */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                                                        <div className='mb-4'>
                                                                                            <div className='font-18 weight-500 mb-1'>รายละเอียดเพิ่มเติม</div>
                                                                                            <div className='weight-300'>
                                                                                                <div dangerouslySetInnerHTML={{ __html: dataPresentation.detail }} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <Form noValidate validated={validated} onSubmit={handleSubmit}>

                                                                                            <div className='mt-5'>

                                                                                                <table className="table table-striped table-blue font-14" id="TextBoxContainerb">
                                                                                                    <thead>
                                                                                                        <tr style={{ background: "#2E2E80", color: "#fff" }}>
                                                                                                            <th colSpan={2} className="pt-3">การอบรมสัมนาเชิงปฎิบัติการ</th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody id="tbodyid">

                                                                                                        {dataPresentation.workshop ? JSON.parse(dataPresentation.workshop).map((val, index) => (
                                                                                                            <tr key={index}>
                                                                                                                <td>
                                                                                                                    {val.item}
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <Form.Label className='font-14 weight-500'>เวลา</Form.Label>
                                                                                                                    <div className="ex-radio4">
                                                                                                                        <input type="radio" id="radio_one" name="workshop" value={val.item + "/" + val.time} required />
                                                                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                                                                            {val.time}
                                                                                                                        </label>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        )) : null}
                                                                                                    </tbody>
                                                                                                </table>
                                                                                                <div>
                                                                                                    <input type="radio" id="radio_one" name="workshop" className='position-absolute' value="" style={{ height: 0, opacity: "0" }} required />
                                                                                                    <div className="invalid-feedback m-0 font-14">Please choose a checkbox.</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <Form.Control type="hidden" name='presentation_id' defaultValue={id} className='form-control register-custom ps-0 font-14' />
                                                                                            <Form.Control type="hidden" name='member_id' defaultValue={datat.member ? datat.member.mem : null} className='form-control register-custom ps-0 font-14' />
                                                                                            <div className='pt-5 text-end'>

                                                                                                {/* {datat.member ?
                                                                                <button type='submit' className="btn-blue" >เข้าร่วม</button>
                                                                                :
                                                                                <span className="btn-blue" onClick={setModalLogin({ view: true })} >เข้าร่วม</span>
                                                                            } */}

                                                                                                {datat.member ?
                                                                                                    dataPresentation.data_app ?
                                                                                                    <span  className="btn-red cursor-pointer" onClick={() => cancleApp(dataPresentation.data_app)}>ยกเลิก </span>
                                                                                                        :
                                                                                                        <>
                                                                                                            <Link to="/presentation/presentation" transition='default-fade' className='btn-link-default pe-3'>ยกเลิก</Link>
                                                                                                            <button type='submit' className="btn-blue" >เข้าร่วม </button>
                                                                                                        </>

                                                                                                    :
                                                                                                    <>
                                                                                                        <Link to="/presentation/presentation" transition='default-fade' className='btn-link-default pe-3'>ยกเลิก</Link>
                                                                                                        <span className="btn-blue cursor-pointer" onClick={() => setModalLoginpage({ view: true })} >เข้าร่วม</span>
                                                                                                    </>
                                                                                                }
                                                                                            </div>

                                                                                        </Form>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                    : null}
            </div >

            {modalLoginpage.view ?
                <ModalLoginpage show={modalLoginpage.view} onHide={() => setModalLoginpage({ view: false })} handleModalhide={(e) => handleModalhide()} />
                : null}
        </>
    );
};

export default Presentation;