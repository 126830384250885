import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import { useParams } from 'react-router-dom';
// import { compareAsc, format } from 'date-fns';
import Moment from 'moment';
import InputMask from 'react-input-mask';

import Header from '../../layout/header';
import Footer from '../../layout/footer';

import { Link } from "react-tiger-transition";
import Swal from 'sweetalert2'

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";
import GetService from "../../components/services_member/get.service";

import loading from '../../assets/img/loading.gif';
import bg from '../../assets/img/bg-contest.jpg';
import a1 from '../../assets/img/a-1.jpg';
import calendar from '../../assets/img/calendar.svg';
import search from '../../assets/img/searchM.svg';

import ModalLogin from "../../layout/modal/modalLogin";
import seemore from '../../assets/img/seemore.svg';

const News = (datat) => {

    // const memberdata = localStorage.getItem("member_data");

    // if (!memberdata) {
    //     // window.location.href = "/";
    //     // console.log('no login')
    // } else {
    //     // console.log('login')
    // }

    const [dateday, setDateday] = useState(Moment(new Date()).format('DD/MM/yyyy'));

    const dateoption = {
        locale: {
            format: 'DD/MM/YYYY'
        },
        startDate: dateday,
        // maxDate: dateday,
        singleDatePicker: true,
        autoApply: true,
    };

    const [dataMap, setDataMap] = useState([]);
    const [dataMapSearch, setDataMapSearch] = useState([]);


    const fetchData = useCallback(async (id) => {
        // console.log(user)
        // if (user) {

        GetService.getAllNews(id).then(
            (response) => {
                // console.log(response)
                if (response.status === 200) {
                    setDataMap(response.data)
                    setDataMapSearch(response.data)
                    // localStorage.setItem("member_data", JSON.stringify(response.data_member));
                    // setDatamember(response.data_member);
                }
            }, (error) => {
                // window.location.reload();
            }
        )
        // }
    });

    useEffect(() => {
        fetchData(0)
    }, []);

    const handledateday = (event) => {

    }

    const [modalLogin, setModalLogin] = useState({ view: false });

    const handleModalhide = () => {
        setModalLogin({ view: false })
        setTimeout(window.location.reload(), 3000);
    }

    const [dataTag, setDatatag] = useState();

    const hadleTag = (keyword) => {

        setDatatag(keyword)

        if (keyword !== '') {
            const results = dataMap.filter(valb => valb.tags.match(new RegExp(keyword, "i")));
            setDataMapSearch(results)
        } else {
            setDataMapSearch(dataMap)
        }
    }

    const handleKeyUp = (e) => {
        console.log(e.target.value);
        // console.log(event.code);
        let keyword = e.target.value;
        if (keyword !== '') {
            const results = dataMap.filter(valb => valb.topic.match(new RegExp(keyword, "i")));
           
            setDataMapSearch(results)
    
            
        } else {
            setDataMapSearch(dataMap)
        }
      };

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="news" />
                <div className="wrapper bg-white box-index">
                    <section className="mt-3" id="page-wrap">
                        <div className="container-fluid p-0 bg-start">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">
                                    <div className="w-100 position-relative">
                                        <div className='w-100'>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className='font-14'>
                                                            <Link to="/" transition='default-fade' className="a-default">หน้าแรก</Link>
                                                            <span className='mx-2'>|</span>
                                                            <span className='text-decoration-underline'>ข่าวสาร</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 mt-4'>
                                            <div className="container mb-5 bg-white">
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-4 col-lg-4 text-start">
                                                        <div className='pt-5'>
                                                            <div className='mb-5'>
                                                                <div className='box-tag color-white px-3 py-2' style={{background:"#2E2E80"}}>Tag</div>
                                                                <div className='py-2'>
                                                                    <span onClick={(e) => hadleTag('การศึกษา')} className='px-4 py-1 font-14 me-2 mt-2 d-inline-block cursor-pointer'  style={dataTag === "การศึกษา" ? {background:"#B4B4B4"} : {background:"#F3F3F3"}}>การศึกษา</span>
                                                                    <span onClick={(e) => hadleTag('บรรยาย/อบรม')} className='px-4 py-1 font-14 me-2 mt-2 d-inline-block cursor-pointer' style={dataTag === "บรรยาย/อบรม" ? {background:"#B4B4B4"} : {background:"#F3F3F3"}}>บรรยาย/อบรม</span>
                                                                    <span onClick={(e) => hadleTag('นิทรรศการ')} className='px-4 py-1 font-14 me-2 mt-2 d-inline-block cursor-pointer' style={dataTag === "นิทรรศการ" ? {background:"#B4B4B4"} : {background:"#F3F3F3"}}>นิทรรศการ</span>
                                                                    <span onClick={(e) => hadleTag('คณะกรรมการตัดสินงานประกวด/แข่งขัน')} className='px-4 py-1 font-14 me-2 mt-2 d-inline-block cursor-pointer' style={dataTag === "คณะกรรมการตัดสินงานประกวด/แข่งขัน" ? {background:"#B4B4B4"} : {background:"#F3F3F3"}}>คณะกรรมการตัดสินงานประกวด/แข่งขัน</span>
                                                                    <span onClick={(e) => hadleTag('กิจกรรมการประกวด/แข่งขัน')} className='px-4 py-1 font-14 me-2 mt-2 d-inline-block cursor-pointer' style={dataTag === "กิจกรรมการประกวด/แข่งขัน" ? {background:"#B4B4B4"} : {background:"#F3F3F3"}}>กิจกรรมการประกวด/แข่งขัน</span>
                                                                </div>
                                                            </div>
                                                            <div className='font-22 weight-600 mb-3'>ข่าวสารล่าสุด</div>
                                                            <div className='mt-5 mt-md-0'>
                                                                { dataMap.length ? dataMap.sort((a, b) => b.id - a.id).slice(0,3).map((val, index) => (
                                                                    <div className='border-loop mb-4' key={index}>
                                                                        <div className='weight-500 font-16'>
                                                                            {val.topic}
                                                                        </div>
                                                                        <div className='py-3'>
                                                                            <Link to={"/news/detail/" + val.id} className="color-light-blue see-more">
                                                                                อ่านเพิ่มเติม <img src={seemore} className="img-seemore" />
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                )): <div className='py-5 text-center'>- No data -</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-8 col-lg-8 text-start ps-md-5">
                                                        <div className='py-5'>
                                                            <div className='row'>
                                                                <div className="col-12 col-md-12 col-lg-12 text-start pb-3">
                                                                    <div className='topic-news weight-600 mb-3'>ข่าวสาร</div>
                                                                    <div className='row'>
                                                                        <div className="col-12 col-md-2 col-lg-2 text-start">
                                                                            <div className='text-start py-3 weight-500 d-inline-block me-3'>ค้นหาข่าวสาร</div>
                                                                        </div>
                                                                        <div className="col-7 col-md-10 col-lg-10 text-start">
                                                                            <div className='position-relative'>
                                                                                <Form.Group controlId="">
                                                                                    <input type="text" onKeyUp={handleKeyUp} className="form-control py-2 pe-5 text-end input-custom-2" name="key" placeholder='Search' />
                                                                                    <div className='position-absolute' style={{ top: "10px", right: "12px" }}>
                                                                                        <img src={search} alt="" className="" style={{ width: "22px" }} />
                                                                                    </div>
                                                                                </Form.Group>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                {dataMapSearch.length ? dataMapSearch.sort((a, b) => b.id - a.id).map((val, index) => (
                                                                    <div className='col-12 col-md-6 col-lg-6 p-3' key={index}>
                                                                        <div>
                                                                            <img src={process.env.REACT_APP_API_URL + "/uploads/news/" + val.id + '/' + val.img} className="w-100" />
                                                                        </div>
                                                                        <div className='weight-500 font-18 py-3'>
                                                                            {val.topic}
                                                                        </div>
                                                                        <div className='weight-300'>
                                                                            {val.sub_topic}
                                                                        </div>
                                                                        <div className='py-3'>
                                                                            <Link to={"/news/detail/" + val.id} className="color-light-blue see-more">
                                                                                อ่านเพิ่มเติม <img src={seemore} className="img-seemore" />
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                                    : <div className='py-5 text-center'>- No data -</div>}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </div>
            {modalLogin.view ?
                <ModalLogin show={modalLogin.view} onHide={() => setModalLogin({ view: false })} handleModalhide={(e) => handleModalhide()} />
                : null}
        </>
    );
};

export default News;