// export default function authHeader() {

    const authHeaderMember = () => {

      const user = JSON.parse(localStorage.getItem("member"));

      if (user && user.accessToken) {
        return { Authorization: 'Bearer ' + user.accessToken };
        // return { "x-auth-token": user.accessToken };
      } else {
        return {};
      }
    };

    const authHeaderBusiness = () => {

      const bu = JSON.parse(localStorage.getItem("business"));

      if (bu && bu.accessToken) {
        return { Authorization: 'Bearer ' + bu.accessToken };
        // return { "x-auth-token": user.accessToken };
      } else {
        return {};
      }
    };

    const authHeaderBranch = () => {

      const bu = JSON.parse(localStorage.getItem("member"));

      if (bu && bu.accessToken) {
        return { Authorization: 'Bearer ' + bu.accessToken };
        // return { "x-auth-token": user.accessToken };
      } else {
        return {};
      }
    };

    
    const authHeader = {
      authHeaderMember,
      authHeaderBusiness,
      authHeaderBranch
    };

    export default authHeader;
// }