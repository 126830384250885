import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Link } from "react-tiger-transition";
import Swal from 'sweetalert2'

import folder from '../../assets/img/folder.svg';
import teacher from '../../assets/img/icon-teacher.svg';
import student from '../../assets/img/icon-student.svg';
import guest from '../../assets/img/icon-guest.svg';
import loading from '../../assets/img/loading.gif';

import PostService from "../../components/services_member/post.service";

const ModalFrom = ({ handleModalhide, dataTrainee, dataCompete, ...datat }) => {

    const [validated, setValidated] = useState(false);
    const [validatedreset, setValidatedreset] = useState(false);
    const [boxRegister, setBoxregister] = useState({ id: 0 });
    const [clssLoading, setClssLoading] = useState(false);
    const [errors, setErrors] = useState(false)
    const [errorsreset, setErrorsreset] = useState(false)

    const [reqOther2, setOther2] = useState(false);

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        // console.log(data.get('name'));
        // const { email,password,password2 } = event.target.elements;   
        // var errors = [];

        // console.log(errors.push("tel"));

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {
            setClssLoading(true);
            // Swal.fire({
            //     position: 'center',
            //     html: `<div class="p-5"><img src='${loading}' class="icon-loading"/></div>`,
            //     title: '',
            //     text: "จองโต๊ะเรียบร้อยแล้ว",
            //     showConfirmButton: false,
            // });

            data.set('id_member', datat.id)

            PostService.regisForm(data).then(
                (res) => {
                    if (res.status === 200) {

                        PostService.getAllPrivatePosts().then(

                            (response) => {
                                if (response.status === 200) {
                                    localStorage.setItem("member_data", JSON.stringify(response.data_member));
                                    handleModalhide();
                                    // window.NavigationPreloadManager()
                                    setClssLoading(false);
                                }
                            }, (error) => {
                                // window.location.reload();
                            }
                        )

                    } else {
                        setErrors(true)
                        setClssLoading(false);
                    }
                },
                (error) => {
                    setClssLoading(false);
                    setErrors(true)
                }
            );
        }

        setValidated(true);
    };

    const handleChange2 = (e) => {

        if (e.target.value === "อื่นๆ") {
            setOther2(true)
        } else {
            setOther2(false)
        }
    }

    console.log(dataCompete)

    return (
        <>
            <Modal {...datat} className="modal-custom" size="lg" backdrop="static" centered keyboard={false}>
                <Modal.Header closeButton className=''>
                    <Modal.Title></Modal.Title>
                </Modal.Header>

                {datat.type.type === "form" ?
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        {clssLoading ?
                            <Modal.Body className="bg-white-modal">
                                <div className="d-block w-100 scroll-custom">
                                    <div className='h-100per-modal py-5 px-4'>
                                        <div className='text-center my-5 py-5'>
                                            <span><i className="fa fa-spinner fa-spin" /></span>
                                            <div>Loading...</div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            :
                            <>
                                <Modal.Body className="bg-white-modal">
                                    <div className="d-block w-100 scroll-custom">
                                        <div className='h-100per-modal py-3 px-4'>

                                            <>
                                                <div className='text-center'>
                                                    <h5>แบบสอบถามความพึงพอใจการเข้าร่วมโครงการสาธิตวิชาการ ครั้งที่ 8</h5>
                                                    <div className='font-14'>“จินตนาการประสานภูมิปัญญา พัฒนานวัตกรรมสร้างสรรค์ ก้าวทันโลกดิจิทัล”</div>
                                                    <div className='font-14'>ระหว่างวันที่ 8 – 9  ธันวาคม 2565</div>
                                                    <div className='font-14'>ณ โรงเรียนสาธิต มหาวิทยาลัยศิลปากร (ปฐมวัยและประถมศึกษา) โรงเรียนสาธิต มหาวิทยาลัยศิลปากร(มัธยมศึกษา)
                                                        และคณะศึกษาศาสตร์ มหาวิทยาลัยศิลปากร วิทยาเขตพระราชวังสนามจันทร์ จังหวัดนครปฐม</div>
                                                </div>
                                                <div className='w-25 m-auto'>
                                                    <hr />
                                                </div>
                                                <div className='mt-4'>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <div className='mb-3'>
                                                            <Form.Label className='font-14 weight-500'>เพศ <span className='color-red'>*</span></Form.Label>
                                                            <div>
                                                                <input type="radio" id="radio_one" name="sex" className='position-absolute' value="" style={{ height: 0, opacity: "0" }} required />
                                                                <div className="invalid-feedback m-0 font-12">Please choose a checkbox.</div>
                                                            </div>
                                                            <div className="ex-radio4 d-inline-block me-5">
                                                                <input type="radio" id="radio_one" name="sex" value="หญิง" />
                                                                <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                    หญิง
                                                                </label>
                                                            </div>
                                                            <div className="ex-radio4 d-inline-block">
                                                                <input type="radio" id="radio_one" name="sex" value="ชาย" />
                                                                <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                    ชาย
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <div className='mb-1'>
                                                            <Form.Label className='font-14 weight-500'>อาชีพ <span className='color-red'>*</span></Form.Label>
                                                            <div>
                                                                <input type="radio" id="radio_one" name="occupation" className='position-absolute' value="" style={{ height: 0, opacity: "0" }} required />
                                                                <div className="invalid-feedback m-0 font-12">Please choose a checkbox.</div>
                                                            </div>
                                                            <div className='px-2 mx-1'>
                                                                <div className='row'>
                                                                    <div className="ex-radio4 col-12 col-md-3">
                                                                        <input type="radio" id="radio_one" name="occupation" value="ข้าราชการ" onChange={handleChange2} />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            ข้าราชการ
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-12 col-md-3">
                                                                        <input type="radio" id="radio_one" name="occupation" value="พนักงานมหาวิทยาลัย" onChange={handleChange2} />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            พนักงานมหาวิทยาลัย
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-12 col-md-3">
                                                                        <input type="radio" id="radio_one" name="occupation" value="รัฐวิสาหกิจ" onChange={handleChange2} />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            รัฐวิสาหกิจ
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-12 col-md-3">
                                                                        <input type="radio" id="radio_one" name="occupation" value="พนักงานเอกชน" onChange={handleChange2} />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            พนักงานเอกชน
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="ex-radio4 col-12 col-md-3">
                                                                        <input type="radio" id="radio_one" name="occupation" value="นักเรียน/นักศึกษา" onChange={handleChange2} />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            นักเรียน/นักศึกษา
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-12 col-md-3">
                                                                        <input type="radio" id="radio_one" name="occupation" value="ค้าขาย/ธุรกิจส่วนตัว" onChange={handleChange2} />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            ค้าขาย/ธุรกิจส่วนตัว
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-12 col-md-6">
                                                                        <input type="radio" id="radio_one" name="occupation" value="อื่นๆ" onChange={handleChange2} />
                                                                        <label className="radio_one text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            อื่นๆ
                                                                        </label>
                                                                        <Form.Group className="mb-3" controlId="formBasicPassword" style={{ paddingLeft: "15px" }}>
                                                                            <Form.Control type="text" name='occupation_more' required={reqOther2} className='form-control register-custom ps-0 font-14' placeholder='ระบุ' />
                                                                        </Form.Group>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <div className='mb-1'>
                                                            <Form.Label className='font-14 weight-500'>ระดับการศึกษา <span className='color-red'>*</span></Form.Label>
                                                            <div>
                                                                <input type="radio" id="radio_one" name="level" className='position-absolute' value="" style={{ height: 0, opacity: "0" }} required />
                                                                <div className="invalid-feedback m-0 font-12">Please choose a checkbox.</div>
                                                            </div>
                                                            <div className='px-2 mx-1'>
                                                                <div className='row'>
                                                                    <div className="ex-radio4 col-12 col-md-3">
                                                                        <input type="radio" id="radio_one" name="level" value="ระดับประถมศึกษา" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            ระดับประถมศึกษา
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-12 col-md-3">
                                                                        <input type="radio" id="radio_one" name="level" value="ระดับมัธยมศึกษา" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            ระดับมัธยมศึกษา
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="ex-radio4 col-12 col-md-3">
                                                                        <input type="radio" id="radio_one" name="level" value="ระดับปริญญาตรี" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            ระดับปริญญาตรี
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-12 col-md-3">
                                                                        <input type="radio" id="radio_one" name="level" value="ระดับปริญญาโท" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            ระดับปริญญาโท
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-12 col-md-3">
                                                                        <input type="radio" id="radio_one" name="level" value="ระดับปริญญาเอก" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            ระดับปริญญาเอก
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <div className='mb-1'>
                                                            <Form.Label className='font-14 weight-500'>1.รูปแบบการจัดนิทรรศการ / เนื้อหาสาระ / เรื่องที่นำมาจัดนิทรรศการ
                                                                มีความน่าสนใจ <span className='color-red'>*</span></Form.Label>
                                                            <div>
                                                                <input type="radio" id="radio_one" name="q1" className='position-absolute' value="" style={{ height: 0, opacity: "0" }} required />
                                                                <div className="invalid-feedback m-0 font-12">Please choose a checkbox.</div>
                                                            </div>
                                                            <div className='px-2 mx-1'>
                                                                <div className='row'>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q1" value="มากที่สุด" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            มากที่สุด
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q1" value="มาก" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            มาก
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q1" value="ปานกลาง" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            ปานกลาง
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q1" value="น้อย" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            น้อย
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q1" value="น้อยที่สุด" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            น้อยที่สุด
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <div className='mb-1'>
                                                            <Form.Label className='font-14 weight-500'>2.วิทยากรสามารถให้คำแนะนำเกี่ยวกับนิทรรศการและอำนวยความสะดวก<span className='color-red'>*</span></Form.Label>
                                                            <div>
                                                                <input type="radio" id="radio_one" name="q2" className='position-absolute' value="" style={{ height: 0, opacity: "0" }} required />
                                                                <div className="invalid-feedback m-0 font-12">Please choose a checkbox.</div>
                                                            </div>
                                                            <div className='px-2 mx-1'>
                                                                <div className='row'>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q2" value="มากที่สุด" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            มากที่สุด
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q2" value="มาก" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            มาก
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q2" value="ปานกลาง" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            ปานกลาง
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q2" value="น้อย" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            น้อย
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q2" value="น้อยที่สุด" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            น้อยที่สุด
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <div className='mb-1'>
                                                            <Form.Label className='font-14 weight-500'>3.ความรู้ที่ได้รับจากการเข้าชมนิทรรศการ<span className='color-red'>*</span></Form.Label>
                                                            <div>
                                                                <input type="radio" id="radio_one" name="q3" className='position-absolute' value="" style={{ height: 0, opacity: "0" }} required />
                                                                <div className="invalid-feedback m-0 font-12">Please choose a checkbox.</div>
                                                            </div>
                                                            <div className='px-2 mx-1'>
                                                                <div className='row'>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q3" value="มากที่สุด" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            มากที่สุด
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q3" value="มาก" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            มาก
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q3" value="ปานกลาง" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            ปานกลาง
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q3" value="น้อย" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            น้อย
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q3" value="น้อยที่สุด" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            น้อยที่สุด
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <div className='mb-1'>
                                                            <Form.Label className='font-14 weight-500'>4.บรรยากาศภายในงานมีความเหมาะสม <span className='color-red'>*</span></Form.Label>
                                                            <div>
                                                                <input type="radio" id="radio_one" name="q4" className='position-absolute' value="" style={{ height: 0, opacity: "0" }} required />
                                                                <div className="invalid-feedback m-0 font-12">Please choose a checkbox.</div>
                                                            </div>
                                                            <div className='px-2 mx-1'>
                                                                <div className='row'>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q4" value="มากที่สุด" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            มากที่สุด
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q4" value="มาก" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            มาก
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q4" value="ปานกลาง" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            ปานกลาง
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q4" value="น้อย" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            น้อย
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q4" value="น้อยที่สุด" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            น้อยที่สุด
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <div className='mb-1'>
                                                            <Form.Label className='font-14 weight-500'>5.ระยะเวลาในการจัดกิจกรรมมีความเหมาะสม <span className='color-red'>*</span></Form.Label>
                                                            <div>
                                                                <input type="radio" id="radio_one" name="q5" className='position-absolute' value="" style={{ height: 0, opacity: "0" }} required />
                                                                <div className="invalid-feedback m-0 font-12">Please choose a checkbox.</div>
                                                            </div>
                                                            <div className='px-2 mx-1'>
                                                                <div className='row'>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q5" value="มากที่สุด" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            มากที่สุด
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q5" value="มาก" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            มาก
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q5" value="ปานกลาง" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            ปานกลาง
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q5" value="น้อย" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            น้อย
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q5" value="น้อยที่สุด" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            น้อยที่สุด
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <div className='mb-1'>
                                                            <Form.Label className='font-14 weight-500'>6.ท่านสามารถนำสิ่งที่ได้รับนำไปถ่ายทอดและนำไปประยุกต์ใช้ได้ <span className='color-red'>*</span></Form.Label>
                                                            <div>
                                                                <input type="radio" id="radio_one" name="q6" className='position-absolute' value="" style={{ height: 0, opacity: "0" }} required />
                                                                <div className="invalid-feedback m-0 font-12">Please choose a checkbox.</div>
                                                            </div>
                                                            <div className='px-2 mx-1'>
                                                                <div className='row'>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q6" value="มากที่สุด" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            มากที่สุด
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q6" value="มาก" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            มาก
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q6" value="ปานกลาง" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            ปานกลาง
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q6" value="น้อย" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            น้อย
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q6" value="น้อยที่สุด" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            น้อยที่สุด
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <div className='mb-1'>
                                                            <Form.Label className='font-14 weight-500'>7.ความพึงพอใจเกี่ยวกับการดำเนินงานโดยภาพรวม <span className='color-red'>*</span></Form.Label>
                                                            <div>
                                                                <input type="radio" id="radio_one" name="q7" className='position-absolute' value="" style={{ height: 0, opacity: "0" }} required />
                                                                <div className="invalid-feedback m-0 font-12">Please choose a checkbox.</div>
                                                            </div>
                                                            <div className='px-2 mx-1'>
                                                                <div className='row'>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q7" value="มากที่สุด" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            มากที่สุด
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q7" value="มาก" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            มาก
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q7" value="ปานกลาง" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            ปานกลาง
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q7" value="น้อย" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            น้อย
                                                                        </label>
                                                                    </div>
                                                                    <div className="ex-radio4 col-6 col-md-2">
                                                                        <input type="radio" id="radio_one" name="q7" value="น้อยที่สุด" />
                                                                        <label className="radio_one mb-2 text-gray font-14" style={{ width: "100%", paddingLeft: "15px" }} >
                                                                            น้อยที่สุด
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <Form.Label className='font-14 weight-500'>ข้อเสนอแนะอื่นๆ </Form.Label>
                                                        <Form.Control as="textarea" name='suggestion' className='font-14 weight-400' rows={3} />
                                                    </Form.Group>
                                                </div>
                                            </>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer className='bg-white-modal d-block'>
                                    <div className='text-white text-start py-4 px-4'>
                                        <div className='text-end'>
                                            <span onClick={() => datat.onHide()} className="btn-login px-5 py-1 d-inline-block text-center cursor-pointer me-3">ยกเลิก</span>
                                            <button type='submit' className="btn-blue py-1 d-inline-block text-center cursor-pointer border-none">ยืนยัน</button>
                                        </div>
                                    </div>
                                </Modal.Footer>
                            </>
                        }
                    </Form>
                    :
                    <Modal.Body className="bg-white-modal">
                        <div className="d-block w-100 scroll-custom">
                            <div className='h-100per-modal py-3 px-4'>
                                <>
                                    <h5 className=''>ใบประกาศนียบัตร</h5>
                                    <div className='mb-4'>{datat.text}</div>
                                    {dataCompete ?
                                        <div>
                                            {datat.type.cat === 'present' ? <>
                                                <div className='weight-500 mb-2'>
                                                    ผู้เข้าร่วม
                                                </div>
                                                {dataCompete.length ? dataCompete.map((val, index) => (
                                                    <div className='mb-1' key={index}>
                                                        {val} <span className='float-end'>
                                                            <a href={process.env.REACT_APP_API_URL + '/uploads/presentation/' + datat.type.pid + '/S' + datat.type.id + ".jpg"}
                                                                className='' target="_blank">ดาวน์โหลด</a>
                                                        </span>
                                                    </div>
                                                )) : null}
                                            </>
                                                : <>
                                                    <div className='weight-500 mb-2'>ผู้เข้าประกวด</div>
                                                    {dataCompete.length ? dataCompete.map((val, index) => (
                                                        <div className='mb-1' key={index}>
                                                            {index + 1 + '.' + val} <span className='float-end'>
                                                                <a href={process.env.REACT_APP_API_URL + '/uploads/app_contest/' + datat.type.id + '/S' + index + ".jpg"}
                                                                    className='' target="_blank">ดาวน์โหลด</a>
                                                            </span>
                                                        </div>
                                                    )) : null}
                                                </>
                                            }


                                        </div>
                                        : null}
                                    <div className='w-25 m-auto'>
                                        <hr />
                                    </div>
                                    {dataTrainee ?
                                        <div>
                                            <div className='weight-500 mb-2'>อาจารย์ที่ปรึกษา/ผู้ฝึกซ้อม</div>
                                            {dataTrainee.length ? dataTrainee.map((val, index) => (
                                                <div className='mb-1' key={index}>
                                                    {index + 1 + '.' + val} <span className='float-end'>
                                                    <a href={process.env.REACT_APP_API_URL + '/uploads/app_contest/' + datat.type.id + '/T' + index + ".jpg"}
                                                                    className='' target="_blank">ดาวน์โหลด</a>
                                                    </span>
                                                </div>
                                            )) : null}
                                        </div>
                                        : null}
                                </>
                            </div>
                        </div>
                    </Modal.Body>
                }
            </Modal>
        </>
    );
};

export default ModalFrom;