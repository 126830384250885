import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import { useParams } from 'react-router-dom';
// import { compareAsc, format } from 'date-fns';
import Header from '../../layout/header';
import Footer from '../../layout/footer';

import { Link } from "react-tiger-transition";
import GetService from "../../components/services_member/get.service";

import bg from '../../assets/img/bg-reason.jpg';
import bgfooter from '../../assets/img/bg-footer-host.jpg';


const Reason = (datat) => {

    const [dataMap, setDataMap] = useState([]);

    const fetchData = useCallback(async (id) => {
        // console.log(user)
        // if (user) {

        GetService.getAllHome(id).then(
            (response) => {
                // console.log(response)
                if (response.status === 200) {
                    setDataMap(response.data[0])
                }
            }, (error) => {
                // window.location.reload();
            }
        )
        // }
    });

    useEffect(() => {
        fetchData(1)
    }, []);

    // console.log(dataMap)
    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="home" datamember={datat.datamember} />
                <div className="wrapper bg-white box-index">
                    <section className="" id="page-wrap">
                        <div className="container-fluid p-0 bg-top-center pt-3" style={{ backgroundImage: `url(${bg})` }}>
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">
                                    <div className="w-100 position-relative">
                                        <div className='w-100'>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className='font-14'>
                                                            <Link to="/" transition='default-fade' className="a-default">หน้าแรก</Link>
                                                            <span className='mx-2'>|</span>
                                                            <span className='text-decoration-underline'>{dataMap.topic}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='w-100 mt-5 pt-5'>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 text-center">
                                                        <div className='topic-news weight-600 mb-0'>{dataMap.topic}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="container">
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className=' px-1 px-md-4 pt-4'>
                                                            <div className='row justify-content-center'>
                                                                <div className='col-12 col-md-12 col-lg-12 mb-5'>
                                                                    <div className='position-relative pb-5'>
                                                                        <div className='weight-400' dangerouslySetInnerHTML={{ __html: dataMap.detail }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default Reason;