import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getAllProvince = () => {
  return axios.post(API_URL + "/province/province_all");
};

const getAllPrivatePosts = () => {
  // return axios.get(API_URL + "/data_private", { headers: authHeader.authHeaderMember() });
  return axios.get(API_URL + "/admin/member_private", { headers: authHeader.authHeaderMember() })
  // return axios.get(API_URL + "/member_private",{ headers: authHeader.authHeaderMember() })
  .then((response) => {
    return response.data;
  });
};

const regisPresentation = (data) => {
  return axios
    .post(API_URL + "/api/v1/regisPresentation", data)
    .then((response) => {
      return response.data;

    });
};
const delPresentation = (data) => {
  return axios
    .post(API_URL + "/api/v1/delPresentation", data)
    .then((response) => {
      return response.data;

    });
};
const regisContest = (data) => {
  return axios
    .post(API_URL + "/api/v1/regisContest", data)
    .then((response) => {
      return response.data;

    });
};
const delContest = (data) => {
  return axios
    .post(API_URL + "/api/v1/delContest", data)
    .then((response) => {
      return response.data;

    });
};
const regisContact = (data) => {
  return axios
    .post(API_URL + "/api/v1/regisContact", data)
    .then((response) => {
      return response.data;

    });
};

const regisForm = (data) => {
  return axios
    .post(API_URL + "/api/v1/regisForm", data)
    .then((response) => {
      return response.data;

    });
};


const postService = {
  getAllProvince,
  getAllPrivatePosts,
  regisPresentation,
  delPresentation,
  regisContest,
  delContest,
  regisContact,
  regisForm
};

export default postService;