import React, { useState, useCallback, useEffect } from 'react';

import { BrowserRouter as Router, Switch } from 'react-router-dom';
// import logo from './logo.svg';

import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import './assets/css/style.css';
import './assets/css/menu.css';
import './assets/css/respornsive.css';
import './assets/css/radioandcheckbox.css';

import PostService from "./components/services_member/post.service";

import Home from './pages/home';
import Register from './pages/register';
import Profile from './pages/profile';
import Newspassword from './pages/newpassword';
import Historypresentation from './pages/history_presentation';
import Historycontest from './pages/history_contest';
import Presentation from './pages/presentation/presentation';
import Presentationdetail from './pages/presentation/detail';

import Contest from './pages/contest/contest';
import Contestdetail from './pages/contest/detail';
import Contestregister from './pages/contest/register';

import News from './pages/news/news';
import Newsdetail from './pages/news/detail';

import Historyhost from './pages/home/history_host';
import Reason from './pages/home/reason';
import Objective from './pages/home/objective';
import Expected from './pages/home/expected';
import Institution from './pages/home/institution';
import Song from './pages/home/song';
import Symbol from './pages/home/symbol';
import Mascot from './pages/home/mascot';
import Contact from './pages/home/contact';
import Gallery from './pages/gallery/gallery';
import Gallerydetail from './pages/gallery/detail';
import Exhibition from './pages/exhibition';

import { Navigation, Route, Screen, glide, fade } from "react-tiger-transition";

glide({
  name: 'glide-left'
});
glide({
  name: 'glide-right',
  direction: 'right'
});
fade({
  name: "default-fade"
});



function App() {

  const user = JSON.parse(localStorage.getItem("member"));
  const memberdata = localStorage.getItem("member_data");

  const [token, setToken] = useState(user);

  // const memberdata = JSON.parse(localStorage.getItem("member_data"));

  // if (!user) {
  //     // window.location.href = "/";
  //     console.log('no login')
  // } else {
  //     console.log('login')      
  // }

  // setToken(user);

  // console.log(token)

  return (

    <div className="App">
      <Router basename="">
        <Switch>
          <Navigation>
            <Route exact path="/"><Screen><Home></Home></Screen></Route>
            <Route path="/register/:id"><Screen><Register ></Register></Screen></Route>
            <Route path="/newpassword/:id"><Screen><Newspassword ></Newspassword></Screen></Route>
            <Route path="/profile"><Screen><Profile ></Profile></Screen></Route>
            <Route path="/home/historyhost"><Screen><Historyhost ></Historyhost></Screen></Route>
            <Route path="/home/reason"><Screen><Reason ></Reason></Screen></Route>
            <Route path="/home/objective"><Screen><Objective></Objective></Screen></Route>
            <Route path="/home/expected"><Screen><Expected></Expected></Screen></Route>
            <Route path="/home/institution"><Screen><Institution></Institution></Screen></Route>
            <Route path="/home/song"><Screen><Song></Song></Screen></Route>
            <Route path="/home/symbol"><Screen><Symbol></Symbol></Screen></Route>
            <Route path="/home/mascot"><Screen><Mascot></Mascot></Screen></Route>
            <Route path="/home/contact"><Screen><Contact></Contact></Screen></Route>
            <Route path="/gallery"><Screen><Gallery></Gallery></Screen></Route>
            <Route path="/gallery/detail/:idde"><Screen><Gallerydetail></Gallerydetail></Screen></Route>
            <Route path="/exhibition"><Screen><Exhibition></Exhibition></Screen></Route>


            <Route path="/history_presentation"><Screen><Historypresentation member={token}></Historypresentation></Screen></Route>
            <Route path="/history_contest"><Screen><Historycontest member={token}></Historycontest></Screen></Route>
            <Route path="/presentation/presentation"><Screen><Presentation member={token} ></Presentation></Screen></Route>
            <Route path="/presentation/detail/:id"><Screen><Presentationdetail member={token}></Presentationdetail></Screen></Route>
            <Route path="/contest/contest"><Screen><Contest member={token} ></Contest></Screen></Route>
            <Route path="/contest/detail/:id"><Screen><Contestdetail member={token}></Contestdetail></Screen></Route>
            <Route path="/contest/register/:id"><Screen><Contestregister member={token}></Contestregister></Screen></Route>

            <Route path="/news/news"><Screen><News></News></Screen></Route>
            <Route path="/news/detail/:iddetail"><Screen><Newsdetail></Newsdetail></Screen></Route>
          </Navigation>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
