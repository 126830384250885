import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import { useParams } from 'react-router-dom';
// import { compareAsc, format } from 'date-fns';
import Header from '../../layout/header';
import Footer from '../../layout/footer';

import { Link } from "react-tiger-transition";
import GetService from "../../components/services_member/get.service";

import bg from '../../assets/img/bg-institution.jpg';
import expected1 from '../../assets/img/expected-1.jpg';
import expected2 from '../../assets/img/expected-2.jpg';
import expected3 from '../../assets/img/expected-3.jpg';


const Institution = (datat) => {

    const [dataMap, setDataMap] = useState([]);

    const fetchData = useCallback(async (id) => {

        GetService.getAllHome(id).then(
            (response) => {
                // console.log(response)
                if (response.status === 200) {
                    setDataMap(response.data)
                }
            }, (error) => {
            }
        )
    });

    useEffect(() => {
        fetchData(4)
    }, []);


    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="home" datamember={datat.datamember} />
                <div className="wrapper bg-white box-index">
                    <section className="" id="page-wrap">
                        <div className="container-fluid p-0 bg-top-center pt-3 bg-contain" style={{ backgroundImage: `url(${bg})` }}>
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">
                                    <div className="w-100 position-relative">
                                        <div className='w-100'>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                        <div className='font-14'>
                                                            <Link to="/" transition='default-fade' className="a-default">หน้าแรก</Link>
                                                            <span className='mx-2'>|</span>
                                                            <span className='text-decoration-underline'>โรงเรียนสาธิต 22 สถาบัน</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='w-100 mt-md-5'>
                                            <div className="container">
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-10 col-lg-10 text-start">
                                                        <div className=''>
                                                            <div className='topic-news weight-600 mb-0 pt-5 mt-md-5 text-center'>โรงเรียนสาธิต 22 สถาบัน</div>
                                                            <div className='row align-items-center mb-5'>
                                                                {dataMap.length ? dataMap.map((val, index) => (
                                                                    <div className='col-6 col-md-4 col-lg-4 p-md-4'>
                                                                        <div className='position-relative py-5 text-center shadow-sm' style={{background:"rgba(255,255,255,.6)"}}>
                                                                            <img src={process.env.REACT_APP_API_URL + "/uploads/home/" + val.id + '/' + val.img} style={{ height: "100px" }} />
                                                                        </div>
                                                                        <div className='color-white px-3 py-3' style={{background:"#2B2B2B"}}>
                                                                            {val.topic}
                                                                            <div className='font-12'>{val.detail}</div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                                    : <div className='py-5 text-center'>- No data -</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default Institution;